@import "../../../styles/variable.scss";

.payment-method-main {
  .payment-method-btn-main {
    width: $hundred-p;
    display: $d-flex;
    align-items: $center;
    gap: 0.625rem;
    margin-bottom: 0.625rem;
    @media screen and (max-width: 1200px) {
      flex-wrap: $flex-wrap;
      justify-content: $center;
    }
    .payment-method-options {
      position: $position-relative;
      z-index: $z-index-one;
      width: $hundred-p;
      height: 60px;
      background: $french-pass;
      color: $black;
      font-size: 1rem;
      font-weight: $font-weight-semibold;
      display: $d-flex;
      justify-content: $center;
      align-items: $center;
      flex-direction: $flex-column;
      cursor: $cursor-pointer;
      box-shadow: $none;
      border: $none;
      overflow: $overflow-hidden;
      border-radius: 0.25rem;
      padding: 0.375rem $zero;
      &:focus-visible {
        outline: $none;
      }
      &::after {
        content: "";
        position: $position-absolute;
        left: $zero;
        right: $zero;
        top: -100%;
        bottom: $hundred-p;
        z-index: -1;
        background: $neon-blue;
        color: $white;
        display: $d-block;
        padding: 0.85em 0.75em;
        transition: all 0.35s;
      }
      &:hover {
        color: $white;
        .payment-option {
          color: $white;
        }
        .payment-methods-option-icons {
          filter: brightness(0) invert(1);
        }
        &::after {
          left: $zero;
          right: $zero;
          top: $zero;
          bottom: $zero;
          transition: all 0.35s;
        }
      }
      @media screen and (max-width: 1620px) {
        font-size: 0.75rem;
      }
      @media screen and (max-width: 1440px) {
        height: 50px;
      }
      @media screen and (max-width: 1200px) {
        height: 75px;
        font-size: 0.625rem;
      }
      .payment-methods-option-icons {
        height: 16px;
        width: 16px;
      }
      .payment-option {
        .ant-radio {
          display: $none;
        }
      }
      .bank-transfer-icon {
        width: 22px;
        height: 22px;
        margin-bottom: 0.313rem;
      }
      .multi-transfer-icon {
        width: 16px;
        height: 14px;
      }
    }
    .checked {
      background: $neon-blue;
      color: $white;
      .payment-option {
        color: $white !important;
      }
      .payment-methods-option-icons {
        filter: brightness(0) invert(1);
      }
    }
    .multi-btn {
      width: $hundred-p;
      height: 75px;
      background: $french-pass !important;
      color: $midnight;
      font-size: 0.875rem;
      font-weight: $font-weight-normal;
      display: $d-flex;
      flex-direction: $flex-column;
      align-items: $center;
      justify-content: $center;
      gap: 0.5rem;
      border-radius: 0.188rem;
      padding: 0.844rem 0.938rem;
      @media screen and (max-width: 1440px) {
        font-size: 0.75rem;
      }
      &::after {
        content: "";
        position: $position-absolute;
        left: $zero;
        right: $zero;
        top: -100%;
        bottom: $hundred-p;
        z-index: -1;
        background: $neon-blue;
        display: $d-block;
        padding: 0.85em 0.75em;
        transition: all 0.35s;
      }
      &:hover {
        color: $white;
        .card-payment {
          filter: brightness(0) invert(1);
        }
        &::after {
          left: $zero;
          right: $zero;
          top: $zero;
          bottom: $zero;
          transition: all 0.35s;
        }
      }
      @media screen and (max-width: 1550px) {
        width: 48%;
      }
    }
    .checkbox-main {
      width: 24%;
      .ant-radio-wrapper {
        width: $hundred-p;
        margin: $zero;
        span:nth-child(2) {
          width: $hundred-p;
        }
        @media screen and (max-width: 1920px) {
          font-size: 0.75rem;
        }
        @media screen and (max-width: 1620px) {
          font-size: 0.625rem;
        }
        span.ant-radio + * {
          padding-inline-start: $zero;
          padding-inline-end: $zero;
        }
        span {
          &:nth-child(2) {
            display: $d-flex;
            flex-direction: $flex-column;
            align-items: $center;
            justify-content: $center;
          }
        }
      }
      @media screen and (max-width: 1200px) {
        width: 22%;
      }
      @media screen and (max-width: 575px) {
        width: 47%;
      }
    }
  }
  .payment-method {
    overflow-x: $overflow-hidden;
    overflow-y: $auto;
    .payment-checkbox {
      .checkbox-main {
        margin: 0.625rem $zero;
      }
    }
    .payment-method-title {
      background: $alice-blue;
      color: $midnight;
      font-size: 0.875rem;
      font-weight: $font-weight-semibold;
      line-height: 1.2;
      border-radius: 0.313rem;
      padding: 0.625rem;
      margin: $zero;
      margin-bottom: 0.25rem;
    }
  }
  .payment-method-wrap {
    .pound-penny-main {
      width: $hundred-p;
      display: $d-flex;
      margin-bottom: 0.625rem;
      @media screen and (max-width: 375px) {
        flex-wrap: $flex-wrap;
      }
      .pound-main {
        width: 49%;
        @media screen and (max-width: 375px) {
          width: $hundred-p;
          margin-bottom: 0.625rem;
        }
        .pound-title {
          font-size: 0.875rem;
          font-weight: $font-weight-medium;
          margin: $zero;
        }
        .product-item-add {
          width: 93%;
          height: 24px;
          display: $d-flex;
          justify-content: $justify-content-between;
          align-items: $center;
          border-radius: 0.5rem;
          padding: 0.313rem;
          margin: 0.188rem $zero $zero $auto;
          @media screen and (max-width: 375px) {
            margin: 0.188rem $zero $zero $zero;
          }
          .plus-minus-main {
            width: 90px;
            height: 30px;
            background: $soft-peach;
            border-radius: 0.125rem;
            display: $d-flex;
            align-items: $center;
            padding: $zero 0.188rem;
            .pound-icons {
              height: 20px;
              width: 20px;
              background: $ebony-clay;
              color: $white;
              display: $d-flex;
              justify-content: $center;
              align-items: $center;
              user-select: $none;
              cursor: $cursor-pointer;
            }
          }
          span {
            width: 50px;
            color: $shuttle-grey;
            font-size: 0.75rem;
            text-align: $center;
          }
          span:first-child {
            height: 26px;
            width: 50px;
            background: $alice-blue;
            color: $shuttle-grey;
            font-weight: $font-weight-semibold;
            display: $d-flex;
            justify-content: $center;
            align-items: $center;
            border-radius: 0.188rem;
          }
          span:last-child {
            width: 50px;
            background: $alice-blue;
            color: $black;
            font-weight: $font-weight-semibold;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
            overflow: $overflow-hidden;
            display: $d-flex;
            justify-content: $center;
            align-items: $center;
            border-radius: 0.188rem;
            padding: 0.25rem 0.125rem;
          }
          .ant-image {
            width: 40px;
            cursor: $cursor-pointer;
            .ant-image-img {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
      .pound-penny-divider {
        height: 215px;
        border-left: 0.063rem dashed #1d293a59;
        margin: 1.75rem $auto $zero $auto;
        @media screen and (max-width: 375px) {
          display: $none;
        }
      }
      .penny-main {
        width: 49%;
        @media screen and (max-width: 375px) {
          width: $hundred-p;
          margin-bottom: 0.625rem;
        }
        .penny-title {
          margin: $zero;
          font-weight: $font-weight-medium;
          font-size: 0.875rem;
        }
        .product-item-add {
          width: 93%;
          height: 24px;
          display: $d-flex;
          justify-content: $justify-content-between;
          align-items: $center;
          border-radius: 0.5rem;
          padding: 0.313rem;
          margin-top: 0.188rem;
          .plus-minus-main {
            width: 90px;
            height: 30px;
            background: $soft-peach;
            border-radius: 0.125rem;
            display: $d-flex;
            align-items: $center;
            padding: $zero 0.188rem;
            .penny-icons {
              height: 20px;
              width: 20px;
              background: $ebony-clay;
              color: $white;
              display: $d-flex;
              justify-content: $center;
              align-items: $center;
              user-select: $none;
              cursor: $cursor-pointer;
            }
          }
          span {
            width: 50px;
            color: $shuttle-grey;
            font-size: 0.75rem;
            text-align: $center;
          }
          span:first-child {
            height: 26px;
            width: 50px;
            background: $alice-blue;
            color: $shuttle-grey;
            font-weight: $font-weight-semibold;
            display: $d-flex;
            justify-content: $center;
            align-items: $center;
            border-radius: 0.188rem;
          }
          span:last-child {
            height: 26px;
            width: 50px;
            background: $alice-blue;
            color: $black;
            font-weight: $font-weight-semibold;
            display: $d-flex;
            justify-content: $center;
            align-items: $center;
            border-radius: 0.188rem;
          }
          .ant-image {
            width: 40px;
            cursor: $cursor-pointer;
            .ant-image-img {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
    .cash-wrap {
      width: $hundred-p;
      display: $d-flex;
      justify-content: $justify-content-between;
      @media screen and (max-width: 475px) {
        flex-wrap: $flex-wrap;
      }
      .cash-coin-main {
        width: 48%;
        margin-bottom: 0.625rem;
        .cash-coin {
          display: $d-flex;
          flex-wrap: $flex-wrap;
          gap: 0.375rem;
          .ant-image {
            width: 31%;
            display: $d-flex;
            justify-content: $center;
            align-items: $center;
            @media screen and (max-width: 475px) {
              width: 29%;
            }
            .ant-image-img {
              width: 90%;
              height: 75px;
              cursor: $cursor-pointer;
              @media screen and (max-width: 576px) {
                height: 65px;
              }
              @media screen and (max-width: 475px) {
                height: 30px;
              }
            }
          }
        }
      }
      .cash-notes-main {
        width: 48%;
        margin-bottom: 1.25rem;
        .cash-notes {
          height: $hundred-p;
          display: $d-flex;
          flex-wrap: $flex-wrap;
          gap: 0.375rem;
          .ant-image {
            width: 31%;
            display: $d-flex;
            align-items: $center;
            justify-content: $center;
            @media screen and (max-width: 475px) {
              width: 29%;
            }
            .ant-image-img {
              width: $hundred-p;
              height: 68px;
              cursor: $cursor-pointer;
              @media screen and (max-width: 576px) {
                height: 55px;
              }
              @media screen and (max-width: 475px) {
                height: 23px;
              }
            }
          }
          .ant-image:nth-child(1) {
            .ant-image-img {
              width: 90%;
              height: 77px;
              @media screen and (max-width: 576px) {
                height: 65px;
              }
              @media screen and (max-width: 475px) {
                height: 30px;
              }
            }
          }
          .ant-image:nth-child(4) {
            .ant-image-img {
              width: 90%;
              height: 77px;
              @media screen and (max-width: 576px) {
                height: 65px;
              }
              @media screen and (max-width: 475px) {
                height: 30px;
              }
            }
          }
        }
      }
    }
    .cash-total-main {
      width: $hundred-p;
      display: $d-flex;
      flex-direction: $flex-column;
      align-items: $flex-end;
      .cash-count-wrap {
        width: $hundred-p;
        justify-content: $justify-content-between;
        display: $d-flex;
        @media screen and (max-width: 425px) {
          flex-wrap: $flex-wrap;
        }
        .cash-content {
          width: $hundred-p;
          height: 20px;
          color: $white;
          text-align: $center;
          display: $d-flex;
          justify-content: $center;
          border-radius: 0.25rem;
          align-items: $center;
          gap: 0.313rem;
          padding: 0.75rem $zero;
          margin-bottom: 0.625rem;
          span {
            font-size: 0.875rem;
            font-weight: $font-weight-semibold;
          }
        }
        .cash-content:nth-child(1) {
          background: $persian-green;
          margin-right: 0.688rem;
          @media screen and (max-width: 425px) {
            margin-right: $zero;
          }
        }
        .cash-content:nth-child(2) {
          background: $faded-red;
        }
      }
      .cash-content {
        width: $hundred-p;
        height: 20px;
        background: $midnight;
        text-align: $center;
        color: $white;
        display: $d-flex;
        justify-content: $center;
        border-radius: 0.25rem;
        align-items: $center;
        gap: 0.313rem;
        margin-bottom: 0.625rem;
        padding: 0.75rem $zero;
        span {
          font-size: 0.875rem;
          font-weight: $font-weight-semibold;
        }
      }
    }
    .cash-input-main {
      height: 80px !important;
      .cash-input {
        height: 55px !important;
        background: $alice-blue !important;
      }
    }
  }
  .payment-modal-buttons-main {
    display: $d-flex;
    justify-content: $flex-end;
    align-items: $center;
    gap: 0.625rem;
    .complete-btn {
      background: $persian-green !important;
      box-shadow:
        #03b2887d 0px 6px 12px -2px,
        #03b28859 0px 3px 7px -3px;
      padding: $zero 0.938rem;
      &::after {
        content: "";
        background: $medium-slate-blue;
      }
      &:hover {
        box-shadow:
          #4c4de361 0px 6px 12px -2px,
          #4c4de342 0px 3px 7px -3px;
      }
      .anticon {
        svg {
          fill: $white !important;
        }
      }
    }
    .cancel-btn {
      background: $jasper !important;
      box-shadow:
        #dc354575 0px 6px 12px -2px,
        #dc35454f 0px 3px 7px -3px;
      padding: $zero 0.938rem;
      &::after {
        content: "";
        background: linear-gradient(
          132deg,
          $neon-blue 4.27%,
          $medium-slate-blue
        ) !important;
      }
      &:hover {
        box-shadow:
          #4c4de361 0px 6px 12px -2px,
          #4c4de342 0px 3px 7px -3px !important;
      }
    }
  }
  .bank-transfer-table-main {
    .loader-animation-main {
      .loader-animation {
        height: 180px;
      }
    }
    .table-wrap {
      .table {
        padding: $zero;
      }
      .back-transfer-table {
        padding: $zero;
        .ant-table-content {
          .ant-table-thead {
            tr {
              .ant-table-cell {
                text-transform: $text-transform-uppercase;
              }
              .ant-table-cell:nth-child(1) {
                padding-left: $zero;
              }
            }
          }
          .ant-table-tbody {
            .ant-table-row {
              .ant-table-cell {
                p {
                  margin: $zero;
                }
              }
              .ant-table-cell:nth-child(1) {
                border-left: $none;
                padding-left: $zero;
              }
            }
            .ant-table-row:last-child {
              .ant-table-cell {
                border-bottom: $none;
              }
            }
          }
        }
      }
    }
  }
}
.ant-radio {
  display: $none !important;
  .ant-radio-inner {
    display: $none !important;
  }
}
