@import "../../styles/variable.scss";

.suggestion-list {
    position: $position-absolute;
    top: 2.5rem;
    z-index: 4;
    width: $hundred-p;
    max-height: 206px;
    background-color: $white;
    padding: 0.313rem;
    box-shadow:
      0 6px 16px 0 #00000014,
      0 3px 6px -4px #0000001f,
      0 9px 28px 8px #0000000d;
    border-radius: 0.375rem;
    overflow: $auto;
    .no-data-found-main {
      position: $position-relative;
      .no-data-found-icon {
        height: 160px;
        width: 160px;
        margin: $zero $auto;
      }
      .no-found-text {
        position: $position-absolute;
        top: 5.625rem;
        width: $hundred-p;
        text-align: $center;
        color: #a3a3a3;
      }
    }
    .loader-icon {
      height: 160px;
    }
    ul {
      padding: $zero;
      margin: $zero;
      .suggestion-list-main {
        list-style-type: $none;
        padding: 0.75rem 0.5rem;
        border-radius: 0.375rem;
        cursor: $cursor-pointer;
        &:hover {
          background-color: $medium-slate-blue;
          color: $white;
        }
      }
    }
  }