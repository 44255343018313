@import "../../../styles/variable.scss";

.user-list-main {
  background: $alice-blue;
  padding: 1.25rem;
  padding-top: 0.875rem;
  @media screen and (min-width: 1440px) {
    padding: 1.875rem;
    padding-top: 1rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
  }
  .table-wrap {
    .table-header-main {
      .print-button-main {
        .generate-backup-button {
          width: 130px;
          height: 50px;
          @media screen and (max-width: 768px) {
            width: 110px;
            height: 40px;
          }
        }
      }
    }
    .table {
      background: $bg-transparent;
      padding: $zero;
      h1 {
        margin: $zero;
      }
    }
    .table-header-main {
      .filter-button-main {
        .filter-btn {
          display: $none;
        }
      }
    }
  }
  .user-list-table {
    background: $white;
    overflow-x: $auto;
    height: calc(100vh - 285px);
    @media screen and (min-width: 1500px) {
      height: calc(100vh - 313px);
    }
    @media screen and (max-width: 1200px) {
      height: 320px;
    }
    @media screen and (max-width: 768px) {
      height: 336px;
    }
    .ant-spin-nested-loading {
      height: calc(100vh - 285px);
      background: $white;
      overflow: $auto;
      @media screen and (min-width: 1500px) {
        height: calc(100vh - 313px);
      }
      @media screen and (max-width: 1200px) {
        height: 320px;
      }
      @media screen and (max-width: 768px) {
        height: 336px;
      }
      .ant-spin-container {
        height: $hundred-p;
        .ant-table-empty {
          height: $hundred-p;
          .ant-table-container {
            height: $hundred-p;
            .ant-table-content {
              height: $hundred-p;
              table {
                height: $hundred-p;
              }
            }
          }
        }
      }
    }
    .ant-table-content {
      background: $white;
      position: $position-relative;
      .ant-empty-normal {
        position: $position-absolute;
        top: $fifty-p;
        left: $fifty-p;
        transform: translate(-50%, -50%);
        margin: $zero;
        @media screen and (min-width: 1400px) {
          left: $fifty-p;
          top: $fifty-p;
        }
        @media screen and (max-width: 1040px) {
          left: 37%;
        }
      }
    }
    table {
      colgroup {
        col {
          width: 16px !important;
        }
      }
      .ant-table-thead {
        tr {
          th {
            position: $position-sticky !important;
            top: $zero;
            z-index: $z-index-one;
            height: 45px;
          }
          .ant-table-column-has-sorters:hover {
            background: $white;
          }
          .ant-table-cell {
            padding: 0.563rem;
            .ant-checkbox-wrapper {
              .ant-checkbox {
                .ant-checkbox-inner {
                  border: 0.063rem $border-style-solid $blue-lotus;
                }
              }
              .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background: $blue-lotus;
                }
              }
            }
          }
          .ant-table-column-sort {
            background: $white;
          }
          .ant-table-cell:first-child {
            padding-left: $zero;
            border-start-start-radius: $zero;
          }
          .ant-table-cell:nth-child(3) {
            max-width: 200px;
            overflow: $overflow-hidden;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
          }
          .ant-table-cell:nth-child(4) {
            max-width: 200px;
            overflow: $overflow-hidden;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
          }
          .ant-table-cell:nth-child(5) {
            max-width: 200px;
            overflow: $overflow-hidden;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
          }
          .ant-table-cell:nth-child(6) {
            max-width: 160px;
            overflow: $overflow-hidden;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
          }
          .ant-table-cell:nth-child(8) {
            max-width: 120px;
            overflow: $overflow-hidden;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
          }
          .ant-table-cell:nth-child(9) {
            padding: 0.75rem;
          }
          .ant-table-cell:last-child {
            position: $position-sticky !important;
            top: $zero;
            right: inherit;
            z-index: $z-index-one;
            text-align: $center;
            border-start-end-radius: $zero;
            box-shadow: $table-box-shadow;
          }
          .ant-table-cell-fix-right {
            position: $position-sticky !important;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-row {
          .ant-table-cell {
            padding: 0.563rem;
            .ant-checkbox-wrapper {
              .ant-checkbox {
                .ant-checkbox-inner {
                  border: 0.063rem $border-style-solid $blue-lotus;
                }
              }
              .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background: $blue-lotus !important;
                }
              }
            }
          }
          .ant-table-column-sort {
            background: $white;
          }
          .ant-table-cell-fix-right {
            position: $position-sticky !important;
            z-index: $zero;
          }
          .ant-table-cell:first-child {
            padding-left: $zero;
          }
          .ant-table-cell:nth-child(2) {
            line-height: $zero;
            .ant-image {
              width: 40px;
              height: 40px;
              border-radius: 0.313rem;
              overflow: $overflow-hidden;
              box-shadow:
                #1616160a 0px 20px 33px -5px,
                #1616160a 0px 10px 10px -5px;
              .ant-image-img {
                width: $hundred-p;
                height: $hundred-p;
                object-fit: $cover !important;
              }
            }
          }
          .ant-table-cell:nth-child(3) {
            max-width: 200px;
            overflow: $overflow-hidden;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
          }
          .ant-table-cell:nth-child(4) {
            max-width: 200px;
            overflow: $overflow-hidden;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
          }
          .ant-table-cell:nth-child(5) {
            max-width: 200px;
            overflow: $overflow-hidden;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
          }
          .ant-table-cell:nth-child(6) {
            max-width: 160px;
            overflow: $overflow-hidden;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
          }
          .ant-table-cell:nth-child(8) {
            max-width: 120px;
            overflow: $overflow-hidden;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
          }
          .ant-table-cell:nth-child(9) {
            .input-wrap {
              .input-label {
                margin: $zero;
              }

              .input-area-main {
                height: 35px;
                .dropdown_input {
                  height: 35px;
                  background: $alice-blue;
                  width: $hundred-p;
                  .ant-select-selector {
                    padding-left: 0.375rem !important;
                  }
                }
              }
            }
          }
          .ant-table-cell:nth-child(10) {
            .input-wrap {
              .input-area-main {
                height: 35px;
                .dropdown_input {
                  height: 35px;
                  background: $alice-blue;
                  width: $hundred-p;
                  .ant-select-selector {
                    padding-left: 0.375rem !important;
                  }
                }
              }
            }
          }
          .ant-table-cell:last-child {
            div {
              display: $d-flex;
              align-items: $center;
              justify-content: $center;
              span {
                margin-left: 0.563rem;
                cursor: $cursor-pointer;
                svg {
                  width: 19px;
                  height: $hundred-p;
                  object-fit: $none;
                  @media screen and (max-width: 768px) {
                    height: 18px;
                    width: 15px;
                  }
                }
              }
              span:nth-child(1) {
                margin-left: $zero;
                svg {
                  fill: $green-blue;
                }
              }
              span:nth-child(2) {
                svg {
                  fill: $blue-lotus;
                }
              }
            }
          }
        }
        .select-dropdown-main {
          height: $hundred-p;
          .user-select-dropdown {
            width: $hundred-p;
            padding: $zero;
          }
        }
      }
    }
  }
  .table-wrap {
    .table {
      overflow-y: $overflow-hidden;
      overflow-x: $auto;
    }
  }
}
.user-modal-main {
  .ant-modal-content {
    padding: $zero !important;
    .ant-modal-close {
      top: 0.625rem;
      right: 1rem;
      width: 20px;
      height: 20px;
      border-radius: $fifty-p;
      border: 0.063rem $border-style-solid $jasper;
      background: $jasper;
      transition: all 0.3s $transition-ease-in-out;
      box-shadow:
        #dc3545 0px 3px 8px -2px,
        #dc3545 0px 3px 7px -3px;
      .ant-modal-close-x {
        .ant-modal-close-icon {
          svg {
            width: $hundred-p;
            height: 12px;
            fill: $white;
            transition: all 0.3s $transition-ease-in-out;
          }
        }
      }
      &:hover {
        background: #dc354538;
        border: 0.063rem $border-style-solid $jasper;
        .ant-modal-close-x {
          .ant-modal-close-icon {
            svg {
              fill: $jasper;
            }
          }
        }
      }
    }
    .ant-modal-header {
      padding: 0.5rem 1rem $zero;
      margin: $zero;
      .ant-modal-title {
        font-size: 1.125rem;
        padding: $zero $zero 0.313rem $zero;
      }
    }
    .ant-modal-body {
      max-height: 550px;
      overflow: $auto;
      padding: 0.5rem 1rem;
      padding-bottom: $zero;
    }
    .ant-modal-footer {
      padding: 0.5rem 1rem;
      .create-button {
        background: $persian-green !important;
        box-shadow:
          #03b2885e 0px 6px 12px -2px,
          #03b28845 0px 3px 7px -3px;
        padding: $zero 1.25rem;
        &::after {
          content: "";
          background: $medium-slate-blue;
        }
        &:hover {
          box-shadow:
            #4c4de361 0px 6px 12px -2px,
            #4c4de342 0px 3px 7px -3px;
        }
        .anticon {
          svg {
            fill: $white !important;
          }
        }
      }
    }
  }
  .user-modal-input-main {
    .user-input-wrap {
      position: $position-relative;
      .user-input {
        background: $alice-blue;
        .ant-input-wrapper {
          .ant-input {
            margin-top: -0.125rem;
            background-color: $bg-transparent;
            border: $none;
            &:focus {
              box-shadow: $none;
            }
          }
        }
        .ant-input-group {
          .ant-input-group-addon {
            border: $none;
            background-color: $bg-transparent;
            padding-left: $zero;
            .ant-select {
              margin-top: -0.625rem;

              .ant-select-selector {
                padding-left: $zero !important;
              }
            }
            .ant-select-open {
              border: $none;
            }
          }
        }
      }
      .user-dropdown {
        background: $alice-blue;
      }
      .user-textbox {
        background: $alice-blue;
        margin-bottom: 1.875rem;
      }
      .user-password-eye-icon {
        position: $position-absolute;
        top: 3.063rem;
        right: 1.25rem;
        width: 36px;
        height: 20px;
        display: $d-flex;
        justify-content: $center;
        align-items: $center;
        cursor: $cursor-pointer;
      }
      .input-wrap {
        .input-area-main {
          .error-count-msg {
            .error-msg {
              margin-top: 0.125rem;
            }
          }
          .error-msg {
            margin-top: 0.125rem;
          }
        }
      }
    }
  }
  .upload-images-box-main {
    background: $alice-blue !important;
    border-radius: 0.25rem;
    padding: 1rem;
    @media screen and (max-width: 992px) {
      margin-bottom: 0.75rem;
    }
    @media screen and (max-width: 425px) {
      padding: 0.75rem;
    }
    .ant-upload-drag {
      background: $white !important;
      padding: 2.5rem $zero;
      .ant-upload-btn {
        padding: $zero;
      }
    }
    .checkbox-main {
      span {
        &:nth-child(2) {
          color: $manatee;
          font-size: 0.875rem;
          font-weight: $font-weight-normal;
          line-height: 1.281rem;
        }
      }
      .ant-checkbox {
        .ant-checkbox-inner {
          width: 20px;
          height: 20px;
          background: $bg-transparent;
          border: 0.125rem $border-style-solid $medium-slate-blue;
          margin-right: 0.313rem;
        }
      }
    }
    .upload-image {
      margin-bottom: 1rem;
    }
  }
}
.user-list-view-modal {
  max-height: 450px;
  padding-bottom: $zero;
  .ant-modal-content {
    height: $hundred-p;
    padding: $zero !important;
    .ant-modal-close {
      top: 0.625rem;
      right: 1rem;
      width: 20px;
      height: 20px;
      border-radius: $fifty-p;
      border: 0.063rem $border-style-solid $jasper;
      background: $jasper;
      transition: all 0.3s $transition-ease-in-out;
      box-shadow:
        #dc3545 0px 3px 8px -2px,
        #dc3545 0px 3px 7px -3px;
      .ant-modal-close-x {
        .ant-modal-close-icon {
          svg {
            width: $hundred-p;
            height: 12px;
            fill: $white;
            transition: all 0.3s $transition-ease-in-out;
          }
        }
      }
      &:hover {
        background: #dc354538;
        border: 0.063rem $border-style-solid $jasper;
        .ant-modal-close-x {
          .ant-modal-close-icon {
            svg {
              fill: $jasper;
            }
          }
        }
      }
    }
    .ant-modal-header {
      padding: 0.5rem 1rem $zero;
      margin: $zero;
      .ant-modal-title {
        font-size: 1.125rem;
        padding: $zero $zero 0.313rem $zero;
      }
    }
    .ant-modal-body {
      max-height: 470px;
      overflow-y: $auto;
      .user-list-table-wrap {
        padding: 0.75rem 1rem;
      }
    }
  }
  .user-list-table-wrap {
    .user-list-details-main {
      display: $d-flex;
      flex-direction: $flex-column;
      padding: $zero;
      margin: $zero;
      li {
        display: $d-flex;
        flex-wrap: $flex-wrap;
        justify-content: $justify-content-between;
        align-items: $center;
        list-style: $none;
        padding: 1rem 1.25rem;
        @media screen and (max-width: 992px) {
          padding: 0.75rem 1rem;
        }
        &:nth-child(odd) {
          background: $alice-blue;
        }
        &:nth-child(1) {
          order: 2;
        }
        &:nth-child(2) {
          order: 3;
        }
        &:nth-child(3) {
          order: 4;
        }
        &:nth-child(4) {
          order: 5;
        }
        &:nth-child(5) {
          order: 6;
        }
        &:nth-child(6) {
          order: 7;
        }
        .user-details-title {
          width: $fifty-p;
          color: $midnight;
          font-size: 0.875rem;
          font-weight: $font-weight-normal;
          line-height: 1.281rem;
          margin: $zero;
          @media screen and (max-width: 768px) {
            font-size: 0.75rem;
            width: 40%;
          }
        }
        .user-details {
          width: $fifty-p;
          color: $midnight;
          font-size: 0.875rem;
          font-weight: $font-weight-bold;
          line-height: 1.281rem;
          margin: $zero;
          @media screen and (max-width: 768px) {
            font-size: 0.75rem;
            width: 60%;
          }
        }
      }
      .ant-image {
        width: 145px;
        height: 145px;
        background: $white;
        display: $d-flex;
        justify-content: $center;
        align-items: $center;
        box-shadow: #959da533 0px 8px 24px;
        border-radius: $fifty-p;
        margin: 0.75rem $auto;
        img {
          width: $hundred-p;
          height: 145px;
          border-radius: $fifty-p;
          object-fit: $cover !important;
        }
      }
    }
  }
  .user-detail-modal-btn {
    width: 85px;
    height: 52px;
    background: $persian-green !important;
    color: $white;
    font-size: 0.875rem;
    font-weight: $font-weight-semibold;
    border-radius: 0.188rem;
    box-shadow:
      #03b2885e 0px 6px 12px -2px,
      #03b28845 0px 3px 7px -3px;
    padding: 0.844rem 1.563rem;
    margin: 0.625rem 1.25rem 1.25rem;
    @media screen and (max-width: 992px) {
      font-size: 0.813rem;
    }
    &::after {
      content: "";
      background: $medium-slate-blue;
    }
    &:hover {
      box-shadow:
        #4c4de361 0px 6px 12px -2px,
        #4c4de342 0px 3px 7px -3px;
    }
    span {
      margin-right: 0.313rem;
    }
  }
}
.user-edit-upload-image-main {
  width: $fit-content;
  margin: $zero $auto;
  .uploaded-image-wrap {
    position: $position-relative;
    .ant-image {
      width: $hundred-p;
      max-width: 250px;
      height: $auto;
      max-height: 250px;
      display: $d-flex;
      justify-content: $center;
      overflow: $overflow-hidden;
      border-radius: 0.5rem;
      object-fit: $object-fit-contain;
      margin-top: 1rem;
      img {
        height: $hundred-p;
        width: $hundred-p;
        border-radius: 0.5rem;
        margin-top: $zero;
        margin-bottom: $zero;
      }
    }
    .close-button-main {
      position: $position-absolute;
      top: -0.375rem;
      right: -0.375rem;
      z-index: $z-index-one;
      display: $d-flex;
      justify-content: $flex-end;
      .anticon-close {
        width: 8px;
        height: 8px;
        background: $neon-blue;
        border: 0.125rem $border-style-solid $white;
        border-radius: 3.125rem;
        transition: 0.2s all $transition-ease-in-out;
        padding: 0.188rem;
        svg {
          width: $hundred-p;
          height: 10px;
          fill: $white;
        }
      }
    }
  }
}
