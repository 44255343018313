@import "../../styles/variable.scss";

.technical-error-page-main {
  height: $vh-100;
  display: $d-flex;
  flex-direction: $flex-column;
  justify-content: $center;
  align-items: $center;
  margin: $zero $auto;
  .technical-details-wrap {
    width: 800px;
    max-width: 800px;
    background: $white;
    text-align: $center;
    font-family: $font-family !important;
    box-shadow: #64646f33 0px 7px 29px 0px;
    padding: 1.25rem;
    .oops-image-main {
      height: 110px;
    }
    .technical-main {
      width: 600px;
      max-width: 600px;
      margin: $zero $auto;
    }
    .experiencing-text {
      text-align: $center;
      font-size: 1.5rem;
      font-weight: $font-weight-semibold;
      margin-bottom: 0.625rem;
    }
    .experiencing-detail {
      margin-top: $zero;
      font-size: 0.625rem;
      font-weight: $font-weight-medium;
    }
    .technical-code {
      font-size: 0.938rem;
      font-weight: $font-weight-bold;
    }
    .error-links {
      font-size: 0.75rem;
      font-weight: $font-weight-medium;
    }
    .link-text {
      margin: 1.875rem $zero $zero;
      .click-here {
        position: $position-relative;
        z-index: $z-index-one;
        height: 46px;
        background: $madison;
        font-size: 0.875rem;
        font-weight: $font-weight-semibold;
        border: $none;
        border-radius: 0.313rem;
        overflow: $overflow-hidden;
        box-shadow: $none;
        padding: 0.375rem 0.875rem;
      }
    }
  }
}
