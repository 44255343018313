//color
$black: #000000;
$white: #fff;
$bg-transparent: transparent;
$midnight: #1e293b;
$manatee: #8d929a;
$medium-slate-blue: #6366f1;
$neon-blue: #4f46e5;
$persian-green: #03b288;
$alice-blue: #f1f5f9;
$japanese-indigo: #2c3a52;
$blue-lotus: #575fe9;
$green-blue: #14ba93;
$oslo-grey: #8c8c8c;
$lavender-mist: #deebff;
$jasper: #dc3545;
$tealish-gray: #838a95;
$ebony-clay: #1d293a;
$aluminium: #a8acb3;
$light-grey: #d9d9d9;
$boulder: #797c79;
$red: #ea3548;
$clear-day: #edfffb;
$water: #eef3fa;
$soft-peach: #efefef;
$shuttle-grey: #5b6670;
$soft-peach: #ececec;
$alice-blue: #f3f6f9;
$dimgray: #717171;
$whisper: #ebebeb;
$lavender: #e9e9ff;
$amour: #ffe9eb;
$madison: #2a3a51;
$light-grey-tints: #d4d4d4;
$dark-gray: #ababab;
$dim-gray: #696565;
$white-smoke: #f6f6f6;
$gray: #7d7d7d;
$dark-seafoam: #1e244c;
$dark-jungle-green: #161e2c;
$pastel-grey: #cbcbcb;
$baltic-sea: #212326;
$platinum: #E5E5E5;
$lavender-grey: #c6c7d3;
$Ghost-white: #f3f8ff;
$venus: #828c97;
$aluminium: #acacac;
$french-pass: #d5e3fa;
$faded-red: #e12f47;
$gondola: #1a1a1a;
$concord: #737679;
$alabaster: #fafafa;

// font-family
$text-transform-capitalize: capitalize;
$text-transform-uppercase: uppercase;

//d-flex
$d-flex: flex;
$d-block: block;
$d-inline-block: inline-block;
$d-inline-table: inline-table;
$center: center;
$flex-start: flex-start;
$flex-end: flex-end;
$align-items-baseline: baseline;
$justify-content-between: space-between;
$justify-content-around: space-around;
$justify-content-evenly: space-evenly;
$flex-column: column;
$flex-wrap: wrap;

//position
$position-absolute: absolute;
$position-relative: relative;
$position-fixed: fixed;
$position-static: static;
$position-sticky: sticky;

//height-width
$vh-100: 100vh;
$vw-100: 100vw;
$object-fit-contain: contain;
$fit-content: fit-content;

//text-align
$end: end;
$start: start;

// cursor
$cursor-pointer: pointer;
$cursor-not-allowed: not-allowed;

// border
$border-style-solid: solid;
$border-transparent: transparent;

// overflow
$overflow-hidden: hidden;
$text-overflow-ellipsis: ellipsis;

// font-weight
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// transition
$transition-ease-in-out: ease-in-out;
$transition-ease-out: ease-out;

// text-decoration
$text-decoration-underline: underline;

// common none
$none: none;

// font-family
$font-family: "Inter", sans-serif;

// box-sizing
$box-sizing-border: border-box;

// for zero value
$zero: 0;

// for auto value
$auto: auto;

// fifty-p and hundred-p
$fifty-p: 50%;
$hundred-p: 100%;

// cover
$cover: cover;

// border-collapse
$border-collapse: collapse;

//visibility
$visibility-visible: visible;

// z-index
$z-index-one: 1;

// table box shadow
$table-box-shadow: 0 -7px 10px #0000001c;

//nowrap
$nowrap: nowrap;