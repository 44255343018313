@import "../../../styles/variable.scss";

.quotation-list-main {
  background: $alice-blue;
  padding: 1.25rem;
  padding-top: 0.875rem;
  @media screen and (min-width: 1440px) {
    padding: 1.875rem;
    padding-top: 1rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
  }
  .table-wrap {
    .table-header-main{
      .print-button-main{
        .generate-backup-button{
          height: 50px;
          @media screen and (max-width:768px) {
            height: 40px;
          }
        }
      }
    }
    .table {
      background: $bg-transparent;
      padding: $zero;
    }
  }
  .quotation-list-table {
    height: calc(100vh - 294px);
    overflow-x: $overflow-hidden;
    background: $white;
    overflow-y: $auto;
    @media screen and (min-width: 1500px) {
      height: calc(100vh - 322px);
    }
    @media screen and (max-width: 1200px) {
      height: 320px;
    }
    @media screen and (max-width: 768px) {
      height: 336px;
    }
    .ant-spin {
      top: 2.25rem;
    }
    .ant-spin-nested-loading {
      max-height: calc(100vh - 294px);
      background: $white;
      overflow-x: $auto;
      @media screen and (min-width: 1500px) {
        max-height: calc(100vh - 322px);
      }
      @media screen and (max-width: 1200px) {
        max-height: 320px;
      }
      @media screen and (max-width: 768px) {
        max-height: 336px;
      }
    }
    table {
      .ant-table-wrapper {
        overflow-x: $auto;
        overflow-y: $overflow-hidden;
      }
      colgroup {
        col {
          width: 16px !important;
        }
      }
      .ant-table-thead {
        tr {
          th {
            position: $position-sticky;
            top: $zero;
            z-index: $z-index-one;
            height: 45px;
          }
          .ant-table-cell {
            padding: $zero 0.563rem;
            .ant-table-column-sorters {
              .ant-table-column-title {
                white-space: $nowrap;
              }
            }
            .ant-checkbox-wrapper {
              .ant-checkbox {
                .ant-checkbox-inner {
                  border: 0.063rem $border-style-solid $blue-lotus;
                }
              }
              .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background: $blue-lotus;
                }
              }
            }
          }
          .ant-table-cell:first-child {
            padding-left: $zero;
            border-start-start-radius: $zero
          }
          .ant-table-cell:last-child {
            text-align: $center;
            border-start-end-radius: $zero;
            box-shadow: $table-box-shadow;
          }
          .ant-table-cell-fix-right {
            position: $position-sticky !important;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-row {
          .ant-table-cell {
            padding: 0.563rem;
            p {
              margin: $zero;
            }
            .ant-tag-gold {
              border-color: $border-transparent;
              padding: 0.125rem 0.5rem;
            }
            .ant-tag-red {
              border-color: $border-transparent;
              padding: 0.125rem 0.5rem;
            }
            .ant-tag-green {
              border-color: $border-transparent;
              padding: 0.125rem 0.5rem;
              color: $persian-green;
              background: #03b2881f;
            }
          }
          .ant-table-cell-fix-right {
            position: $position-sticky !important;
            z-index: $zero;
          }
          .ant-checkbox-wrapper {
            .ant-checkbox {
              .ant-checkbox-inner {
                border: 0.063rem $border-style-solid $blue-lotus;
              }
            }
            .ant-checkbox-checked {
              .ant-checkbox-inner {
                background: $blue-lotus;
              }
            }
          }
          .ant-table-cell:first-child {
            padding-left: $zero;
          }
          .ant-table-cell:nth-child(3) {
            cursor: $cursor-pointer;
            p{
              max-width: 245px;
              overflow: $overflow-hidden;
              text-overflow: $text-overflow-ellipsis;
              white-space: $nowrap;
            }
          }
          .ant-table-cell:nth-child(4) {
            cursor: $cursor-pointer;
            p{
              max-width: 181px;
              overflow: $overflow-hidden;
              text-overflow: $text-overflow-ellipsis;
              white-space: $nowrap;
            }
          }
          .ant-table-cell:last-child {
            text-align: $center;
            @media screen and (max-width: 768px) {
              max-width: 90px;
              min-width: 90px !important;
              width: 90px;
              overflow: $overflow-hidden;
              text-overflow: $text-overflow-ellipsis;
              white-space: $nowrap;
            }
            div {
              display: $d-flex;
              align-items: $center;
              justify-content: $center;
              gap: 0.375rem;
              span {
                svg {
                  height: 19px;
                  width: $hundred-p;
                  object-fit: $none;
                  @media screen and (max-width: 768px) {
                    height: 18px;
                    width: 14px;
                  }
                }
              }
              span:nth-child(1) {
                svg {
                  fill: $green-blue;
                  @media screen and (max-width: 768px) {
                    height: 18px;
                    width: 15px;
                  }
                }
              }
              a:nth-child(2) {
                height: 19px;
                span {
                  svg {
                    fill: $blue-lotus;
                  }
                }
              }
              .mail-icon {
                svg {
                  fill: #e68e29;
                  @media screen and (max-width: 768px) {
                    height: 18px;
                    width: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.quotation-modal {
  padding-bottom: $zero;
  .ant-table {
    border-radius: $zero;
  }
  .ant-table-container {
    border-radius: $zero;
  }
  .ant-modal-content {
    padding: $zero !important;
    .ant-modal-close {
      top: 0.625rem;
      right: 1rem;
      width: 20px;
      height: 20px;
      border-radius: $fifty-p;
      border: 0.063rem $border-style-solid $jasper;
      background: $jasper;
      transition: all 0.3s $transition-ease-in-out;
      box-shadow:
        #dc3545 0px 3px 8px -2px,
        #dc3545 0px 3px 7px -3px;
      .ant-modal-close-x {
        .ant-modal-close-icon {
          svg {
            width: $hundred-p;
            height: 12px;
            fill: $white;
            transition: all 0.3s $transition-ease-in-out;
          }
        }
      }
      &:hover {
        background: #dc354538;
        border: 0.063rem $border-style-solid $jasper;
        .ant-modal-close-x {
          .ant-modal-close-icon {
            svg {
              fill: $jasper;
            }
          }
        }
      }
    }
    .ant-modal-header {
      padding: 0.5rem 1rem $zero;
      margin: $zero;
      .ant-modal-title {
        font-size: 1.125rem;
        padding: $zero $zero 0.313rem $zero;
      }
    }
    .ant-modal-body {
      max-height: 550px;
      overflow-y: $auto;
      overflow-x: $overflow-hidden;
      padding: 0.438rem 1rem;
      .quotation-modal-content {
        margin-bottom: 0.938rem;
        .quotation-modal-body {
          h6 {
            color: $midnight;
            font-size: 1rem;
            font-weight: $font-weight-medium;
            margin: $zero;
            text-transform: $text-transform-uppercase;
            line-height: 1.375rem;
          }
          .bill_to {
            text-align: $end;
          }
          .bill_form {
            text-align: $start;
          }
          .quotation-info {
            font-size: 0.875rem;
            color: $midnight;
            font-weight: $font-weight-medium;
            display: $d-flex;
            align-items: $center;
            justify-content: $end;
            margin: $zero;
            .ant-tag-geekblue {
              height: 27px;
              color: $medium-slate-blue;
              display: $d-flex;
              justify-content: $center;
              align-items: $center;
              border: 0.063rem $border-style-solid $medium-slate-blue;
              margin-left: 0.25rem;
            }
            .ant-tag-green {
              height: 27px;
              color: $persian-green;
              display: $d-flex;
              justify-content: $center;
              align-items: $center;
              border: 0.063rem $border-style-solid $persian-green;
              margin-left: 0.25rem;
            }
            span {
              font-weight: $font-weight-normal;
              margin-left: $zero;
              font-size: 0.875rem;
              color: #3c3c3c;
              line-height: 1.375rem;
            }
          }
          .Customer-info {
            color: $midnight;
            font-size: 0.875rem;
            font-weight: $font-weight-semibold;
            margin: $zero;
            display: $d-flex;
            justify-content: $start;
            span {
              font-weight: $font-weight-normal;
              margin-left: $zero;
              font-size: 0.875rem;
              color: #3c3c3c;
              line-height: 1.375rem;
            }
            .customer_name_quotation {
              text-transform: $text-transform-capitalize;
            }
            .add-tooltip {
              position: $position-relative;
              white-space: $nowrap;
              text-overflow: $text-overflow-ellipsis;
              div {
                width: 270px;
                overflow: $overflow-hidden;
                text-overflow: $text-overflow-ellipsis;
                white-space: $nowrap;
              }
              .tooltip-text {
                position: $position-absolute;
                bottom: 150%;
                left: 19%;
                z-index: $z-index-one;
                width: $fit-content;
                background-color: $black;
                color: $white;
                text-align: $center;
                border-radius: 0.375rem;
                padding: 0.313rem;
                margin-left: -3.75rem;
                visibility: hidden;
                &::after {
                  content: "";
                  position: $position-absolute;
                  top: $hundred-p;
                  left: $fifty-p;
                  margin-left: -0.313rem;
                  border-width: 0.313rem;
                  border-style: $border-style-solid;
                  border-color: $black $border-transparent $border-transparent
                    $border-transparent;
                }
              }
              &:hover {
                .tooltip-text {
                  visibility: $visibility-visible;
                }
              }
            }
          }
          .company-info {
            font-size: 0.875rem;
            color: $midnight;
            font-weight: $font-weight-semibold;
            span {
              color: $manatee;
            }
          }
        }
      }
      .modal-table-main {
        width: $hundred-p;
        max-height: 400px;
        overflow: $auto;
        .table {
          padding: $zero;
          .table-first {
            padding: $zero;
            .ant-table {
              .ant-table-container {
                border-inline-start: $none;
                border-top: 0.063rem $border-style-solid $border-transparent;
                .ant-table-content {
                  table {
                    .ant-table-thead {
                      tr {
                        display: $d-flex;
                        th {
                          border-right: 0.063rem $border-style-solid
                            $lavender-mist;
                          border-bottom: 0.063rem $border-style-solid
                            $lavender-mist;
                          border-top: 0.063rem $border-style-solid
                            $lavender-mist;
                          display: $d-block;
                          padding: 0.75rem;
                        }
                        th:nth-child(1) {
                          max-width: 205px;
                          min-width: 205px;
                          width: 205px;
                          border-left: 0.063rem $border-style-solid
                            $lavender-mist;
                        }
                        th:nth-child(2) {
                          width: 170px;
                          min-width: 170px;
                          max-width: 170px;
                        }
                        th:nth-child(3) {
                          width: 110px;
                          min-width: 110px;
                          max-width: 110px;
                        }
                        th:nth-child(4) {
                          width: 90px;
                          min-width: 90px;
                          max-width: 90px;
                        }
                        th:nth-child(5) {
                          width: 130px;
                          min-width: 130px;
                        }
                        th:last-child {
                          width: 122px;
                          min-width: 122px;
                        }
                        th {
                          background: $alabaster;
                          border-radius: $zero;
                        }
                      }
                    }
                    .ant-table-tbody {
                      tr {
                        display: $d-flex;
                        td {
                          border-right: 0.063rem $border-style-solid
                            $lavender-mist;
                          border-bottom: 0.063rem $border-style-solid
                            $lavender-mist;
                          display: $d-block;
                          padding: 0.75rem;
                        }
                        td:nth-child(1) {
                          max-width: 205px;
                          min-width: 205px;
                          width: 205px;
                          overflow: $overflow-hidden;
                          text-overflow: $text-overflow-ellipsis;
                          white-space: $nowrap;
                          border-left: 0.063rem $border-style-solid
                            $lavender-mist;
                        }
                        td:nth-child(2) {
                          width: 170px;
                          min-width: 170px;
                          max-width: 170px;
                          overflow: $overflow-hidden;
                          text-overflow: $text-overflow-ellipsis;
                          white-space: $nowrap;
                        }
                        td:nth-child(3) {
                          width: 110px;
                          min-width: 110px;
                          max-width: 110px;
                          overflow: $overflow-hidden;
                          text-overflow: $text-overflow-ellipsis;
                          white-space: $nowrap;
                        }
                        td:nth-child(4) {
                          width: 90px;
                          min-width: 90px;
                          max-width: 90px;
                          overflow: $overflow-hidden;
                          text-overflow: $text-overflow-ellipsis;
                          white-space: $nowrap;
                        }
                        td:nth-child(5) {
                          width: 130px;
                          min-width: 130px;
                          max-width: 130px;
                          overflow: $overflow-hidden;
                          text-overflow: $text-overflow-ellipsis;
                          white-space: $nowrap;
                        }
                        td:last-child {
                          width: 122px;
                          min-width: 122px;
                          max-width: 122px;
                          overflow: $overflow-hidden;
                          text-overflow: $text-overflow-ellipsis;
                          white-space: $nowrap;
                          p {
                            margin: $zero;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .table-sec {
          display: $d-flex;
          div {
            padding: 0.75rem;
            box-sizing: $box-sizing-border;
            border-right: 0.063rem $border-style-solid $border-transparent;
            border-bottom: 0.063rem $border-style-solid $border-transparent;
            display: $d-block;
          }
          div:nth-child(1) {
            max-width: 205px;
            min-width: 205px;
            width: 205px;
            border-left: 0.063rem $border-style-solid $border-transparent;
          }
          div:nth-child(2) {
            max-width: 170px;
            min-width: 170px;
            width: 170px;
          }
          div:nth-child(3) {
            width: 110px;
            min-width: 110px;
            max-width: 110px;
            border-right: 0.063rem $border-style-solid $border-transparent;
          }
          div:nth-child(4) {
            width: 90px;
            min-width: 90px;
            max-width: 90px;
            padding-left: 1rem;
            border-right: 0.063rem $border-style-solid $lavender-mist;
            border-bottom: 0.063rem $border-style-solid $border-transparent;
          }
          div:nth-child(5) {
            width: 130px;
            min-width: 130px;
            max-width: 130px;
            border-right: 0.063rem $border-style-solid $lavender-mist;
            border-bottom: 0.063rem $border-style-solid $lavender-mist;
            font-weight: $font-weight-semibold;
          }
          div:last-child {
            width: 122px;
            min-width: 122px;
            max-width: 122px;
            border-right: 0.063rem $border-style-solid $lavender-mist;
            border-bottom: 0.063rem $border-style-solid $lavender-mist;
            font-weight: $font-weight-semibold;
          }
        }
      }
    }
    .ant-modal-footer {
      padding: 0.313rem 1rem 1rem;
      margin: $zero;
      .view-modal-print-btn {
        width: $fit-content;
        background: $persian-green !important;
        box-shadow:
          #03b2885e 0px 6px 12px -2px,
          #03b28845 0px 3px 7px -3px;
        padding: $zero 0.938rem;
        .ant-image {
          .ant-image-img {
            margin-bottom: 0.313rem;
          }
        }
        &::after {
          content: "";
          background: $medium-slate-blue;
        }
        &:hover {
          box-shadow:
            #4c4de361 0px 6px 12px -2px,
            #4c4de342 0px 3px 7px -3px;
        }
        span {
          margin-right: 0.313rem;
        }
      }
    }
  }
}
.email-modal-main {
  .ant-modal-content {
    padding: $zero $zero 1rem;
    .ant-modal-body {
      position: $position-relative;
      height: 160px;
      .email-image {
        text-align: $center;
        svg {
          height: 170px !important;
        }
        p {
          margin: $zero;
        }
      }
      p {
        margin: $zero;
      }
      .email-text {
        position: $position-absolute;
        top: 76%;
        left: $fifty-p;
        transform: translate(-50%, 0);
        font-size: 1rem;
        font-weight: $font-weight-normal;
        margin-top: $zero;
        white-space: $nowrap;
        text-align: $center;
        @media screen and (max-width: 768px) {
          font-size: 0.75rem;
        }
      }
    }
    .ant-modal-footer {
      padding: $zero 1.25rem;
      .btn-main {
        display: $d-flex;
        justify-content: $center;
        align-items: $center;
        gap: 1.25rem;
        .cancel-btn {
          width: $hundred-p;
          background: $bg-transparent !important;
          color: $jasper !important;
          box-shadow:
            #dc354575 0px 6px 12px -2px,
            #dc35454f 0px 3px 7px -3px;
          border: 0.063rem $border-style-solid $jasper;
          &::after {
            content: "";
            background: $jasper;
            border: 0.063rem $border-style-solid $jasper;
          }
          &:hover {
            box-shadow:
              #dc354575 0px 6px 12px -2px,
              #dc35454f 0px 3px 7px -3px;
            border: 0.063rem $border-style-solid $jasper;
            border-color: $jasper !important ;
            color: $white !important;
          }
        }
        .send-btn {
          width: $hundred-p;
          background: $persian-green !important;
          box-shadow:
            #03b2885e 0px 6px 12px -2px,
            #03b28845 0px 3px 7px -3px;
          padding: $zero 0.938rem;
          &::after {
            content: "";
            background: linear-gradient(
              132deg,
              $neon-blue 4.27%,
              $medium-slate-blue
            );
          }
          &:hover {
            box-shadow:
              #4c4de361 0px 6px 12px -2px,
              #4c4de342 0px 3px 7px -3px;
          }
        }
      }
    }
  }
}
