@import "../../../styles/variable.scss";

.ant-modal-content {
  .ant-modal-header {
    .ant-modal-title {
      padding: 1rem;
      border-bottom: 0.063rem $border-style-solid $lavender-mist;
      color: $midnight;
      font-size: 1.25rem;
      font-weight: $font-weight-bold;
      line-height: 1.5;
    }
  }
  .ant-modal-close {
    top: 0.75rem;
    right: 1rem;
    width: 20px;
    height: 20px;
    border-radius: $fifty-p;
    border: 0.063rem $border-style-solid $jasper;
    background: $jasper;
    transition: all 0.3s $transition-ease-in-out;
    box-shadow: #dc3545 0px 3px 8px -2px, #dc3545 0px 3px 7px -3px;
    .ant-modal-close-x {
      .ant-modal-close-icon {
        svg {
          width: $hundred-p;
          height: 12px;
          fill: $white;
          transition: all 0.3s $transition-ease-in-out;
        }
      }
    }
    &:hover {
      background: #dc354538;
      border: 0.063rem $border-style-solid $jasper;
      .ant-modal-close-x {
        .ant-modal-close-icon {
          svg {
            fill: $jasper;
          }
        }
      }
    }
  }
  .ant-modal-footer {
    margin: $zero;
  }
}
.print-button-main {
  text-align: $center;
  .print-btn {
    background: $persian-green !important;
    box-shadow:
    #03b2887d 0px 6px 12px -2px,
    #03b28859 0px 3px 7px -3px;
    padding: $zero;
    &::after {
      content: "";
      background: $medium-slate-blue;
    }
    &:hover {
      box-shadow:
        #4c4de361 0px 6px 12px -2px,
        #4c4de342 0px 3px 7px -3px;
      color: $white !important;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: $zero;
  }
}
.payment-modal{
  width: 690px !important;
  .ant-modal-header {
    margin: $zero;
    .ant-modal-title {
      font-size: 1.125rem;
      padding: $zero $zero 0.375rem $zero;
    }
  }
  .ant-modal-body{
    padding-top: 0.625rem;
    max-height: 795px;
    overflow: $auto;
  }
  .ant-modal-close {
    top: 0.625rem;
    right: 1rem;
    width: 20px;
    height: 20px;
    border-radius: $fifty-p;
    border: 0.063rem $border-style-solid $jasper;
    background: $jasper;
    transition: all 0.3s $transition-ease-in-out;
    box-shadow: #dc3545 0px 3px 8px -2px, #dc3545 0px 3px 7px -3px;
    .ant-modal-close-x {
      .ant-modal-close-icon {
        svg {
          width: $hundred-p;
          height: 12px;
          fill: $white;
          transition: all 0.3s $transition-ease-in-out;
        }
      }
    }
    &:hover {
      background: #dc354538;
      border: 0.063rem $border-style-solid $jasper;
      .ant-modal-close-x {
        .ant-modal-close-icon {
          svg {
            fill: $jasper;
          }
        }
      }
    }
  }
  .ant-modal-content{
    padding: 0.5rem 1rem 0.625rem !important;
  }
}