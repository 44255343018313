@import "../../../styles/variable.scss";

.backup-list-main {
  background: $alice-blue;
  padding: 1.25rem;
  padding-top: 0.875rem;
  padding-bottom: $zero;
  @media screen and (min-width: 1440px) {
    padding: 1.875rem;
    padding-top: 1rem;
    padding-bottom: $zero;
  }
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
    padding-bottom: $zero;
  }
  .table-wrap {
    .table-header-main {
      .print-button-main {
        .new-button {
          width: 199px;
        }
        .generate-backup-button {
          width: 190px;
          &::after {
            content: "";
            background: $jasper;
          }
          &:hover {
            box-shadow:
              #dc354575 0px 6px 12px -2px,
              #dc35454f 0px 3px 7px -3px;
          }
          @media screen and (max-width: 768px) {
            width: 150px;
            font-size: 0.813rem;
          }
        }
      }
      .filter-button-main {
        background: $white;
        padding: 1rem;
        @media screen and (max-width: 576px) {
          padding: 0.25rem;
        }
        .backup-file-main {
          background: $bg-transparent;
          padding: $zero;
          align-items: $center;
        }
      }
    }
    .table {
      background: $bg-transparent;
      padding: $zero;
    }
  }
  .backup-list-table {
    background: $white;
    overflow-x: $auto;
    height: calc(100vh - 328px);
    @media screen and (min-width: 1500px) {
      height: calc(100vh - 348px);
    }
    @media screen and (max-width: 1200px) {
      height: 320px;
    }
    .ant-spin-nested-loading {
      background: $white;
      height: calc(100vh - 328px);
      overflow: $auto;
      @media screen and (min-width: 1500px) {
        height: calc(100vh - 348px);
      }
      @media screen and (max-width: 1200px) {
        height: 320px;
      }
    }
    .ant-table-content {
      background: $white;
    }
    table {
      .ant-table-wrapper {
        overflow-x: $auto;
        overflow-y: $overflow-hidden;
      }
      colgroup {
        col {
          width: 16px !important;
        }
      }
      .ant-table-thead {
        tr {
          th {
            position: $position-sticky;
            top: $zero;
            z-index: $z-index-one;
            font-size: 0.938rem;
            @media screen and (max-width: 768px) {
              font-size: 0.813rem;
            }
          }
          .ant-table-cell {
            padding: 0.75rem;
            .ant-checkbox-wrapper {
              .ant-checkbox {
                .ant-checkbox-inner {
                  border: 0.063rem $border-style-solid $blue-lotus;
                }
              }
              .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background: $blue-lotus;
                }
              }
            }
          }
          .ant-table-column-sort{
            background: $white;
          }
          .ant-table-cell:first-child {
            padding-left: $zero;
            border-start-start-radius: $zero;
          }
          .ant-table-cell:last-child {
            position: $position-sticky !important;
            z-index: 3;
            width: 130px;
            text-align: $center;
            border-start-end-radius: $zero;
            box-shadow: $table-box-shadow;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-row {
          .ant-table-cell {
            padding: 0.75rem;
            p {
              margin: $zero;
            }
            .ant-checkbox-wrapper {
              .ant-checkbox {
                .ant-checkbox-inner {
                  border: 0.063rem $border-style-solid $blue-lotus;
                }
              }
              .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background: $blue-lotus;
                }
              }
            }
          }
          .ant-table-column-sort{
            background: $white;
          }
          .ant-table-cell:first-child {
            padding-left: $zero;
          }
          .ant-table-cell:last-child {
            position: $position-sticky !important;
            z-index: $z-index-one;
            div {
              display: $d-flex;
              justify-content: $center;
              align-items: $center;
              span {
                svg {
                  height: 19px;
                  width: $hundred-p;
                  object-fit: $none;
                  @media screen and (max-width: 768px) {
                    height: 18px;
                    width: 15px;
                  }
                }
              }
              span:nth-child(1) {
                cursor: $cursor-pointer;
                svg {
                  fill: $blue-lotus;
                }
              }
              .ant-image {
                cursor: $cursor-pointer;
                margin-left: 0.563rem;
                @media screen and (max-width: 768px) {
                  width: 16px;
                }
              }
            }
          }
          .ant-table-cell-fix-right {
            position: $position-static !important;
          }
        }
      }
    }
  }
}
.backup-modal-main {
  padding-bottom: $zero;
  .ant-modal-content {
    padding: $zero !important;
    .ant-modal-close {
      top: 0.625rem;
      right: 1rem;
      width: 20px;
      height: 20px;
      border-radius: $fifty-p;
      border: 0.063rem $border-style-solid $jasper;
      background: $jasper;
      transition: all 0.3s $transition-ease-in-out;
      box-shadow: #dc3545 0px 3px 8px -2px, #dc3545 0px 3px 7px -3px;
      .ant-modal-close-x {
        .ant-modal-close-icon {
          svg {
            width: $hundred-p;
            height: 12px;
            fill: $white;
            transition: all 0.3s $transition-ease-in-out;
          }
        }
      }
      &:hover {
        background: #dc354538;
        border: 0.063rem $border-style-solid $jasper;
        .ant-modal-close-x {
          .ant-modal-close-icon {
            svg {
              fill: $jasper;
            }
          }
        }
      }
    }
    .ant-modal-header {
      padding: 0.5rem 1rem $zero;
      margin: $zero;
      .ant-modal-title {
        font-size: 1.125rem;
        padding: $zero $zero 0.313rem $zero;
      }
    }
    .ant-modal-body {
      padding: 0.625rem 1rem;
    }
    .ant-modal-footer {
      display: $none;
    }
  }
  .backup-modal-details {
    margin: $zero;
    width: $hundred-p;
    text-align: $center;
    font-size: 0.875rem;
    color: $manatee;
    font-weight: $font-weight-normal;
    line-height: 1.281rem;
    .back-up-text {
      color: #3bbe86;
    }
    .back-up-link {
      color: $midnight;
      font-weight: $font-weight-semibold;
    }
  }
  .backup-submit-btn {
    width: $hundred-p;
    color: $white;
    height: 44px;
    background: $persian-green !important;
    padding: $zero 1.25rem;
    margin-top: 1.5rem;
    box-shadow:
      #03b2885e 0px 6px 12px -2px,
      #03b28845 0px 3px 7px -3px;
    &::after {
      content: "";
      background: $medium-slate-blue;
    }
    &:hover {
      box-shadow:
        #4c4de361 0px 6px 12px -2px,
        #4c4de342 0px 3px 7px -3px;
    }
  }
}
