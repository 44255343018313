@import "../../styles/variable.scss";

.table-header-main {
  display: $d-flex;
  justify-content: $justify-content-between;
  margin-bottom: 0.813rem;
  @media screen and (min-width: 1440px) {
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 992px) {
    flex-wrap: $flex-wrap;
  }
  .filter-button-main {
    position: $position-relative;
    display: $d-flex;
    @media screen and (max-width: 992px) {
      flex-wrap: $flex-wrap;
    }
    .input-wrap {
      .input-label {
        margin: $zero;
      }
      .input-area-main {
        height: 40px;
        .dropdown_input {
          height: 40px;
          width: 135px;
          margin-right: 1.25rem;
          border: 0.063rem $border-style-solid $neon-blue;
        }
      }
    }
    .filter-icon-main {
      width: 16px;
      height: 16px;
      svg {
        @media screen and (max-width: 768px) {
          height: 14px;
          width: 14px;
        }
      }
    }
    .filter-btn {
      width: 100px;
      background: $bg-transparent !important;
      display: $d-flex;
      justify-content: $center;
      align-items: $center;
      gap: 0.625rem;
      border: 0.063rem $border-style-solid $medium-slate-blue;
      color: $medium-slate-blue !important;
      box-shadow: 0 5px 10px 0 #6366f133;
      margin-right: 0.625rem;
      @media screen and (max-width: 768px) {
        width: 80px;
      }
      @media screen and (max-width: 575px) {
        margin-right: 0.313rem;
        margin-bottom: 0.625rem;
      }
      &::after {
        content: "";
        background: $medium-slate-blue;
        border: 0.063rem $border-style-solid $medium-slate-blue;
      }
      &:hover {
        color: $white !important;
        border: 0.063rem $border-style-solid $medium-slate-blue !important;
        svg {
          fill: $white;
          path {
            stroke: $white;
          }
        }
      }
    }
    .input-wrap {
      height: $auto;
      display: $d-inline-block;
     
      .create-input-main {
        height: 55px;
        width: $hundred-p;
        font-size: 0.875rem;
        .input-label {
          margin: $zero;
        }
        .common-input {
          height: 55px;
          padding: 0.75rem 1.25rem;
        }
        &::placeholder {
          color: $aluminium;
        }
      }
    }
    .table-header-search-bar-label {
      margin-bottom: $zero;
    }
    .table-header-search-bar-main {
      height: 40px;
      .table-header-search-bar {
        height: 40px;
        padding: 0.75rem 0.75rem 0.75rem 2.313rem;
      }
      .ant-image {
        position: $position-absolute;
        top: 0.688rem;
        left: 1rem;
        .search-icon {
          width: 14px;
          height: 15px;
          background: $bg-transparent;
        }
      }
    }
    .backup-file-main {
      background: $white;
      padding: 1rem;
      display: $d-flex;
      justify-content: $justify-content-between;
      @media screen and (max-width: 576px) {
        flex-wrap: $flex-wrap;
      }
      .backup-file {
        font-size: 0.875rem;
        font-weight: $font-weight-medium;
        margin: $zero;
        @media screen and (max-width: 576px) {
          font-size: 0.688rem;
        }
        @media screen and (max-width: 425px) {
          width: $hundred-p;
          float: $none;
        }
        span {
          color: $manatee;
        }
      }
      .backup-file-button {
        height: 30px !important;
        background: $none !important;
        color: $neon-blue !important;
        font-size: 0.875rem;
        box-shadow: $none;
        text-align: $end;
        padding: $zero !important;
        @media screen and (max-width: 576px) {
          text-align: $start;
          margin-top: 0.625rem;
          font-size: 0.688rem;
          height: $auto !important;
        }
        @media screen and (max-width: 425px) {
          width: $hundred-p;
          float: left;
          margin-top: 0.313rem;
          text-align: $start;
        }
        &::after {
          background: $none !important;
        }
      }
    }
  }
  .print-button-main {
    margin-top: $zero !important;
    display: $d-flex;
    @media screen and (max-width: 992px) {
      flex-wrap: $flex-wrap;
    }
    .print-btn {
      width: 100px;
      background: $persian-green !important;
      box-shadow:
        #03b2887d 0px 6px 12px -2px,
        #03b28859 0px 3px 7px -3px;
      margin-right: 0.938rem;
      &::after {
        content: "";
        background: $medium-slate-blue;
      }
      &:hover {
        box-shadow:
          #4c4de361 0px 6px 12px -2px,
          #4c4de342 0px 3px 7px -3px;
      }
      span {
        padding-right: 1.75rem;
        @media screen and (max-width: 768px) {
          padding-right: 1.25rem;
        }
      }
      @media screen and (max-width: 768px) {
        font-size: 0.813rem;
      }
      @media screen and (max-width: 575px) {
        font-size: 0.875rem;
        margin-right: 0.25rem;
      }
      @media screen and (max-width: 425px) {
        margin-right: 0.938rem;
        margin-bottom: 0.625rem;
      }
      .ant-image {
        position: $position-absolute;
        top: 0.813rem;
        right: 1.375rem;
        @media screen and (max-width: 768px) {
          top: 0.438rem;
          right: 1.563rem;
        }
        img {
          @media screen and (max-width: 768px) {
            height: 14px;
            width: 14px;
          }
        }
        .search-icon {
          width: 15px;
          height: 15px;
          background: $bg-transparent;
        }
      }
    }
    .new-button {
      width: 155px;
      &::after {
        content: "";
        background: $jasper;
      }
      &:hover {
        box-shadow:
          #dc354575 0px 6px 12px -2px,
          #dc35454f 0px 3px 7px -3px;
      }
      @media screen and (max-width: 992px) {
        width: 135px;
        font-size: 0.813rem;
      }
      .anticon {
        margin-inline-start: 0.438rem;
      }
    }
  }
  .generate-backup-button {
    width: 200px;
    &::after {
      content: "";
      background: $jasper;
    }
    &:hover {
      box-shadow:
        #dc354575 0px 6px 12px -2px,
        #dc35454f 0px 3px 7px -3px;
    }
    @media screen and (max-width: 768px) {
      width: 160px;
      font-size: 0.813rem;
    }
  }
  .purchase_return_button {
    width: 230px;
  }
}
.filter-modal-main {
  width: 720px !important;
  @media screen and (max-width: 992px) {
    width: 590px !important;
  }
  .ant-modal-content {
    padding: $zero !important;
    .ant-modal-close {
      top: 0.625rem;
      right: 1rem;
      width: 20px;
      height: 20px;
      border-radius: $fifty-p;
      border: 0.063rem $border-style-solid $jasper;
      background: $jasper;
      transition: all 0.3s $transition-ease-in-out;
      box-shadow:
        #dc3545 0px 3px 8px -2px,
        #dc3545 0px 3px 7px -3px;
      .ant-modal-close-x {
        .ant-modal-close-icon {
          svg {
            width: $hundred-p;
            height: 12px;
            fill: $white;
            transition: all 0.3s $transition-ease-in-out;
          }
        }
      }
      &:hover {
        background: #dc354538;
        border: 0.063rem $border-style-solid $jasper;
        .ant-modal-close-x {
          .ant-modal-close-icon {
            svg {
              fill: $jasper;
            }
          }
        }
      }
    }
    .ant-modal-header {
      margin-bottom: $zero;
      .ant-modal-title {
        color: $midnight;
        font-size: 1.125rem;
        font-weight: $font-weight-bold;
        line-height: 1.5;
        margin: $zero 1rem;
        padding: 0.375rem $zero;
        border-bottom: 0.063rem $border-style-solid $lavender-mist;
      }
    }
    .ant-modal-body {
      padding: 0.563rem 1rem $zero;
      @media screen and (max-width: 767px) {
        max-height: 440px;
        overflow: $auto;
      }
    }
    .ant-modal-footer {
      padding: 0.563rem 1rem 0.75rem;
      .filter-modal-buttons-main {
        width: $hundred-p;
        display: $d-flex;
        gap: 1.25rem;
        .filter-cancel-btn {
          background: $bg-transparent !important;
          color: $jasper !important;
          box-shadow: 0 5px 10px 0 #dc384833;
          border: 0.063rem $border-style-solid $jasper !important;
          .filter-icon {
            padding-left: 0.5rem;
          }
          &::after {
            content: "";
            background: $jasper;
            color: $white;
            border: 0.063rem $border-style-solid $jasper;
          }
          &:hover {
            color: $white !important;
            border: 0.063rem $border-style-solid $jasper !important;
          }
        }
        .filter-button {
          background: $persian-green !important;
          box-shadow:
            #03b2887d 0px 6px 12px -2px,
            #03b28859 0px 3px 7px -3px;
          &::after {
            content: "";
            background: $medium-slate-blue;
          }
          &:hover {
            box-shadow:
              #4c4de361 0px 6px 12px -2px,
              #4c4de342 0px 3px 7px -3px;
          }
        }
      }
    }
  }
  .filter-modal-input-main {
    .filter-input-wrap {
      .input-wrap{
        .input-area-main{
          height: 70px;
        }
      }
      .date-picker-input {
        background: $alice-blue;
        margin-bottom: 0.875rem;
      }
      .filter-input {
        background: $alice-blue;
      }
      .filter-dropdown {
        background: $alice-blue;
      }
    }
  }
}
