@import "../../styles/variable.scss";

.navbar-wrap {
  position: $position-fixed;
  top: $zero;
  left: $zero;
  z-index: 99;
  width: $hundred-p;
  max-width: 2000px;
  background: $white;
  box-shadow: 0 4px 30px 0 #00000008;
  margin: $zero $auto;
  @media screen and (min-width: 2000px) {
    left: $fifty-p;
    transform: translate(-50%, 0);
  }
  .nav-main {
    width: $hundred-p;
    height: 60px;
    display: $d-flex;
    align-items: $center;
    justify-content: $justify-content-between;
    padding: $zero 3.125rem;
    @media screen and (max-width: 1440px) {
      padding: $zero 0.938rem;
    }
    @media screen and (max-width: 768px) {
      max-width: 720px;
      margin: $zero $auto;
    }
    @media screen and (max-width: 576px) {
      position: $position-relative;
      max-width: 540px;
      height: 121px;
      flex-wrap: $flex-wrap;
      padding: 0.938rem;
    }
    .logo-img-wrap {
      display: $d-flex;
      align-items: $center;
      @media screen and (max-width: 576px) {
        width: $hundred-p;
        justify-content: $justify-content-between;
      }
      .logo-img-main {
        max-width: 180px;
        height: 40px;
        display: $d-inline-table;
        padding-right: 1.25rem;
        .ant-image {
          width: $auto;
          max-width: 160px;
          height: $hundred-p;
          max-height: 50px;
          .company-logo {
            width: $hundred-p;
            height: $hundred-p;
            max-height: 50px;
            cursor: $cursor-pointer;
          }
        }
        .menu-icon {
          cursor: $cursor-pointer;
        }
        .logo-img {
          cursor: $cursor-pointer;
        }
      }
      .menu-icon-main {
        .menu-icon {
          cursor: $cursor-pointer;
        }
        @media screen and (max-width: 576px) {
          position: $position-absolute;
          bottom: 1.313rem;
        }
      }
    }
    .navbar-list-wrap {
      display: $d-flex;
      align-items: $center;
      @media screen and (max-width: 576px) {
        width: $hundred-p;
        justify-content: $end;
      }
      .switch-main {
        margin-right: 1.25rem;
        &:focus-visible {
          outline: none;
        }
        &:active {
          box-shadow: none;
        }
      }
      .ant-switch-checked {
        background: linear-gradient(132deg, #4f46e5 4.27%, #6366f1) !important;
        box-shadow:
          #4c4de32e 0px 6px 12px -2px,
          rgba(76, 77, 227, 0.2588235294) 0px 3px 7px -3px;
      }
      .print-screen-btn-main {
        margin-right: 1.25rem;
        @media screen and (max-width: 576px) {
          margin-right: $zero;
        }
        .print-screen-btn {
          background-color: $bg-transparent;
          color: $neon-blue;
          font-size: 0.875rem;
          font-weight: $font-weight-semibold;
          padding: 0.313rem 1rem;
          margin: $zero;
          border-radius: 0.25rem;
          border: 0.125rem $border-style-solid $neon-blue;
          box-shadow: 0 5px 10px 0 #6366f133;
          transition: all 0.3s $transition-ease-in-out;
          cursor: $cursor-pointer;
          @media screen and (max-width: 768px) {
            font-size: 0.75rem;
          }
          &:hover {
            background: $neon-blue;
            color: $white;
            border-color: $border-transparent;
          }
        }
        .logo-btn {
          margin-right: 0.625rem;
        }
      }
      .profile-popover-wrap {
        display: $d-flex;
        align-items: $center;
        margin-left: 1.25rem;
        cursor: $cursor-pointer;
        @media screen and (max-width: 576px) {
          position: $position-absolute;
          top: 0.938rem;
          right: 0.938rem;
          margin-left: $zero;
        }
        .profile-details-main {
          margin-left: 1.25rem;
          .profile-name {
            color: $midnight;
            font-size: 1rem;
            font-weight: $font-weight-bold;
            margin: $zero $zero 0.313rem $zero;
            text-transform: $text-transform-capitalize;
            @media screen and (max-width: 576px) {
              font-size: 0.75rem;
            }
          }
          .profile-data {
            color: $tealish-gray;
            font-size: 0.875rem;
            font-weight: $font-weight-normal;
            margin: $zero;
            text-transform: $text-transform-capitalize;
            @media screen and (max-width: 575px) {
              font-size: 0.688rem;
            }
          }
          @media screen and (max-width: 992px) {
            display: $none;
          }
        }
        .profile-photo-main {
          .profile-photo {
            border-radius: 0.313rem;
          }
        }
      }
      .profile-popover-pos-wrap {
        margin-left: $zero;
        margin-right: 1.25rem;
        @media screen and (max-width: 576px) {
          margin-right: $zero;
        }
      }
      .zoom-screen-icon-main {
        .zoom-screen-icon {
          cursor: $cursor-pointer;
          @media screen and (max-width: 576px) {
            display: $none;
          }
        }
      }
    }
  }
}
.profile-menu-popover {
  .ant-popover-arrow {
    display: $none;
  }
  .ant-popover-content {
    top: 0.625rem;
    .ant-popover-inner {
      width: 120px;
      border-radius: 0.375rem;
      box-shadow: 0 0 15px #00000026;
      padding: 0.313rem 1.25rem;
      .ant-popover-title {
        min-width: $fit-content;
        display: $none;
      }
      .ant-popover-inner-content {
        .profile-menu-main {
          display: $d-flex;
          flex-direction: $flex-column;
          .menu-list {
            color: $tealish-gray;
            font-size: 0.875rem;
            font-weight: $font-weight-normal;
            padding: 0.375rem $zero;
            border-bottom: 0.063rem $border-style-solid #00000008;
            transition: all 0.3s $transition-ease-in-out;
            &:hover {
              color: $medium-slate-blue;
              padding-left: 0.313rem;
            }
          }
          .log-out-btn {
            border: $none;
          }
        }
      }
    }
  }
}
