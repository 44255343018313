@import "../../../styles/variable.scss";

.create-discount-main {
  padding: 0.875rem 1.25rem 1.25rem;
  @media screen and (min-width: 1440px) {
    padding: 1rem 1.875rem 1.875rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
  }
  .search-input-main {
    background: $white;
    border-radius: 0.25rem;
    padding: 1.25rem 1.25rem 0.5rem;
    margin-bottom: 1.25rem;
    display: $d-flex;
    align-items: $center;
    gap: 0.75rem;
    @media screen and (max-width: 768px) {
      padding: 1.25rem;
      flex-wrap: $flex-wrap;
    }
    @media screen and (max-width: 576px) {
      padding: 0.625rem;
      flex-direction: $flex-column;
      gap: $zero;
    }
    .input-wrap {
      width: $hundred-p;
      .table-header-search-bar-main {
        height: 75px;
        .table-header-search-bar {
          background: $alice-blue;
          text-transform: $text-transform-capitalize;
          width: $hundred-p;
        }
      }
    }
    .search-dropdown {
      width: 300px;
      @media screen and (max-width: 576px) {
        width: $hundred-p;
      }
      .input-area-main {
        height: 75px;
        .ant-select {
          background: $alice-blue;
          border: 0.063rem $border-style-solid $neon-blue;
        }
      }
    }
  }
  .discount-details-main {
    background: $white;
    border-radius: 0.25rem;
    padding: 1.25rem;
    @media screen and (max-width: 768px) {
      padding: 1.25rem;
      flex-wrap: $flex-wrap;
    }
    @media screen and (max-width: 576px) {
      padding: 0.625rem;
    }
    .discount-details-title {
      color: $midnight;
      font-size: 1.125rem;
      font-weight: $font-weight-semibold;
      line-height: 1.2;
      border-bottom: 0.063rem $border-style-solid $lavender-mist;
      padding: $zero $zero 0.5rem;
      margin: 0.75rem $zero 0.438rem;
      @media screen and (max-width: 576px) {
        font-size: 0.938rem;
        padding: $zero $zero 0.625rem;
      }
    }
    .discount-details-title-first {
      margin: $zero;
    }
    .disc-Products-input-wrap {
      .input-wrap {
        .product-unit-input-main {
          height: 70px;
        }
      }
    }
    .discount-input-main-wrap {
      margin-bottom: 1.25rem;
      .input-wrap {
        .input-area-main {
          height: 60px;
          .dropdown_input {
            background-color: $alice-blue;
          }
        }
      }
    }
    .discount-input-main-wrap:last-child {
      margin-bottom: $zero;
    }
    .discount-input-wrap {
      .input-wrap {
        .date-picker-input {
          background: $alice-blue;
          margin-bottom: 1rem;
        }
        .input-area-main {
          .dropdown_input {
            background: $alice-blue;
          }
          .common-input {
            background: $alice-blue;
          }
          .table-header-search-bar-main {
            height: 70px;
          }
        }
      }
    }
    .discount-package {
      text-decoration: underline;
      margin: 0.563rem $zero;
    }
    .save-btn-main {
      display: $d-flex;
      align-items: $end;
      .save-button {
        @media screen and (max-width: 767px) {
          margin-top: 1rem;
        }
      }
    }
    .save-button {
      width: 90px;
    }
    .no-data-main {
      .page-not-found-main {
        position: $position-relative;
        @media screen and (max-width: 1200px) {
          height: 180px;
          display: $d-flex;
          flex-direction: $flex-column;
          justify-content: $center;
          align-items: $center;
        }
        .page-not-found-icon {
          height: 298px;
        }
        .not-found-text {
          position: $position-absolute;
          top: 80%;
          left: $fifty-p;
          transform: translate(-50%, -50%);
          width: $hundred-p;
          color: $dark-gray;
          text-align: $center;
          font-size: 0.875rem;
          font-weight: $font-weight-medium;
          margin: $zero;
        }
      }
    }
    .loader-animation-main {
      .loader-animation {
        height: 298px;
      }
    }
    .disc-details-Products-input {
      .discount-input-wrap {
        .input-wrap {
          .table-header-search-bar-main {
            height: 75px;
          }
        }
      }
    }
  }
}
