@import "../../../styles/variable.scss";

.new-quotation-wrap {
  background: $alice-blue;
  padding: 0.875rem 1.25rem 1.25rem;
  @media screen and (min-width: 1440px) {
    padding: 1rem 1.875rem 1.875rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
  }
  .save-btn {
    width: 110px;
  }
  .quotation-new-main {
    .new-quotation-form {
      .input-wrap {
        .input-area-main {
          .ant-input-affix-wrapper {
            padding-left: 2.563rem;
            .ant-input-prefix {
              position: $position-absolute;
              left: 1.25rem;
              top: 0.813rem;
            }
          }
          .ant-select {
            .ant-select-selector {
              .ant-select-selection-search {
                .ant-select-selection-search-input {
                  padding-left: 0.625rem;
                }
              }
            }
          }
        }
      }
      .quotation-search-product {
        position: $position-relative;
        .suggestion-list {
          top: 5rem;
        }
      }
      .add-customer {
        display: $d-flex;
        margin-bottom: 1.25rem;
        align-items: $end;
        @media screen and (max-width:992px) {
          margin-bottom: 1.25rem;
        }
        @media screen and (max-width: 575px) {
          justify-content: $end;
          margin-bottom: 0.875rem;
        }
        .add-customer-btn {
          height: 50px !important;
          span{
            display: $none;
          }
        }
      }
    }
    .quotation-order-table-main {
      justify-content: start;
      .quotation-bill-wrap {
        display: $d-flex;
        align-items: $center;
        .quotation-bill-main {
          width: $hundred-p;
          background: $white;
          border-radius: 0.25rem;
          display: $d-flex;
          flex-direction: $flex-column;
          gap: 0.875rem;
          justify-content: $justify-content-between;
          align-items: $center;
          padding: 1rem;
          margin-left: $auto;
          @media screen and (max-width: 991px) {
            width: $hundred-p;
          }
        }
        .order-tax-main {
          width: $hundred-p;
          display: $d-flex;
          justify-content: $justify-content-between;
          align-items: $center;
        }
        .order-tax-title {
          color: $shuttle-grey;
          font-size: 0.938rem;
          font-weight: $font-weight-medium;
          margin: $zero;
          @media screen and (max-width: 1440px) {
            font-size: 0.813rem;
          }
        }
        .grand-total-main {
          width: $hundred-p;
          display: $d-flex;
          justify-content: $justify-content-between;
          align-items: $center;
        }
      }
    }
    .quotation-notes-textarea {
      height: 150px;
    }
    .ant-input-suffix {
      .ant-image {
        position: $position-absolute;
        right: 0.438rem;
        top: 0.438rem;
        width: 46px;
        height: 46px;
        background: $soft-peach;
        color: $tealish-gray;
        font-weight: $font-weight-semibold;
        border-radius: 0.25rem;
        display: $d-flex;
        justify-content: $center;
        align-items: $center;
        .dollar-icon {
          height: 14px;
          width: 18px;
        }
      }
    }
  }
}
.quotation-table-main {
  background: $alice-blue;
  overflow-y: $auto;
  margin-bottom: 1.25rem;
  .table-wrap {
    .table {
      padding: $zero;
      h1 {
        position: $position-sticky;
        top: $zero;
        left: $zero;
        z-index: $z-index-one;
        width: $hundred-p;
        height: 40px;
        background: $white;
        display: $d-flex;
        align-items: $center;
        padding: $zero 1.25rem;
        @media screen and (max-width: 575px) {
          height: 40px;
          font-size: 0.875rem;
        }
      }
    }
  }
  .quotation-table {
    margin: $zero 1.25rem;
    padding-left: $zero;
    .ant-spin-nested-loading {
      max-height: 250px;
      overflow: $auto;
      @media screen and (min-width: 1500px) {
        max-height: 300px;
      }
      @media screen and (min-width: 2000px) {
        max-height: 400px;
      }
    }
    table {
      .ant-table-wrapper {
        overflow-x: $auto;
        overflow-y: $overflow-hidden;
      }
      colgroup {
        col {
          width: 16px !important;
        }
      }
      .ant-table-thead {
        tr {
          th {
            position: $position-sticky;
            top: $zero;
            z-index: 3;
            padding: 0.563rem;
          }
          .ant-table-cell {
            .ant-table-column-sorters {
              .ant-table-column-title {
                white-space: $nowrap;
              }
            }
          }
          .ant-table-cell:nth-child(1) {
            padding-left: $zero;
            border-start-start-radius: $zero;
          }
          .ant-table-cell:nth-child(7) {
            text-align: $center;
          }
          .ant-table-cell:last-child {
            width: 87px;
            box-shadow: -2px -7px 10px #0000001c;
            text-align: $center;
            border-start-end-radius: $zero;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-row {
          .ant-table-cell {
            padding: 0.563rem;
            .ant-tag-error {
              background: $amour;
              color: $red;
              font-weight: $font-weight-semibold;
              border: $none;
              padding: 0.125rem 0.625rem;
            }
            .ant-tag-success {
              background: $clear-day;
              color: $persian-green;
              font-weight: $font-weight-semibold;
              border: $none;
              padding: 0.125rem 0.625rem;
            }
            .ant-checkbox-wrapper {
              .ant-checkbox {
                .ant-checkbox-inner {
                  border: 0.063rem $border-style-solid $blue-lotus;
                }
              }
              .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background: $blue-lotus;
                }
              }
            }
            .input-wrap {
              .input-label {
                margin: $zero;
              }
              .input-area-main {
                height: 33px;
                .ant-input {
                  height: 33px;
                  background: $alice-blue;
                  width: 110px;
                  padding-left: 0.375rem;
                }
                .price-input {
                  height: 33px;
                  background: $alice-blue;
                  width: 130px;
                  padding-left: 0.375rem !important;
                }
              }
            }
          }
          .ant-table-cell:nth-child(7) {
            display: $d-flex;
            gap: 1.75rem;
            align-items: $center;
            justify-content: $center;
            .wrap {
              width: 115px;
              height: 33px;
              background: $soft-peach;
              border-radius: 0.125rem;
              display: $d-flex;
              justify-content: $justify-content-between;
              align-items: $center;
              user-select: $none;
              padding: 0.25rem;
              .ant-image {
                height: 24px;
                width: 25px;
                background: linear-gradient(
                  132deg,
                  #4f46e5 4.27%,
                  #6366f1 100%
                );
                cursor: $cursor-pointer;
                display: $d-flex;
                justify-content: $center;
                align-items: $center;
                img {
                  height: 10px;
                  width: 10px;
                  fill: $white;
                  font-weight: $font-weight-semibold;
                }
              }
              span:nth-child(2) {
                font-weight: $font-weight-medium;
                color: $black;
              }
            }
          }
          .ant-table-cell:nth-child(1) {
            padding-left: $zero;
          }
          .ant-table-cell:nth-child(4) {
            max-width: 150px;
            overflow: $overflow-hidden;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
          }
          .ant-table-cell:last-child {
            div {
              display: $d-flex;
              align-items: $center;
              gap: 0.563rem;
              justify-content: $center;
              .ant-image {
                cursor: $cursor-pointer;
                img {
                  height: $hundred-p;
                  width: 19px;
                  fill: $white;
                  font-weight: $font-weight-semibold;
                }
              }
            }
          }
        }
      }
    }
  }
}
.product-view-list {
  .ant-modal-content {
    height: $hundred-p;
    padding: $zero !important;
    .ant-modal-close {
      top: 0.625rem;
      right: 1rem;
      width: 20px;
      height: 20px;
      border-radius: $fifty-p;
      border: 0.063rem $border-style-solid $jasper;
      background: $jasper;
      transition: all 0.3s $transition-ease-in-out;
      box-shadow:
        #dc3545 0px 3px 8px -2px,
        #dc3545 0px 3px 7px -3px;
      .ant-modal-close-x {
        .ant-modal-close-icon {
          svg {
            width: $hundred-p;
            height: 12px;
            fill: $white;
            transition: all 0.3s $transition-ease-in-out;
          }
        }
      }
      &:hover {
        background: #dc354538;
        border: 0.063rem $border-style-solid $jasper;
        .ant-modal-close-x {
          .ant-modal-close-icon {
            svg {
              fill: $jasper;
            }
          }
        }
      }
    }
    .ant-modal-header {
      padding: 0.5rem 1rem $zero;
      margin: $zero;
      .ant-modal-title {
        font-size: 1.125rem;
        padding: $zero $zero 0.313rem $zero;
      }
    }
    .ant-modal-body {
      max-height: 550px;
      overflow-y: $auto;
      overflow-x: $overflow-hidden;
      padding: 0.563rem 0.938rem;
      padding-bottom: $zero;
      .table-wrap {
        overflow: $auto;
        .table {
          padding: $zero;
          .ant-table-wrapper {
            padding: $zero;
          }
          .product-list-table {
            .ant-table-content {
              max-height: 535px;
              overflow: $auto;
              table {
                border: 0.063rem $border-style-solid $lavender-mist;
                border-collapse: $border-collapse;
                .ant-table-thead {
                  tr {
                    .ant-table-cell {
                      position: $position-sticky;
                      top: -0.063rem;
                      z-index: $z-index-one;
                      background-color: $alabaster;
                      border: 0.063rem $border-style-solid $lavender-mist;
                      padding: 0.75rem;
                    }
                    .ant-table-cell:first-child{
                      border-start-start-radius: $zero;
                    }
                    .ant-table-cell:last-child{
                      border-start-end-radius: $zero;
                    }
                  }
                }
                .ant-table-tbody {
                  .ant-table-row {
                    .ant-table-cell {
                      padding: 0.75rem;
                      border: 0.063rem $border-style-solid $lavender-mist;
                    }
                    .ant-table-cell:nth-child(1) {
                      max-width: 150px;
                      overflow: $overflow-hidden;
                      text-overflow: $text-overflow-ellipsis;
                      white-space: $nowrap;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .ant-modal-footer {
      padding: 0.563rem 0.938rem;
      .btn-main {
        width: $hundred-p;
        display: $d-flex;
        justify-content: $end;
        button {
          width: 100px;
          margin-left: 1.25rem;
        }
        button:nth-child(1) {
          margin-left: $zero;
        }
        .mail-btn {
          background: $persian-green !important;
          box-shadow:
            #03b2885e 0px 6px 12px -2px,
            #03b28845 0px 3px 7px -3px;
          &::after {
            content: "";
            background: linear-gradient(
              132deg,
              $neon-blue 4.27%,
              $medium-slate-blue
            );
          }
          &:hover {
            box-shadow:
              #4c4de361 0px 6px 12px -2px,
              #4c4de342 0px 3px 7px -3px;
          }
        }
        .cancel-btn {
          box-shadow:
            #dc354575 0px 6px 12px -2px,
            #dc35454f 0px 3px 7px -3px;
          background: #dc3545 !important;
          &::after {
            content: "";
            background: linear-gradient(
              132deg,
              $neon-blue 4.27%,
              $medium-slate-blue
            );
          }
          &:hover {
            box-shadow:
              #4c4de361 0px 6px 12px -2px,
              #4c4de342 0px 3px 7px -3px !important;
          }
        }
      }
    }
  }
}
