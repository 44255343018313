@import "../../../styles/variable.scss";

.supplier-product-list-main {
  background: $alice-blue;
  padding: 1.25rem;
  padding-top: 0.875rem;
  @media screen and (min-width: 1440px) {
    padding: 1.875rem;
    padding-top: 1rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
  }
  .table-wrap {
    .table-header-main{
      .print-button-main{
        .generate-backup-button{
          height: 50px;
          @media screen and (max-width:768px) {
            height: 40px;
          }
        }
      }
    }
    .table {
      padding: $zero;
      background: $bg-transparent;
      .supplier-product-list-table {
        height: calc(100vh - 302px);
        background: $white;
        overflow-x: $overflow-hidden;
        overflow-y: $auto;
        @media screen and (min-width: 1500px) {
          height: calc(100vh - 322px);
        }
        @media screen and (max-width: 1200px) {
          height: 340px;
        }
        @media screen and (max-width: 768px) {
          height: 336px;
        }
        .ant-spin {
          top: 2.25rem;
        }
        .ant-spin-nested-loading {
          height: calc(100vh - 302px);
          background: $white;
          overflow-x: $auto;
          @media screen and (min-width: 1500px) {
            height: calc(100vh - 322px);
          }
          @media screen and (max-width: 1200px) {
            height: 340px;
          }
          @media screen and (max-width: 768px) {
            height: 336px;
          }
        }
        table {
          .ant-table-thead {
            tr {
              th {
                position: $position-sticky;
                top: $zero;
                z-index: 2;
                height: 45px;
                padding: $zero 0.563rem;
              }
              .ant-table-cell:first-child {
                padding-left: $zero;
                border-start-start-radius: $zero;
              }
              .ant-table-cell:last-child {
                position: $position-sticky;
                right: $zero;
                border-start-end-radius: $zero;
                min-width: 110px;
                text-align: $center;
                box-shadow: $table-box-shadow;
                @media screen and (max-width: 768px) {
                  max-width: 90px;
                  min-width: 90px !important;
                  width: 90px;
                  overflow: $overflow-hidden;
                  text-overflow: $text-overflow-ellipsis;
                  white-space: $nowrap;
                }
              }
            }
          }
          .ant-table-tbody {
            .ant-table-row {
              .ant-table-cell {
              padding: 0.563rem;
              }
              .ant-table-cell:nth-child(1) {
                padding-left: $zero;
                max-width: 150px;
                overflow: $overflow-hidden;
                text-overflow: $text-overflow-ellipsis;
                white-space: $nowrap
              }
              .ant-table-cell:nth-child(3) {
                max-width: 150px;
                overflow: $overflow-hidden;
                text-overflow: $text-overflow-ellipsis;
                white-space: $nowrap
              }
              .ant-table-cell:nth-child(4) {
                max-width: 230px;
                overflow: $overflow-hidden;
                text-overflow: $text-overflow-ellipsis;
                white-space: $nowrap
              }
              .ant-table-cell:nth-child(5) {
                max-width: 160px;
                overflow: $overflow-hidden;
                text-overflow: $text-overflow-ellipsis;
                white-space: $nowrap
              }
              .ant-table-cell:nth-child(6) {
                max-width: 160px;
                overflow: $overflow-hidden;
                text-overflow: $text-overflow-ellipsis;
                white-space: $nowrap
              }
              .ant-table-cell:last-child {
                position: $position-sticky;
                right: $zero;
                text-align: $center;
                background: $white;
                @media screen and (max-width: 768px) {
                  max-width: 90px;
                  min-width: 90px !important;
                  width: 90px;
                  overflow: $overflow-hidden;
                  text-overflow: $text-overflow-ellipsis;
                  white-space: $nowrap;
                }
                div {
                  display: $d-flex;
                  align-items: $center;
                  justify-content: $center;
                  span {
                    svg {
                      height: 19px;
                      width: $hundred-p;
                      object-fit: $none;
                      fill: $green-blue;
                      cursor: $cursor-pointer;
                      @media screen and (max-width: 768px) {
                        height: 18px;
                        width: 15px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.supplier-product-view {
  .ant-modal-content {
    height: $hundred-p;
    padding: $zero !important;
    .ant-modal-close {
      top: 0.625rem;
      right: 1rem;
      width: 20px;
      height: 20px;
      border-radius: $fifty-p;
      border: 0.063rem $border-style-solid $jasper;
      background: $jasper;
      transition: all 0.3s $transition-ease-in-out;
      box-shadow:
        #dc3545 0px 3px 8px -2px,
        #dc3545 0px 3px 7px -3px;
      .ant-modal-close-x {
        .ant-modal-close-icon {
          svg {
            width: $hundred-p;
            height: 12px;
            fill: $white;
            transition: all 0.3s $transition-ease-in-out;
          }
        }
      }
      &:hover {
        background: #dc354538;
        border: 0.063rem $border-style-solid $jasper;
        .ant-modal-close-x {
          .ant-modal-close-icon {
            svg {
              fill: $jasper;
            }
          }
        }
      }
    }
    .ant-modal-header {
      padding: 0.5rem 1rem $zero;
      margin: $zero;
      .ant-modal-title {
        font-size: 1.125rem;
        padding: $zero $zero 0.313rem $zero;
      }
    }
    .ant-modal-body {
      max-height: 600px;
      overflow-y: $auto;
      overflow-x: $overflow-hidden;
      padding: 0.938rem;
      .supplier-details{
        margin-bottom: 0.625rem;
        .supplier-title{
          margin-top: $zero;
          margin-bottom: 0.313rem;
          font-size: 0.875rem;
          color: $midnight;
          font-weight: $font-weight-semibold;
          display: $d-flex;
          p{
            width: 111px;
            margin: $zero;
          }
          div {
            color: $manatee;
            @media screen and (max-width:575px) {
              max-width: 160px;
              overflow: $overflow-hidden;
              text-overflow: $text-overflow-ellipsis;
              white-space: $nowrap;
            }
          }
        }
      }
      .table-wrap {
        .table-header-main {
          .input-wrap {
            .table-header-search-bar-main {
              .table-header-search-bar {
                background-color: $alice-blue;
              }
              .ant-image{
                top: 0.5rem;
              }
            }
          }
        }
        .table {
          padding: $zero;
          .supplier-modal-table {
            padding: $zero;
            .ant-table-content{
              max-height: 420px;
              overflow: $auto;
            }
            table {
              border: 0.063rem $border-style-solid $lavender-mist;
              border-collapse: $border-collapse;
              .ant-table-thead {
                tr {
                  th {
                    position: $position-sticky;
                    top: -0.125rem;
                    z-index: $z-index-one;
                    background-color: $alabaster;
                    border: 0.063rem $border-style-solid $lavender-mist;
                    padding: 0.75rem;
                  }
                  .ant-table-cell:first-child{
                    border-start-start-radius: $zero;
                  }
                }
              }
              .ant-table-tbody {
                .ant-table-row {
                  .ant-table-cell {
                    border: 0.063rem $border-style-solid $lavender-mist;
                    padding: 0.75rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
