@import "../../styles/variable.scss";

.table {
  .modal-table-main {
    border-spacing: $zero;
    .table-header {
      .table-row {
        display: $d-flex;
        .table-cols {
          background: $platinum;
          font-size: 0.875rem;
          text-align: $start;
          font-weight: $font-weight-medium;
          white-space: $nowrap;
          border-right: 0.063rem $border-style-solid $lavender-mist;
          border-bottom: 0.063rem $border-style-solid $lavender-mist;
          padding: 0.688rem $zero 0.688rem 0.625rem;
          @media screen and (max-width:992px) {
            font-size: 0.813rem;
          }
        }
        .table-cols:nth-child(1) {
          width: 43px;
          border-left: 0.063rem $border-style-solid $border-transparent;
        }
        .table-cols:nth-child(2) {
          width: 140px;
        }
        .table-cols:nth-child(3) {
          width: 80px;
        }
        .table-cols:nth-child(4) {
          width: 95px;
        }
        .table-cols:nth-child(5) {
          width: 110px;
        }
        .table-cols:nth-child(6) {
          width: 100px;
        }
        .table-cols:nth-child(7) {
          width: 100px;
        }
        .table-cols:nth-child(8) {
          width: 120px;
        }
      }
    }
    .table-body {
      .table-body-row {
        display: $d-flex;
        .table-body-cols {
          font-size: 0.875rem;
          text-align: $start;
          white-space: $nowrap;
          border-right: 0.063rem $border-style-solid $lavender-mist;
          border-bottom: 0.063rem $border-style-solid $lavender-mist;
          padding: 0.688rem $zero 0.688rem 0.625rem;
          @media screen and (max-width:992px) {
            font-size: 0.813rem;
          }
        }
        .table-body-cols:nth-child(1) {
          width: 43px;
          border-left: 0.063rem $border-style-solid $lavender-mist;
        }
        .table-body-cols:nth-child(2) {
          width: 140px;
          text-overflow: $text-overflow-ellipsis;
          white-space: $nowrap;
          overflow: $overflow-hidden;
        }
        .table-body-cols:nth-child(3) {
          width: 80px;
        }
        .table-body-cols:nth-child(4) {
          width: 95px;
        }
        .table-body-cols:nth-child(5) {
          width: 110px;
        }
        .table-body-cols:nth-child(6) {
          width: 100px;
        }
        .table-body-cols:nth-child(7) {
          width: 100px;
        }
        .table-body-cols:nth-child(8) {
          width: 120px;
        }
      }
    }
  }
}
