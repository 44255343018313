@import "../../styles/variable.scss";

.forgot-password-page-wrap {
  overflow: $overflow-hidden;
  .forgot-password-main {
    height: $vh-100;
    .forgot-image-image {
      width: $fifty-p;
      background-image: url(../../assest/images/login.webp);
      background-repeat: no-repeat;
      background-size: $cover;
      background-position: bottom;
    }
    .forgot-password-content {
      display: $d-flex;
      justify-content: $center;
      align-items: $center;
      .forgot-password-box {
        width: 80%;
        border: 0.063rem $border-style-solid $alice-blue;
        padding: 1.563rem;
        .forgot-password-title {
          display: $d-block;
          text-align: $center;
          border-bottom: 0.063rem $border-style-solid $water;
          margin-bottom: 1.25rem;
          margin: $zero;
          padding-bottom: 1.563rem;
        }
        .forgot-password-information {
          color: $tealish-gray;
          font-size: 0.875rem;
          font-weight: $font-weight-normal;
        }
        .forgot-password-form-input {
          background: $alice-blue;
        }
        .forgot-password-btn {
          width: $hundred-p;
        }
      }
    }
  }
}
