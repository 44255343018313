@import "../../../styles/variable.scss";

.discount-list-main {
  background: $alice-blue;
  padding: 1.25rem;
  padding-top: 0.875rem;
  @media screen and (min-width: 1440px) {
    padding: 1.875rem;
    padding-top: 1rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
  }
  .table-wrap {
    .table-header-main {
      .print-button-main {
        .generate-backup-button {
          height: 50px;
          @media screen and (max-width: 768px) {
            height: 40px;
          }
        }
      }
    }
    .table {
      padding: $zero;
      background: $bg-transparent;
    }
  }
  .discount-list-table {
    height: calc(100vh - 294px);
    overflow-x: $overflow-hidden;
    background: $white;
    overflow-y: $auto;
    @media screen and (min-width: 1500px) {
      height: calc(100vh - 322px);
    }
    @media screen and (max-width: 1200px) {
      height: 320px;
    }
    @media screen and (max-width: 768px) {
      height: 336px;
    }
    .ant-spin {
      top: 2.25rem;
    }
    .ant-spin-nested-loading {
      max-height: calc(100vh - 294px);
      background: $white;
      overflow-x: $auto;
      @media screen and (min-width: 1500px) {
        max-height: calc(100vh - 322px);
      }
      @media screen and (max-width: 1200px) {
        max-height: 320px;
      }
      @media screen and (max-width: 768px) {
        max-height: 336px;
      }
    }
    table {
      .ant-table-thead {
        tr {
          th {
            height: 45px;
          }
          td {
            background-color: $white;
          }
          .ant-table-cell {
            position: $position-sticky;
            top: $zero;
            z-index: $z-index-one;
            padding: $zero 0.563rem;
            .ant-table-column-sorters {
              .ant-table-column-title {
                white-space: $nowrap;
              }
            }
            .ant-checkbox-wrapper {
              .ant-checkbox {
                .ant-checkbox-inner {
                  border: 0.063rem $border-style-solid $blue-lotus;
                }
              }
              .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background: $blue-lotus;
                }
              }
            }
          }
          .ant-table-cell:first-child {
            padding-left: $zero;
            border-start-start-radius: $zero;
          }
          .ant-table-cell:last-child {
            text-align: $center;
            border-start-end-radius: $zero;
            box-shadow: $table-box-shadow;
            right: $zero;
          }
          .ant-table-cell-fix-right {
            position: $position-sticky !important;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-row {
          .ant-table-cell {
            padding: 0.563rem;
            p {
              margin: $zero;
            }
            .ant-tag-gold {
              border-color: $border-transparent;
              padding: 0.25rem 0.5rem;
            }
            .ant-tag-red {
              border-color: $border-transparent;
              padding: 0.25rem 0.5rem;
            }
            .ant-tag-green {
              border-color: $border-transparent;
              padding: 0.25rem 0.5rem;
              color: $persian-green;
              background: #03b2881f;
            }
            .disabled {
              span {
                cursor: $cursor-not-allowed !important;
                svg {
                  fill: $oslo-grey !important;
                }
              }
            }
          }
          .ant-table-cell-fix-right {
            position: $position-sticky !important;
            z-index: $zero;
          }
          .ant-table-cell:nth-child(1) {
            cursor: $cursor-pointer;
            max-width: 150px;
            padding-left: $zero;
            .ant-table-row-expand-icon {
              height: 16px;
              width: 16px;
              border: 0.063rem $border-style-solid #000eff;
              background: $white;
              border-radius: 0.25rem;
              &::before {
                background: #000eff;
              }
              &::after {
                background: $neon-blue;
              }
            }
          }
          .ant-table-cell:nth-child(2) {
            max-width: 130px;
            overflow: $overflow-hidden;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
          }
          .ant-table-cell:last-child {
            position: $position-sticky;
            right: $zero;
            background: $white;
            text-align: $center;
            display: $d-flex;
            justify-content: $center;
            div{
              display: $d-flex;
              span {
                cursor: $cursor-pointer;
                svg {
                  height: $hundred-p;
                  width: 18px;
                  object-fit: $none;
                  fill: $blue-lotus;
                  @media screen and (max-width: 768px) {
                    width: 15px;
                  }
                }
              }
              .ant-image {
                cursor: $cursor-pointer;
                margin-left: 0.563rem;
                margin-top: -0.25rem;
                img {
                  height: $hundred-p;
                  width: 19px;
                  @media screen and (max-width: 768px) {
                    width: 15px;
                  }
                }
              }
            }
            .ant-image {
              cursor: $cursor-pointer;
              margin-left: 0.563rem;
              img {
                height: $hundred-p;
                width: 19px;
                @media screen and (max-width: 768px) {
                  width: 15px;
                }
              }
            }
          }
        }
        .ant-table-expanded-row {
          .ant-table-cell {
            padding: $zero;
            .table-wrap {
              .disc-expand-modal-table {
                padding: $zero;
                table {
                  .ant-table-thead {
                    tr {
                      .ant-table-cell {
                        position: $position-relative;
                        padding: 0.75rem;
                        z-index: $zero;
                        background: #f3f6f966;
                      }

                      .ant-table-cell:nth-child(1) {
                        width: 180px;
                        overflow: $auto;
                        text-overflow: $text-overflow-ellipsis;
                        white-space: $nowrap;
                        padding-left: 3.75rem;
                        @media screen and (max-width: 684px) {
                          padding-left: 2.5rem;
                        }
                      }
                      .ant-table-cell:nth-child(3) {
                        max-width: 130px;
                        overflow: $auto;
                        text-overflow: $text-overflow-ellipsis;
                        white-space: $nowrap;
                      }
                      .ant-table-cell:nth-child(4),
                      .ant-table-cell:nth-child(5) {
                        width: 70px;
                        overflow: $auto;
                        text-overflow: $text-overflow-ellipsis;
                        white-space: $nowrap;
                      }
                      .ant-table-cell:last-child {
                        width: 100px;
                        box-shadow: $none;
                      }
                    }
                  }
                  .ant-table-tbody {
                    .ant-table-row {
                      .ant-table-cell-row-hover {
                        background: #f3f6f966 !important;
                      }
                      .ant-table-cell {
                        padding: 0.75rem;
                        background: #f3f6f966;
                      }
                      .ant-table-cell:nth-child(1) {
                        width: 180px;
                        overflow: $auto;
                        text-overflow: $text-overflow-ellipsis;
                        white-space: $nowrap;
                        padding-left: 3.75rem;
                        @media screen and (max-width: 684px) {
                          padding-left: 2.5rem;
                        }
                      }
                      .ant-table-cell:nth-child(3) {
                        max-width: 130px;
                        overflow: $auto;
                        text-overflow: $text-overflow-ellipsis;
                        white-space: $nowrap;
                      }
                      .ant-table-cell:nth-child(4),
                      .ant-table-cell:nth-child(5) {
                        width: 70px;
                        overflow: $auto;
                        text-overflow: $text-overflow-ellipsis;
                        white-space: $nowrap;
                      }
                      .ant-table-cell:last-child {
                        width: 100px;
                      }
                    }
                    .ant-table-row:last-child {
                      .ant-table-cell {
                        border-bottom: $none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.discount-product-modal {
  .ant-modal-content {
    padding: $zero !important;
    .ant-modal-close {
      top: 0.625rem;
      right: 1rem;
      width: 20px;
      height: 20px;
      border-radius: $fifty-p;
      border: 0.063rem $border-style-solid $jasper;
      background: $jasper;
      transition: all 0.3s $transition-ease-in-out;
      box-shadow:
        #dc3545 0px 3px 8px -2px,
        #dc3545 0px 3px 7px -3px;
      .ant-modal-close-x {
        .ant-modal-close-icon {
          svg {
            width: $hundred-p;
            height: 12px;
            fill: $white;
            transition: all 0.3s $transition-ease-in-out;
          }
        }
      }
    }
    .ant-modal-header {
      margin-bottom: $zero;
      .ant-modal-title {
        color: $midnight;
        font-size: 1.125rem;
        font-weight: $font-weight-bold;
        line-height: 1.5;
        border-bottom: 0.063rem $border-style-solid $lavender-mist;
        margin: $zero 1rem;
        padding: 0.375rem $zero;
      }
    }
    .ant-modal-body {
      max-height: 500px;
      padding: 1rem;
      padding-top: 0.438rem;
      overflow: $auto;
      @media screen and (max-width: 767px) {
        max-height: 410px;
      }
      .table-wrap {
        .table {
          padding: $zero;
          .disc-modal-table {
            padding: $zero;
            table {
              border: 0.063rem $border-style-solid $lavender-mist;
              border-collapse: collapse;
              .ant-table-thead {
                tr {
                  .ant-table-cell {
                    padding: 0.75rem;
                    background: $alabaster;
                    border: 0.063rem $border-style-solid $lavender-mist;
                  }
                }
              }
              .ant-table-tbody {
                .ant-table-row {
                  .ant-table-cell {
                    padding: 0.75rem;
                    border: 0.063rem $border-style-solid $lavender-mist;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
