@import "../../../styles/variable.scss";

.department-list-main {
  padding: 1.25rem;
  padding-top: 0.875rem;
  @media screen and (min-width: 1440px) {
    padding: 1.875rem;
    padding-top: 1rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
  }
  .table-wrap {
    .table {
      background: $bg-transparent;
      padding: $zero;
      .department-list-table {
        height: calc(100vh - 284px);
        background: $white;
        overflow-x: $overflow-hidden;
        overflow-y: $auto;
        @media screen and (min-width: 1500px) {
          height: calc(100vh - 312px);
        }
        @media screen and (max-width: 1200px) {
          height: 320px;
        }
        @media screen and (max-width: 768px) {
          height: 336px;
        }
        .ant-spin-nested-loading {
          height: calc(100vh - 284px);
          background: $white;
          overflow-x: $auto;
          @media screen and (min-width: 1500px) {
            height: calc(100vh - 312px);
          }
          @media screen and (max-width: 1200px) {
            height: 320px;
          }
          @media screen and (max-width: 768px) {
            height: 336px;
          }
        }
        .ant-table-content {
          table {
            .ant-table-thead {
              tr {
                .ant-table-cell {
                  position: $position-sticky;
                  top: $zero;
                  z-index: $z-index-one;
                  background: $white;
                  padding: 0.563rem;
                }
                .ant-table-cell:nth-child(1) {
                  padding-left: $zero;
                  border-start-start-radius: $zero;
                }
                .ant-table-cell:last-child {
                  border-start-end-radius: $zero;
                  .ant-table-column-sorters {
                    justify-content: $center !important;
                  }
                }
              }
            }
            .ant-table-tbody {
              .ant-table-row {
                .ant-table-cell{
                  padding: 0.563rem;
                }
                .ant-table-cell:nth-child(1) {
                  padding-left: $zero;
                }
                .ant-table-cell:last-child {
                  div {
                    display: $d-flex;
                    align-items: $center;
                    justify-content: $center;

                    span {
                      svg {
                        height: 19px;
                        width: $hundred-p;
                        object-fit: $none;
                        @media screen and (max-width: 768px) {
                          height: 18px;
                          width: 14px;
                        }
                      }
                    }
                    span {
                      height: 19px;
                      svg {
                        fill: $blue-lotus;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.department-list-modal {
  .ant-modal-content {
    padding: $zero !important;
    .ant-modal-close {
      top: 0.75rem;
      right: 1rem;
      width: 20px;
      height: 20px;
      border-radius: $fifty-p;
      border: 0.063rem $border-style-solid $jasper;
      background: $jasper;
      transition: all 0.3s $transition-ease-in-out;
      box-shadow:
        #dc3545 0px 3px 8px -2px,
        #dc3545 0px 3px 7px -3px;
      .ant-modal-close-x {
        .ant-modal-close-icon {
          svg {
            width: $hundred-p;
            height: 12px;
            fill: $white;
            transition: all 0.3s $transition-ease-in-out;
          }
        }
      }
      &:hover {
        background: #dc354538;
        border: 0.063rem $border-style-solid $jasper;
        .ant-modal-close-x {
          .ant-modal-close-icon {
            svg {
              fill: $jasper;
            }
          }
        }
      }
    }
    .ant-modal-header {
      margin-bottom: $zero;
      .ant-modal-title {
        color: $midnight;
        font-size: 1.125rem;
        font-weight: $font-weight-bold;
        line-height: 1.5;
        border-bottom: 0.063rem $border-style-solid $lavender-mist;
        margin: $zero 1rem;
        padding: 0.375rem $zero;
      }
    }
    .ant-modal-body {
      padding: 1rem;
      padding-bottom: $zero;
      padding-top: 0.563rem;
      .department-list-input{
        .input-wrap{
            .input-area-main{
                .ant-select{
                    background: $soft-peach;
                }
                .ant-input{
                    background: $alice-blue;
                }
            }
        }
      }
    }
    .ant-modal-footer {
      padding: 0.313rem 1.25rem 1.25rem;
      margin: $zero;
      .btn-main{
        display: $d-flex;
        justify-content: $end;
        .cancel-btn {
            width: 120px;
            box-shadow:
              #dc354575 0px 6px 12px -2px,
              #dc35454f 0px 3px 7px -3px;
            background: #dc3545 !important;
            &::after {
              content: "";
              background: linear-gradient(
                132deg,
                $neon-blue 4.27%,
                $medium-slate-blue
              );
            }
            &:hover {
              box-shadow:
                #4c4de361 0px 6px 12px -2px,
                #4c4de342 0px 3px 7px -3px !important;
            }
          }
          .save-btn {
            width: 120px;
            background: $persian-green !important;
            box-shadow:
              #03b2885e 0px 6px 12px -2px,
              #03b28845 0px 3px 7px -3px;
            margin-left: 1.25rem;
            &::after {
              content: "";
              background: $medium-slate-blue;
            }
            &:hover {
              box-shadow:
                #4c4de361 0px 6px 12px -2px,
                #4c4de342 0px 3px 7px -3px;
            }
            span {
              margin-right: 0.313rem;
            }
          }
      }
    }
  }
}