@import "../../../styles/variable.scss";

.product-details-wrap {
  padding: 1.25rem;
  padding-top: 0.563rem;
  @media screen and (min-width: 1440px) {
    padding: 1.875rem;
    padding-top: 1rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
  }
  .product-details-form-main {
    background: $white;
    padding: 1.25rem;
    @media screen and (max-width: 576px) {
      padding: 0.625rem;
    }
    .product-table-main {
      .single-product-image-main {
        display: $d-flex;
        flex-direction: $flex-column;
        gap: 1.25rem;
        @media screen and (max-width: 767px) {
          text-align: $center;
        }
 
          .single-product-image {
            max-width: $hundred-p;
            width: $auto;
            height: 200px;
            border-radius: 0.25rem;
          }
        
      }
      .product-type-list-main {
        width: $hundred-p;
        border: 0.063rem $border-style-solid $water;
        border-spacing: $zero;
        .product-types {
          .type-list {
            color: $midnight;
            text-align: $start;
            font-size: 0.875rem;
            font-weight: $font-weight-medium;
            text-transform: $text-transform-capitalize;
            line-height: 1;
            border-right: 0.063rem $border-style-solid $water;
            padding: 1.438rem 1.875rem;
            border-bottom: 0.063rem $border-style-solid $water;
            @media screen and (max-width: 576px) {
              padding: 0.938rem;
              font-size: 0.688rem;
            }
            @media screen and (max-width: 375px) {
              padding: 0.625rem;
            }
          }
          .single-product-green {
            background: $clear-day;
            color: $persian-green;
          }
          .single-product-red {
            background: #ea354830;
            color: $red;
          }
          .single-product {
            max-width: 200px;
            color: $concord;
            font-size: 0.813rem;
            font-weight: $font-weight-normal;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
            line-height: 1;
            text-transform: $text-transform-capitalize;
            border-bottom: 0.063rem $border-style-solid $water;
            text-align: $start;
            overflow: $overflow-hidden;
            padding: 1.438rem 1.875rem;
            @media screen and (max-width: 576px) {
              max-width: 150px;
              font-size: 0.688rem;
              padding: 0.938rem;
            }
            @media screen and (max-width: 375px) {
              max-width: 100px;
              padding: 0.625rem;
            }
          }
          .single-product-title {
            color: $midnight;
          }
          .product-alert-main {
            border-bottom: 0.063rem $border-style-solid $water;
          }
          .stock-type {
            border-bottom: $none;
          }
          .single-product-tag {
            width: $fit-content;
            border-radius: 0.313rem;
            font-size: 0.813rem;
            font-weight: $font-weight-semibold;
            border: $none;
            padding: 0.313rem 0.438rem;
            margin: 1rem 1.875rem;
            @media screen and (max-width: 576px) {
              margin: 0.938rem;
              font-size: 0.688rem;
            }
            @media screen and (max-width: 375px) {
              margin: 0.625rem;
            }
          }
        }
      }
      .veg-and-fruit-title {
        font-weight: $font-weight-medium;
        font-size: 0.938rem;
        margin: $zero;
        margin-top: 1rem;
      }
      .veg-fruit-Package-main {
        border: 0.063rem $border-style-solid $water;
        width: $hundred-p;
        border-spacing: $zero;
        margin-top: 1rem;
        .product-types {
          .product-type-wrap {
            border-right: 0.063rem $border-style-solid $water;
          }
          .product-type-wrap:last-child {
            border-right: $none;
          }
          .type-list {
            color: $midnight;
            text-align: $start;
            font-size: 0.875rem;
            font-weight: $font-weight-medium;
            text-transform: $text-transform-capitalize;
            line-height: 1rem;
            border-right: 0.063rem $border-style-solid $water;
            border-bottom: 0.063rem $border-style-solid $water;
            padding: 0.938rem;
            @media screen and (max-width: 576px) {
              font-size: 0.688rem;
              padding: 0.938rem;
            }
            @media screen and (max-width: 375px) {
              padding: 0.625rem;
            }
          }
          .type-list:last-child {
            border-right: $none;
          }
          .single-product-main {
            width: $hundred-p;
            .single-product-name {
              color: $midnight;
              text-align: $start;
              font-size: 0.875rem;
              font-weight: $font-weight-normal;
              text-transform: $text-transform-capitalize;
              line-height: 1rem;
              border-bottom: 0.063rem $border-style-solid $water;
              padding: 0.938rem;
              @media screen and (max-width: 576px) {
                font-size: 0.688rem;
                padding: 0.938rem;
              }
              @media screen and (max-width: 375px) {
                padding: 0.625rem;
              }
            }
            .single-product {
              color: $concord;
              font-size: 0.813rem;
              font-weight: $font-weight-normal;
              text-overflow: $text-overflow-ellipsis;
              white-space: $nowrap;
              text-align: $start;
              line-height: 1;
              text-transform: $text-transform-capitalize;
              border-bottom: 0.063rem $border-style-solid $water;
              overflow: $overflow-hidden;
              padding: 0.938rem;
              @media screen and (max-width: 576px) {
                font-size: 0.688rem;
              }
              @media screen and (max-width: 375px) {
                white-space: break-spaces;
                padding: 0.625rem;
              }
            }
          }
          .single-product-main:last-child {
            .single-product {
              border-bottom: $none;
            }
            .single-product-name {
              border-bottom: $none;
            }
          }
        }
      }
      .quantity-table-main {
        width: $hundred-p;
        .stock-quantity-title {
          color: $midnight;
          text-align: $start;
          font-size: 0.875rem;
          font-weight: $font-weight-normal;
          line-height: 1;
          padding: 1.438rem 1.875rem 0.438rem;
        }
        .stock-quantity {
          color: $midnight;
          font-size: 0.875rem;
          text-align: $start;
          font-weight: $font-weight-normal;
          line-height: 1;
          border-bottom: 0.063rem $border-style-solid $water;
          border-right: 0.063rem $border-style-solid $water;
          border-left: 0.063rem $border-style-solid $water;
          padding: 1.438rem 1.875rem;
        }
        .stock-warehouse-2 {
          border-bottom: $none;
        }
        .stock-item {
          color: $midnight;
          font-size: 0.875rem;
          text-align: $start;
          font-weight: $font-weight-normal;
          line-height: 1;
          border-bottom: 0.063rem $border-style-solid $water;
          border-right: 0.063rem $border-style-solid $water;
          padding: 1.438rem 1.875rem;
        }
        .stock-item-warehouse-2 {
          border-bottom: $none;
        }
      }
    }
  }
  .print-button-wrap {
    margin-bottom: 0.375rem;
    @media screen and (min-width: 1440px) {
      margin-bottom: 1rem;
    }
    @media screen and (max-width: 576px) {
      margin-bottom: 0.625rem;
    }
    .barcode-main {
      .barcode {
        width: $fit-content;
        height: 80px;
        margin-left: -0.25rem;
        rect:nth-child(1) {
          fill: $bg-transparent !important;
        }
        g {
          rect:nth-child(1) {
            fill: $black !important;
          }
          text {
            font: 1.5rem monospace !important;
          }
        }
      }
    }
  }
  .no-product-main {
    position: $position-relative;
    height: $hundred-p;
    .page-not-found-main {
      height: calc(100vh - 281px);
      .page-not-found-icon {
        position: $position-absolute;
        top: 45%;
        left: $fifty-p;
        height: 300px;
        transform: translate(-50%, -50%);
      }
      .not-found-text {
        position: $position-absolute;
        top: 63%;
        left: $fifty-p;
        transform: translate(-50%, -50%);
        width: $hundred-p;
        color: $dark-gray;
        text-align: $center;
        font-size: 0.875rem;
        font-weight: $font-weight-medium;
        margin: $zero;
      }
    }
  }
  .loader-animation-main {
    .hold-product-loader {
      height: 380px;
    }
    p {
      margin: $zero;
    }
  }
}
