@import "../../../styles/variable.scss";

.new-purchase-main {
  padding: 0.875rem 1.25rem 1.25rem;
  @media screen and (min-width: 1440px) {
    padding: 1rem 1.875rem 1.875rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
  }
  .purchase-new-wrap {
    @media screen and (max-width: 768px) {
      margin-bottom: 1.25rem;
    }
    .new-purchase-input-main {
      .input-wrap {
        .input-area-main {
          .ant-select {
            .ant-select-selector {
              .ant-select-selection-search {
                padding-left: 0.625rem;
              }
            }
          }
        }
      }
    }
    .purchase-order-table-main {
      justify-content: start;
    }
    .purchase-notes-textarea {
      height: 150px;
    }
    .input-wrap {
      .input-area-main {
        height: 75px;
        .ant-input-affix-wrapper {
          position: $position-relative;
          padding-left: 2.563rem;
          align-items: $center;
          .ant-input-prefix {
            position: $position-absolute;
            left: 1.25rem;
            top: 0.813rem;
          }
          .ant-input {
            height: $fit-content;
          }
        }
      }
    }
    .ant-input-suffix {
      .ant-image {
        position: $position-absolute;
        right: 0.438rem;
        top: 0.438rem;
        width: 46px;
        height: 46px;
        background: $soft-peach;
        color: $tealish-gray;
        font-weight: $font-weight-semibold;
        border-radius: 0.25rem;
        display: $d-flex;
        justify-content: $center;
        align-items: $center;
        .dollar-icon {
          height: 14px;
          width: 18px;
        }
      }
    }
    .purchase-search-product {
      position: $position-relative;
      .suggestion-list {
        top: 5rem;
      }
    }
  }
  .btn-main {
    display: $d-flex;
    .save-payment-btn {
      width: 150px;
      background: $persian-green !important;
      border: $none;
      box-shadow:
        #03b2885e 0px 6px 12px -2px,
        #03b28845 0px 3px 7px -3px;
      &::after {
        content: "";
        background: linear-gradient(
          132deg,
          $neon-blue 4.27%,
          $medium-slate-blue
        );
      }
      &:hover {
        box-shadow:
          #4c4de361 0px 6px 12px -2px,
          #4c4de342 0px 3px 7px -3px;
      }
    }
    .settle-btn {
      margin-right: 1.25rem;
    }
  }
}
.purchase-table-main {
  background: $white;
  margin-bottom: 1.25rem;
  .table-wrap {
    .table {
      padding: $zero;
      h1 {
        height: 35px;
        width: $hundred-p;
        background: $white;
        display: $d-flex;
        align-items: $end;
        padding: $zero 1.25rem;
        font-size: 1.125rem;
        @media screen and (min-width: 1640px) {
          font-size: 1.313rem;
        }
        @media screen and (max-width: 768px) {
          font-size: 0.875rem;
          height: 35px;
        }
      }
    }
  }
  .purchase-table {
    margin: $zero 1.25rem;
    padding: $zero;
    .ant-spin-nested-loading {
      max-height: 352px;
      overflow: $auto;
      @media screen and (min-width: 1640px) {
        height: calc(100vh - 679px);
        max-height: $hundred-p;
      }
      @media screen and (min-width: 2000px) {
        height: calc(100vh - 919px);
        max-height: $hundred-p;
      }
    }
    table {
      colgroup {
        col {
          width: 16px !important;
        }
      }
      .ant-table-thead {
        tr {
          th {
            position: $position-sticky;
            top: $zero;
            z-index: $z-index-one;
            height: 45px;
            padding: 0.563rem;
            @media screen and (max-width: 768px) {
              font-size: 0.813rem;
            }
          }
          .ant-table-cell {
            .ant-table-column-sorters {
              .ant-table-column-title {
                white-space: $nowrap;
              }
            }
          }
          .ant-table-cell:nth-child(5) {
            padding: $zero;
          }
          .ant-table-cell:last-child {
            box-shadow: $table-box-shadow;
            border-start-end-radius: $zero;
            right: $zero !important;
            z-index: 3;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-row {
          .ant-table-cell {
            padding: 0.563rem;
            .ant-checkbox-wrapper {
              .ant-checkbox {
                .ant-checkbox-inner {
                  border: 0.063rem $border-style-solid $blue-lotus;
                }
              }
              .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background: $blue-lotus;
                }
              }
            }
            .input-area-main {
              height: 35px;
              .ant-input {
                height: 35px;
                background: $alice-blue;
                width: $hundred-p;
                padding-left: 0.375rem;
              }
              .price-input {
                height: 35px;
                background: $alice-blue;
                width: 100px;
                padding-left: 0.375rem !important;
              }
            }
            .input-wrap {
              .input-label {
                margin: $zero;
              }
              .input-area-main {
                height: 35px;
              }
            }
          }
          .ant-table-cell:nth-child(1) {
            max-width: 90px;
            min-width: 90px;
            .input-area-main {
              input {
                width: $hundred-p;
              }
            }
          }
          .ant-table-cell:nth-child(2) {
            max-width: 300px;
            min-width: 180px;
            .input-area-main {
              input {
                width: $hundred-p;
              }
            }
          }
          .ant-table-cell:nth-child(3) {
            max-width: 90px;
            min-width: 100px;
            .input-area-main {
              input {
                width: $hundred-p;
              }
            }
          }
          .ant-table-cell:nth-child(4) {
            max-width: 80px;
            min-width: 90px;
            .input-area-main {
              input {
                width: $hundred-p;
              }
            }
          }
          .ant-table-cell:nth-child(5) {
            .input-area-main {
              input {
                width: 70px;
              }
            }
          }
          .ant-table-cell:nth-child(6) {
            max-width: 60px;
            overflow: $overflow-hidden;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
          }
          .ant-table-cell:nth-child(7) {
            max-width: 100px;
            .input-area-main {
              .dropdown_input {
                height: 35px;
                background: $alice-blue;
                width: $hundred-p;
                .ant-select-selector {
                  padding-left: 0.375rem !important;
                }
              }
            }
          }
          .ant-table-cell:nth-child(8) {
            max-width: 100px;
            .input-area-main {
              input {
                width: $hundred-p;
              }
            }
          }
          .ant-table-cell:last-child {
            div {
              display: $d-flex;
              align-items: $center;
              justify-content: $center;
              gap: 0.563rem;
              .ant-image {
                cursor: $cursor-pointer;
                img {
                  height: $hundred-p;
                  width: 19px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.total-inputs-main {
  .purchase-bill-wrap {
    display: $d-flex;
    align-items: $center;
    .purchase-bill-main {
      width: 96%;
      background: $white;
      display: $d-flex;
      flex-direction: $flex-column;
      justify-content: $justify-content-between;
      align-items: $center;
      border-radius: 0.25rem;
      padding: 1rem;
      gap: 0.938rem;
      .order-tax-main {
        width: $hundred-p;
        display: $d-flex;
        justify-content: $justify-content-between;
        align-items: $center;
        margin-bottom: 1.25rem;
        @media screen and (max-width: 1440px) {
          gap: 0.938rem;
        }
      }
      .order-tax-title {
        color: $shuttle-grey;
        font-size: 0.813rem;
        font-weight: $font-weight-medium;
        flex-direction: $flex-column;
        margin: $zero;
        text-transform: uppercase;
      }
      .grand-total-main {
        width: $hundred-p;
        display: $d-flex;
        justify-content: $justify-content-between;
        align-items: $center;
      }
    }
  }
  .total-inputs-wrap {
    margin-bottom: 1rem;
    .inputs-main {
      .input-wrap {
        .input-label {
          margin: $zero;
        }
        .input-area-main {
          height: 60px;
        }
      }
    }
    .radio-btn {
      display: $d-flex;
      justify-content: $center;
      align-items: $start;
      .ant-radio-group {
        display: $d-flex;
        justify-content: $center;
        align-items: $center;
        margin-top: 0.375rem;
        .ant-radio-button-wrapper {
          border: $zero;
          height: 40px;
          display: $d-flex;
          justify-content: $center;
          align-items: $center;

          &:hover {
            color: $black;
          }
        }
      }
      .ant-radio-button-wrapper:nth-child(1) {
        border-start-start-radius: 0.375rem;
        border-end-start-radius: 0.375rem;
        span {
          font-size: 1.25rem;
        }
      }
      .ant-radio-button-wrapper-checked {
        background-color: $neon-blue;
      }
      .ant-radio-button-wrapper-checked::before {
        background-color: #d9d9d9;
      }
      .ant-radio-button-wrapper:nth-child(2) {
        border-start-end-radius: 0.375rem;
        border-end-end-radius: 0.375rem;
        span {
          font-size: 1.688rem;
        }
      }
    }
    .total-plus-minus-icon {
      display: $d-flex;
      align-items: $center;
      justify-content: $center;
      .plus-icon-main {
        .ant-image {
          width: 35px;
          height: 35px;
          display: $d-flex;
          justify-content: $center;
          align-items: $center;
          background: $madison;
          border-radius: $fifty-p;
          cursor: $cursor-pointer;
          @media screen and (max-width: 992px) {
            position: $position-static;
          }
          .plus-icon {
            width: 14px;
            height: 14px;
          }
          .minus-icon {
            width: 14px;
            height: 14px;
          }
        }
      }
      .plus-icon-main:nth-child(2) {
        margin-left: 0.625rem;
      }
    }
  }
  .minus-plus-total-wrap {
    display: $d-flex;
    justify-content: $end;
    align-items: $end;
    .minus-plus-total {
      width: $hundred-p;
      background: $white;
      display: $d-flex;
      flex-direction: $flex-column;
      justify-content: $justify-content-between;
      align-items: $center;
      border-radius: 0.25rem;
      box-sizing: border-box;
      padding: 1rem;
      gap: 0.938rem;
      .grand-total-main {
        width: $hundred-p;
        display: $d-flex;
        justify-content: $justify-content-between;
        align-items: $center;
        .order-tax-title {
          color: $shuttle-grey;
          font-size: 0.813rem;
          font-weight: $font-weight-medium;
          flex-direction: $flex-column;
          margin: $zero;
        }
      }
    }
  }
}
.purchase-save-modal {
  .ant-modal-content {
    padding: $zero !important;
    .ant-modal-close {
      top: 0.625rem;
      right: 1rem;
      width: 20px;
      height: 20px;
      border-radius: $fifty-p;
      border: 0.063rem $border-style-solid $jasper;
      background: $jasper;
      transition: all 0.3s $transition-ease-in-out;
      box-shadow:
        #dc3545 0px 3px 8px -2px,
        #dc3545 0px 3px 7px -3px;
      .ant-modal-close-x {
        .ant-modal-close-icon {
          svg {
            width: $hundred-p;
            height: 12px;
            fill: $white;
            transition: all 0.3s $transition-ease-in-out;
          }
        }
      }
      &:hover {
        background: #dc354538;
        border: 0.063rem $border-style-solid $jasper;
        .ant-modal-close-x {
          .ant-modal-close-icon {
            svg {
              fill: $jasper;
            }
          }
        }
      }
    }
    .ant-modal-header {
      padding: 0.5rem 1rem $zero;
      margin: $zero;
      .ant-modal-title {
        font-size: 1.125rem;
        padding: $zero $zero 0.313rem $zero;
      }
    }
    .ant-modal-body {
      padding: 0.5rem 1rem;
      .input-wrap {
        margin-bottom: 1.25rem;
        .input-area-main {
          height: 58px;
          .price-input-padding-none,
          .dropdown_input {
            background: $alice-blue;
          }
        }
      }
      .total-amount {
        font-weight: $font-weight-medium;
        background-color: #ecececc2;
        display: $d-flex;
        justify-content: $justify-content-between;
        width: $fifty-p;
        padding: 1rem;
        color: $shuttle-grey;
        font-size: 0.938rem;
        font-weight: $font-weight-medium;
        margin: $zero $zero 1rem $auto;
        @media screen and (max-width: 1440px) {
          font-size: 0.813rem;
        }
      }
      .purchase-payment-checkbox {
        margin-bottom: 0.625rem;
      }
      .amount-button-main {
        width: $hundred-p;
        display: $d-flex;
        justify-content: $justify-content-between;
        .cancel-btn {
          width: 48%;
          background: $jasper !important;
          font-size: 0.875rem;
          display: $d-flex;
          justify-content: $center;
          align-items: $center;
          gap: 0.375rem;
          box-shadow:
            #dc354575 0px 6px 12px -2px,
            #dc35454f 0px 3px 7px -3px;
          padding: 0.844rem 0.938rem;
          .ant-image {
            display: $d-flex;
          }
          &::after {
            content: "";
            background: linear-gradient(
              132deg,
              $neon-blue 4.27%,
              $medium-slate-blue
            );
          }
          &:hover {
            box-shadow:
              #4c4de361 0px 6px 12px -2px,
              #4c4de342 0px 3px 7px -3px !important;
          }
        }
        .complete-btn {
          width: 48%;
          background: $persian-green !important;
          color: $white;
          font-size: 1rem;
          font-weight: $font-weight-normal;
          box-shadow:
            #03b2885e 0px 6px 12px -2px,
            #03b28845 0px 3px 7px -3px;
          padding: $zero 1.25rem;
          &::after {
            content: "";
            background: $medium-slate-blue;
          }
          &:hover {
            box-shadow:
              #4c4de361 0px 6px 12px -2px,
              #4c4de342 0px 3px 7px -3px;
          }
        }
      }
      .advance-input-main {
        .advance-input {
          height: 100px;
          .input-wrap {
            margin-bottom: 0.125rem;
          }
        }
      }
    }
  }
}
.purchase-product-modal {
  .ant-modal-content {
    padding: $zero !important;
    .ant-modal-close {
      top: 0.625rem;
      right: 1rem;
      width: 20px;
      height: 20px;
      border-radius: $fifty-p;
      border: 0.063rem $border-style-solid $jasper;
      background: $jasper;
      transition: all 0.3s $transition-ease-in-out;
      box-shadow:
        #dc3545 0px 3px 8px -2px,
        #dc3545 0px 3px 7px -3px;
      .ant-modal-close-x {
        .ant-modal-close-icon {
          svg {
            width: $hundred-p;
            height: 12px;
            fill: $white;
            transition: all 0.3s $transition-ease-in-out;
          }
        }
      }
      &:hover {
        background: #dc354538;
        border: 0.063rem $border-style-solid $jasper;
        .ant-modal-close-x {
          .ant-modal-close-icon {
            svg {
              fill: $jasper;
            }
          }
        }
      }
    }
    .ant-modal-header {
      margin-bottom: $zero;
      .ant-modal-title {
        color: $midnight;
        font-size: 1.125rem;
        font-weight: $font-weight-bold;
        line-height: 1.5;
        border-bottom: 0.063rem $border-style-solid $lavender-mist;
        margin: $zero 1rem;
        padding: 0.375rem $zero;
      }
    }
    .ant-modal-body {
      max-height: 550px;
      padding: 1rem;
      padding-top: 0.438rem;
      overflow: $auto;
      @media screen and (max-width: 767px) {
        max-height: 410px;
      }
      .table-wrap {
        .table {
          padding: $zero;
          .purchase-product-modal-table {
            padding: $zero;
            .ant-table-content {
              max-height: 510px;
              overflow: $auto;
              table {
                .ant-table-thead {
                  tr {
                    .ant-table-cell {
                      padding: 0.75rem;
                      position: $position-static;
                      top: $zero;
                      background-color: $white;
                    }
                    .ant-table-cell:nth-child(1) {
                      border-start-start-radius: 0.5rem;
                      padding-left: $zero;
                    }
                  }
                }
                .ant-table-tbody {
                  .ant-table-row {
                    .ant-table-cell {
                      padding: 0.75rem;
                    }
                    .ant-table-cell:nth-child(1) {
                      padding-left: $zero;
                    }
                    .ant-table-cell:nth-child(2) {
                      max-width: 200px;
                      overflow: $overflow-hidden;
                      text-overflow: $text-overflow-ellipsis;
                      white-space: nowrap;
                    }
                    .ant-table-cell:last-child {
                      width: 120px;
                      .ant-btn {
                        height: 35px;
                        width: 80px;
                        background: $persian-green !important;
                        border: $none;
                        box-shadow:
                          #03b2885e 0px 6px 12px -2px,
                          #03b28845 0px 3px 7px -3px;
                        &::after {
                          content: "";
                          background: linear-gradient(
                            132deg,
                            $neon-blue 4.27%,
                            $medium-slate-blue
                          );
                        }
                        &:hover {
                          box-shadow:
                            #4c4de361 0px 6px 12px -2px,
                            #4c4de342 0px 3px 7px -3px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.purchase-selected-product-modal {
  .ant-modal-content {
    padding: $zero;
    .ant-modal-close {
      top: 0.75rem;
      right: 1rem;
      width: 20px;
      height: 20px;
      border-radius: $fifty-p;
      border: 0.063rem $border-style-solid $jasper;
      background: $jasper;
      transition: all 0.3s $transition-ease-in-out;
      box-shadow:
        #dc3545 0px 3px 8px -2px,
        #dc3545 0px 3px 7px -3px;
      .ant-modal-close-x {
        .ant-modal-close-icon {
          svg {
            width: $hundred-p;
            height: 12px;
            fill: $white;
            transition: all 0.3s $transition-ease-in-out;
          }
        }
      }
      &:hover {
        background: #dc354538;
        border: 0.063rem $border-style-solid $jasper;
        .ant-modal-close-x {
          .ant-modal-close-icon {
            svg {
              fill: $jasper;
            }
          }
        }
      }
    }
    .ant-modal-header {
      padding: 0.5rem 1rem $zero;
      margin: $zero;
      .ant-modal-title {
        font-size: 1.125rem;
        padding: $zero $zero 0.625rem $zero;
      }
    }
    .ant-modal-body {
      max-height: 550px;
      padding: 1rem;
      padding-top: 0.438rem;
      overflow: $auto;
      @media screen and (max-width: 767px) {
        max-height: 410px;
      }
      .input-wrap {
        .input-area-main {
          .common-input {
            background: $alice-blue;
          }
        }
      }
      .table-wrap {
        .table {
          padding: $zero;
          .purchase-table {
            padding: $zero;
            .ant-table-content {
              max-height: 330px;
              overflow: $auto;
              table {
                .ant-table-thead {
                  tr {
                    .ant-table-cell {
                      position: $position-sticky;
                      top: $zero;
                      z-index: 3;
                      background-color: $white;
                      padding: 0.563rem;
                    }
                  }
                }
                .ant-table-tbody {
                  .ant-table-row {
                    .ant-table-cell {
                      padding: 0.563rem;
                      .input-area-main {
                        height: 35px;
                        .ant-input {
                          width: 83px;
                          background-color: $alice-blue;
                          height: 35px;
                        }
                      }
                    }
                    .ant-table-cell:nth-child(2) {
                      max-width: 130px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .purchase-bill-main {
        width: 30%;
        background: $alice-blue;
        margin-left: $auto;
        margin-top: 1.25rem;
        display: $d-flex;
        flex-direction: $flex-column;
        justify-content: $justify-content-between;
        align-items: $center;
        border-radius: 0.25rem;
        box-sizing: border-box;
        padding: 1rem;
        gap: 0.938rem;
        @media screen and (max-width: 992px) {
          width: 70%;
        }
        @media screen and (max-width: 575px) {
          width: $hundred-p;
        }
        .order-tax-main {
          width: $hundred-p;
          display: $d-flex;
          justify-content: $justify-content-between;
          align-items: $center;
          margin-bottom: 1.25rem;
          @media screen and (max-width: 1440px) {
            gap: 0.938rem;
          }
        }
        .order-tax-title {
          color: $shuttle-grey;
          font-size: 0.813rem;
          font-weight: $font-weight-medium;
          flex-direction: $flex-column;
          margin: $zero;
        }
        .grand-total-main {
          width: $hundred-p;
          display: $d-flex;
          justify-content: $justify-content-between;
          align-items: $center;
        }
      }
    }
    .ant-modal-footer {
      padding: $zero 1rem 0.5rem;
      button {
        width: 100px;
      }
    }
  }
}
