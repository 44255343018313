@import "../../../styles/variable.scss";

.new-expenses-main {
  padding: 0.875rem 1.25rem 1.25rem;
  @media screen and (min-width: 1440px) {
    padding: 1rem 1.875rem 1.875rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
  }
  .expenses-loader {
    width: $hundred-p;
    height: 500px;
    margin: $zero $auto;
  }
  .expenses-new-wrap {
    .ant-col {
      .input-wrap {
        .text-input-area-main {
          .textarea-common-input {
            height: 50px;
            min-height: 50px;
            font-size: 0.875rem;
            @media screen and (max-width: 1250px) {
              font-size: 0.813rem;
            }
            @media screen and (max-width: 768px) {
              font-size: 0.75rem;
            }
          }
        }
      }
    }
  }
  .save-btn {
    width: 100px;
  }
}
