@import "../../../styles/variable.scss";

.pos-list-details-main {
  height: 160px;
  @media screen and (max-width: 1199px) {
    justify-content: $end;
    height: 140px;
  }
  @media screen and (max-width: 991px) {
    justify-content: $end;
    height: $auto;
  }
  .pos-list-details-input:nth-child(1) {
    order: 1;
  }
  .pos-list-details-input:nth-child(2) {
    order: 2;
  }
  .pos-list-details-input:nth-child(3) {
    order: 4;
    .input-wrap {
      .reference-number-input-main {
        .reference-number-input {
          padding-right: 2.75rem;
        }
      }
    }
    .code-loader {
      height: 29px;
      width: 29px;
      position: $position-absolute;
      top: 0.375rem;
      right: 0.375rem;
      background: $soft-peach;
      border-radius: 0.125rem;
      display: $d-flex;
      justify-content: $center;
      span {
        svg {
          height: 15px;
          width: 12px;
        }
      }
    }
    @media screen and (max-width: 992px) {
      order: 3;
    }
  }
  .pos-list-details-input:nth-child(4) {
    order: 5;
    @media screen and (max-width: 992px) {
      order: 4;
    }
  }
  .pos-list-details-input:nth-child(5) {
    order: 3;
    @media screen and (max-width: 992px) {
      order: 5;
    }
  }
  .pos-list-details-input:nth-child(6) {
    order: 6;
  }
  .pos-list-details-input {
    .input-wrap {
      .reference-number-input-main {
        .error-count-msg {
          .error-msg {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
  .reference-number-input {
    height: 40px !important;
    background: $soft-peach;
    color: $dimgray;
    @media screen and (max-width: 1550px) {
      font-size: 0.75rem;
    }
    @media screen and (max-width: 1199px) {
      height: 35px !important;
      font-size: 0.625rem;
    }
    @media screen and (max-width: 991px) {
      height: 40px !important;
    }
  }
  .reference-number-dropdown {
    height: 40px !important;
    @media screen and (max-width: 1199px) {
      height: 35px !important;
    }
    @media screen and (max-width: 991px) {
      height: 40px !important;
    }
    .ant-select-selector {
      .ant-select-selection-search {
        padding-left: 0.625rem;
      }
    }
    .ant-select-selection-item {
      font-size: 0.875rem;
      @media screen and (max-width: 1250px) {
        font-size: 0.625rem;
      }
      @media screen and (max-width: 768px) {
        font-size: 0.75rem;
      }
    }
  }
  .reference-number-input-main {
    height: 60px;
    @media screen and (max-width: 1199px) {
      font-size: 0.625rem;
      height: 50px;
    }
    input {
      @media screen and (max-width: 1250px) {
        font-size: 0.625rem;
      }
    }
    .error-count-msg {
      .error-msg {
        font-size: 0.75rem;
      }
    }
  }
  .reference-number-labels {
    line-height: normal !important;
    margin-bottom: 0.313rem;
    font-size: 0.875rem;
    @media screen and (max-width: 1440px) {
      font-size: 0.75rem !important;
    }
  }
  .redeem-btn {
    margin-bottom: 1.313rem;
    display: $d-flex;
    align-items: $end;
    @media screen and (max-width: 1199px) {
      margin-bottom: 0.938rem;
    }
    .filter-button {
      width: $hundred-p;
      float: right;
      @media screen and (max-width: 1199px) {
        height: 35px !important;
      }
      @media screen and (max-width: 991px) {
        height: 40px !important;
      }
    }
    .add-customer-btn {
      display: $d-flex;
      justify-content: $center;
      align-items: $center;
      @media screen and (max-width: 1199px) {
        height: 35px !important;
      }
      @media screen and (max-width: 991px) {
        height: 40px !important;
      }
      .ant-image {
        .add-customer {
          @media screen and (max-width: 1200px) {
            height: 16px !important;
            width: 16px !important;
          }
        }
      }
      span {
        display: $none;
      }
    }
  }
}
