@import "../../../styles/variable.scss";

.new-stock-wrap {
  background: $alice-blue;
  padding: 1.25rem;
  padding-top: 0.875rem;
  @media screen and (min-width: 1440px) {
    padding: 1.875rem;
    padding-top: 1rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.75rem;
  }
  .barcode-field-main {
    background: $white;
    border-radius: 0.25rem;
    padding: 1.25rem 1.25rem 0.5rem;
    display: $d-flex;
    margin-bottom: 1.25rem;
    @media screen and (max-width: 768px) {
      padding: 1.25rem;
      flex-wrap: $flex-wrap;
    }
    @media screen and (max-width: 576px) {
      padding: 0.625rem;
    }
    .barcode-field-wrap {
      width: $fifty-p;
      @media screen and (max-width: 768px) {
        width: 76%;
      }
      @media screen and (max-width: 575px) {
        width: $hundred-p;
      }
      position: $position-relative;
      .suggestion-list {
        top: 5rem;
      }
      .barcode-input {
        width: $hundred-p;
        background: $alice-blue;
        text-transform: $text-transform-capitalize;
      }
      .error-count-msg {
        .error-msg {
          margin-top: 0.125rem;
        }
      }
      .barcode-textbox-main {
        height: 75px;
      }
    }
    .label-button-main {
      position: $position-relative;
      .label-button {
        position: $position-absolute;
        top: 1.563rem;
        left: 1.25rem;
        width: 115px;
        height: 50px !important;
        color: $white;
        padding: $zero;
        border: $none;
        box-shadow:
          #4c4de361 0px 6px 12px -2px,
          #4c4de342 0px 3px 7px -3px;
        @media screen and (max-width: 575px) {
          position: $position-static;
          width: 100px;
          height: 40px !important;
        }
        &:hover {
          box-shadow:
            #dc354575 0px 6px 12px -2px,
            #dc35454f 0px 3px 7px -3px;
        }
      }
    }
  }
  .stock-details-main {
    border-radius: 0.25rem;
    .stock-details-title {
      color: $midnight;
      font-size: 1.125rem;
      font-weight: $font-weight-semibold;
      line-height: 1.2;
      border-bottom: 0.063rem $border-style-solid $lavender-mist;
      padding: 1.25rem $zero 0.5rem;
      margin: $zero 1.25rem;
      @media screen and (max-width: 576px) {
        font-size: 0.938rem;
        margin: $zero 0.625rem;
        padding: 0.625rem $zero;
      }
    }
    .stock-details-form-main {
      padding: 0.5rem 1.25rem $zero;
      @media screen and (min-width: 1440px) {
        padding: 0.5rem 1.25rem $zero;
      }
      @media screen and (max-width: 576px) {
        padding: 0.625rem 0.625rem $zero;
      }
      .loader-icon {
        height: 390px;
      }
      .multiple-input-main {
        .edit-numeric-input {
          background: $alice-blue;
        }
      }
      .anticon-loading {
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .product-stock-input-main {
        height: 85px;
      }
      .product-unit-input-main {
        height: 79px;
        .product_dropdown_main {
          background-color: $alice-blue;
        }
        .price-input {
          background: $soft-peach;
          color: $dimgray;
          padding-left: 3rem;
          &::placeholder {
            color: $gray;
            font-weight: $font-weight-medium;
          }
        }
        .currency-icon {
          background: $light-grey-tints;
        }
      }
      .product-unit-input {
        background: $soft-peach;
        color: $dimgray;
        &::placeholder {
          color: $gray;
          font-weight: $font-weight-medium;
        }
      }
      .product-unit-numeric-input {
        background: $soft-peach;
        color: $dimgray;
      }
      .product-unit-dropdown {
        background: $soft-peach;
        color: $dimgray;
      }
      .product-unit-date-picker {
        background: $soft-peach;
        @media screen and (max-width: 992px) {
          margin-bottom: $zero;
        }
        &:hover {
          border-color: $bg-transparent !important;
        }
        .ant-picker-input {
          input {
            color: $dimgray;
            &::placeholder {
              color: $gray;
              font-weight: $font-weight-medium;
            }
          }
          .ant-picker-suffix {
            .anticon-calendar {
              svg {
                color: #afafaf;
              }
            }
          }
        }
      }
    }
    .product-details-main {
      background: $white;
      border-radius: 0.25rem;
    }
    .stock-details-wrap {
      background: $white;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
    .loader-main {
      justify-content: $center;
    }
    .edit-stock-btn {
      width: 105px;
      height: 50px;
      margin: 1.25rem 1.25rem $zero;
      padding: $zero;
      color: $white;
      @media screen and (min-width: 1440px) {
        margin: 1.25rem 1.25rem $zero;
      }
      @media screen and (max-width: 768px) {
        height: 40px;
      }
      @media screen and (max-width: 576px) {
        margin: 1.25rem 1.25rem;
      }
      .anticon {
        svg {
          fill: $white !important;
        }
      }
    }
    .edit-product-details-btn {
      width: 105px;
      height: 50px;
      margin: $zero 1.25rem 1.25rem;
      padding: $zero;
      color: $white;
      @media screen and (min-width: 1440px) {
        margin: $zero 1.25rem 1.25rem;
      }
      @media screen and (max-width: 768px) {
        height: 40px;
      }
      @media screen and (max-width: 576px) {
        margin: $zero 0.625rem 0.625rem;
      }
    }
  }
  .Package-details-main {
    background: $white;
    border-radius: 0.25rem;
    .Package-details-title {
      color: $midnight;
      font-size: 1.125rem;
      font-weight: $font-weight-semibold;
      line-height: 1.2;
      border-bottom: 0.063rem $border-style-solid $lavender-mist;
      padding: $zero $zero 0.5rem;
      margin: $zero 1.25rem;
      margin-bottom: 1.25rem;
      @media screen and (max-width: 576px) {
        margin: $zero 0.625rem;
        padding: 0.625rem $zero;
        font-size: 0.938rem;
      }
    }
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-header {
          .ant-collapse-header-text {
            color: $midnight;
            font-size: 1rem;
            font-weight: $font-weight-medium;
            line-height: 1.2;
            border-color: $border-transparent;
            padding: $zero;
            margin: $zero;
            @media screen and (max-width: 576px) {
              margin: $zero 0.625rem;
              padding: $zero $zero 0.625rem;
              font-size: 0.938rem;
            }
          }
        }
      }
    }
    .Package-product-details {
      padding: 0.5rem 1.25rem $zero;
      .ant-col {
        @media screen and (max-width: 575px) {
          padding-left: $zero !important;
          padding-right: $zero !important;
        }
      }
      .Package-product-details-input-main {
        .Package-product-details-input {
          background: $alice-blue;
        }
        .Package-product-details-dropdown {
          background: $alice-blue;
        }
        .ant-select-disabled {
          background: $soft-peach;
        }
        .error-msg {
          margin-top: 0.125rem;
        }
      }
      .input-wrap {
        .Package-product-details-input-main {
          .price-input {
            background: $alice-blue;
          }
        }
      }
    }
    .Package-product-date-picker {
      margin: $zero;
    }
    .stock-details-form-main {
      padding: 0.5rem 1.25rem $zero;
      .loader-icon {
        height: 390px;
      }
      @media screen and (min-width: 1440px) {
        padding: 1.875rem 1.25rem $zero;
      }
      @media screen and (max-width: 576px) {
        padding: 0.625rem 0.625rem $zero;
      }
      .multiple-input-main {
        .edit-numeric-input {
          background: $alice-blue;
        }
      }
      .anticon-loading {
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .product-stock-input-main {
        height: 85px;
      }
      .product-unit-input-main {
        .price-input {
          background: $soft-peach;
          color: $dimgray;
          &::placeholder {
            color: $gray;
            font-weight: $font-weight-medium;
          }
        }
      }
      .product-unit-input {
        background: $soft-peach;
        color: $dimgray;
        &::placeholder {
          color: $gray;
          font-weight: $font-weight-medium;
        }
      }
      .product-unit-numeric-input {
        background: $soft-peach;
        color: $dimgray;
      }
      .product-unit-dropdown {
        background: $soft-peach;
        color: $dimgray;
      }
      .product-unit-date-picker {
        background: $soft-peach;
        @media screen and (max-width: 992px) {
          margin-bottom: $zero;
        }

        &:hover {
          border-color: $bg-transparent !important;
        }
        .ant-picker-input {
          input {
            color: $dimgray;
            &::placeholder {
              color: $gray;
              font-weight: $font-weight-medium;
            }
          }
          .ant-picker-suffix {
            .anticon-calendar {
              svg {
                color: #afafaf;
              }
            }
          }
        }
      }
    }
    .loader-main {
      justify-content: $center;
    }
    .edit-product-details-btn {
      width: 105px;
      height: 50px;
      margin: $zero 1.25rem;
      padding: $zero;
      color: $white;
      @media screen and (min-width: 1440px) {
        margin: $zero 1.25rem;
      }
      @media screen and (max-width: 768px) {
        height: 40px;
      }
      @media screen and (max-width: 576px) {
        margin: $zero 0.625rem;
      }
      .anticon {
        svg {
          fill: $white !important;
        }
      }
    }
    .Package-edit-product-details-btn {
      width: 105px;
      height: 50px;
      margin: 1.25rem;
      padding: $zero;
      color: $white;
      @media screen and (min-width: 1440px) {
        margin: 1.25rem 1.25rem;
      }
      @media screen and (max-width: 768px) {
        height: 40px;
      }
      @media screen and (max-width: 576px) {
        margin: $zero 0.625rem 0.625rem;
      }
    }
    .input-wrap {
      .date-picker-input {
        background: $alice-blue;
      }
      .Package-product-details-input {
        .price-input {
          background: $alice-blue;
        }
      }
    }
    .ant-collapse {
      border-color: $border-transparent;
      margin: $zero 1.25rem;
      background: $white;
      border-radius: $zero;
      padding-bottom: 1.25rem;
      .ant-collapse-content {
        border-color: $border-transparent;
      }
      .ant-collapse-item {
        background: $alice-blue;
        border-radius: 0.5rem;
        border-color: $border-transparent;
        border-bottom: $none;
        .ant-collapse-header {
          .ant-collapse-header-text {
            .stock-quantity-title {
              border-color: $border-transparent;
              padding: $zero;
              margin: $zero;
            }
          }
        }
      }
      .ant-collapse-item:nth-child(1) {
        margin-top: $zero;
      }
      .ant-collapse-item {
        margin-top: 1.25rem;
      }
    }
  }
  .stock-quantity-main {
    background: $white;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding-bottom: 1.25rem;
    .stock-quantity-title {
      color: $midnight;
      font-size: 1rem;
      font-weight: $font-weight-medium;
      line-height: 1.2;
      border-bottom: 0.063rem $border-style-solid $lavender-mist;
      padding: $zero $zero 0.5rem;
      margin: $zero 1.25rem;
      @media screen and (max-width: 576px) {
        margin: $zero 0.625rem;
        padding: $zero $zero 0.625rem;
        font-size: 0.938rem;
      }
    }
    .stock-quantity-form-main {
      padding: 0.5rem 1.25rem $zero;
      @media screen and (min-width: 1440px) {
        padding: 0.563rem 1.25rem;
        padding-bottom: $zero;
      }
      @media screen and (max-width: 576px) {
        padding: 0.625rem 0.625rem $zero;
      }
      .stock-quantity-date-picker {
        background: $alice-blue;
        @media screen and (max-width: 992px) {
          margin-bottom: $zero;
        }
      }
      .stock-quantity-input {
        background: $alice-blue;
      }
      .stock-quantity-input-disable {
        background: $white-smoke;
        color: $gray;
        font-weight: $font-weight-medium;
      }
      .input-wrap {
        .category-input-wrap {
          .ant-input-disabled {
            background: #0000000a;
          }
          .category-input {
            background: $alice-blue;
          }
          .error-count-msg {
            .error-msg {
              margin-top: 0.125rem;
            }
          }
        }
        .input-area-main {
          .veg-fruits-product-price-input {
            font-size: 0.875rem;
            @media screen and (max-width: 768px) {
              font-size: 0.75rem;
            }
          }
          .currency-icon {
            background: $light-grey-tints;
            left: 0.75rem;
            color: $dim-gray;
          }
          .price-input {
            height: 50px;
            background: $alice-blue;
            padding-left: 3rem !important;
          }
          .dropdown_input {
            background: $alice-blue;
          }
        }
        .multiple-input-main {
          .create-input-main {
            .create-input {
              background: $alice-blue;
            }
          }
          .edit-numeric-input {
            background: $alice-blue;
          }
        }
        .error-msg-main {
          left: $zero;
          bottom: 0.563rem;
        }
        .date-picker-input {
          background: $alice-blue;
        }
        .error-msg {
          margin-top: 0.125rem;
        }
      }
      .multiple-input-main {
        height: 75px;
      }
    }
    .ant-collapse {
      border-color: $border-transparent;
      background: $alice-blue;
      margin: $zero 1.25rem;
      .ant-collapse-content {
        border-color: $border-transparent;
      }
      .ant-collapse-item {
        border-color: $border-transparent;
        .ant-collapse-header {
          .ant-collapse-header-text {
            .stock-quantity-title {
              border-color: $border-transparent;
              padding: $zero;
              margin: $zero;
            }
          }
        }
      }
    }
  }
  .Package-stock-quantity-main {
    background: $white;
    border-radius: 0.25rem;
    .stock-quantity-title {
      color: $midnight;
      font-size: 1.125rem;
      font-weight: $font-weight-semibold;
      line-height: 1.2;
      border-bottom: 0.063rem $border-style-solid $lavender-mist;
      padding: $zero $zero 0.5rem;
      margin: $zero 1.25rem;
      @media screen and (max-width: 576px) {
        margin: $zero 0.625rem;
        padding: 0.625rem $zero;
        font-size: 0.938rem;
      }
    }
    .stock-quantity-form-main {
      padding: 0.5rem 1.25rem $zero;
      @media screen and (min-width: 1440px) {
        padding: 1.25rem;
        padding-bottom: $zero;
      }
      @media screen and (max-width: 576px) {
        padding-bottom: $zero;
        padding: 0.625rem;
      }
      .stock-quantity-date-picker {
        background: $alice-blue;
        @media screen and (max-width: 992px) {
          margin-bottom: $zero;
        }
      }
      .stock-quantity-input {
        background: $alice-blue;
      }
      .stock-quantity-input-disable {
        background: $white-smoke;
        color: $gray;
        font-weight: $font-weight-medium;
      }
    }
  }
  .submit-stock-btn {
    width: 105px;
    color: $white;
    padding: $zero;
    margin: $zero 1.25rem 1.25rem;
    @media screen and (max-width: 768px) {
      height: 40px;
      font-size: 0.875rem;
    }
    @media screen and (max-width: 576px) {
      margin: 0.313rem 0.625rem 0.625rem;
    }
    .anticon {
      svg {
        fill: $white !important;
      }
    }
  }
}
.barcode-label-modal {
  .barcode-wrap {
    display: $d-flex;
    flex-direction: $flex-column;
    align-items: $center;
    gap: 0.625rem;
    padding: $zero 3.75rem;
  }
  .divider {
    margin: $zero;
  }
  .product-title {
    margin-top: 0.375rem;
    color: $midnight;
    text-align: $start;
    font-size: 0.875rem;
    font-weight: $font-weight-medium;
    span {
      color: $venus;
      font-size: 0.938rem;
      font-weight: $font-weight-normal;
    }
  }
  .product-price {
    font-size: 2.188rem;
    font-weight: $font-weight-medium;
    color: $midnight;
    span {
      font-size: 1.875rem;
    }
  }
  .product-information {
    font-size: 0.875rem;
    font-weight: $font-weight-medium;
    text-transform: $text-transform-capitalize;
  }
  .retail-price-main {
    display: $d-flex;
    flex-direction: $flex-column;
    align-items: $center;
    gap: 0.625rem;
    padding: $zero 3.75rem;
  }
  .barcode {
    height: 100px;
    width: $fit-content;
  }
  .barcode-modal-button-main {
    display: $d-flex;
    justify-content: $flex-end;
    margin-top: 0.563rem;
    .barcode-modal-button {
      width: 90px;
      background: $persian-green !important;
      box-shadow:
        #03b2887d 0px 6px 12px -2px,
        #03b28859 0px 3px 7px -3px;
      &::after {
        content: "";
        background: $medium-slate-blue;
      }
      &:hover {
        box-shadow:
          #4c4de361 0px 6px 12px -2px,
          #4c4de342 0px 3px 7px -3px;
        color: $white !important;
      }
    }
  }
  .ant-modal-content {
    padding: $zero !important;
    .ant-modal-close {
      top: 0.625rem;
      right: 1rem;
      width: 20px;
      height: 20px;
      border-radius: $fifty-p;
      border: 0.063rem $border-style-solid $jasper;
      background: $jasper;
      transition: all 0.3s $transition-ease-in-out;
      box-shadow:
        #dc3545 0px 3px 8px -2px,
        #dc3545 0px 3px 7px -3px;
      .ant-modal-close-x {
        .ant-modal-close-icon {
          svg {
            width: $hundred-p;
            height: 12px;
            fill: $white;
            transition: all 0.3s $transition-ease-in-out;
          }
        }
      }
      &:hover {
        background: #dc354538;
        border: 0.063rem $border-style-solid $jasper;
        .ant-modal-close-x {
          .ant-modal-close-icon {
            svg {
              fill: $jasper;
            }
          }
        }
      }
    }
    .ant-modal-header {
      padding: 0.5rem 1rem $zero;
      margin: $zero;
      .ant-modal-title {
        font-size: 1.125rem;
        padding: $zero $zero 0.313rem $zero;
      }
    }
    .ant-modal-body {
      padding: $zero 1rem 1rem;
      @media screen and (max-width: 767px) {
        height: $auto;
        overflow: $auto;
      }
    }
  }
}
