@import "../../../styles/variable.scss";

.create-product-wrap {
  background: $alice-blue;
  padding: 1.25rem;
  padding-top: 0.875rem;
  @media screen and (min-width: 1440px) {
    padding: 1.875rem;
    padding-top: 1rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
  }
  .create-product-input-main {
    .product-input {
      position: $position-relative;
      label {
        color: $black;
        font-weight: $font-weight-medium;
        display: $d-block;
        line-height: 1.281rem;
        margin-bottom: 0.5rem;
      }
      .disable-input {
        background: $soft-peach;
        color: $dimgray;
      }
      .product-notes-textarea {
        height: 156px;
        margin-bottom: 1.25rem;
      }
      .input-area-main {
        .error-count-msg {
          .count {
            height: 16px;
          }
        }
      }
      .barcode-edit-icon-main {
        position: $position-absolute;
        top: 0.625rem;
        right: 0.625rem;
        z-index: $z-index-one;
        width: 30px;
        height: 30px;
        background: $whisper;
        display: $d-flex;
        justify-content: $center;
        align-items: $center;
        border-radius: 0.25rem;
        cursor: $cursor-pointer;
        .ant-image {
          width: 16px;
          height: 16px;
          .barcode-edit-icon {
            width: $hundred-p;
            height: $hundred-p;
            fill: $madison;
          }
        }
      }
      .input-wrap {
        .input-area-main {
          .dropdown_input {
            .ant-select-selector {
              font-size: 0.75rem;
              @media screen and (min-width: 769px) {
                font-size: 0.875rem;
              }
              .ant-select-selection-search {
                padding-left: 0.625rem;
              }
            }
          }
          .error-count-msg {
            .error-msg {
              margin-top: 0.125rem;
            }
          }
          .error-msg {
            margin-top: 0.125rem;
          }
        }
      }
    }
    .input-area-main {
      position: $position-relative;
    }
  }

  .multiple-images-main {
    margin-bottom: 1.25rem;
    .multiple-image-title {
      color: $ebony-clay;
      font-size: 0.875rem;
      font-weight: $font-weight-medium;
      line-height: 1.281rem;
      opacity: 1;
      margin-top: $zero;
      @media screen and (min-width: 1640px) {
        font-size: 1rem;
      }
    }
    .upload-images-box-main {
      background: $white;
      border-radius: 0.25rem;
      padding: 1.25rem;
      @media screen and (max-width: 425px) {
        padding: 0.75rem;
      }
      .ant-upload-drag {
        background: $bg-transparent;
        padding: 1.875rem 0.625rem;
        .ant-upload-btn {
          padding: $zero;
        }
        .ant-upload {
          .ant-upload-drag-container {
            display: $d-block;
            .ant-upload-drag-icon {
              display: $d-flex;
              justify-content: $center;
              .ant-image {
                width: $auto;
                height: $auto;
                display: $d-flex;
                .ant-image-img {
                  width: $auto;
                }
              }
            }
          }
        }
      }
      .ant-upload-hint {
        margin-bottom: $zero;
      }
      .upload-image-error {
        color: $jasper;
        font-size: 0.813rem;
        text-transform: $text-transform-capitalize;
        margin: $zero;
      }
      .checkbox-main {
        span {
          &:nth-child(2) {
            color: $manatee;
            font-size: 0.875rem;
            font-weight: $font-weight-normal;
            line-height: 1.281rem;
          }
        }
        .ant-checkbox {
          .ant-checkbox-inner {
            width: 20px;
            height: 20px;
            background: $bg-transparent;
            border: 0.125rem $border-style-solid $medium-slate-blue;
            margin-right: 0.313rem;
          }
        }
      }
      .uploaded-image-wrap {
        position: $position-relative;
        width: $fit-content;
        margin: 1rem $auto $zero;
        .close-button-main {
          position: $position-absolute;
          right: -0.375rem;
          top: -0.375rem;
          z-index: $z-index-one;
          display: $d-flex;
          justify-content: $flex-end;
          .anticon-close {
            width: 20px;
            height: 20px;
            background: $neon-blue;
            border: 0.125rem $border-style-solid $white;
            border-radius: 3.125rem;
            transition: 0.2s all $transition-ease-in-out;
            padding: 0.188rem;
            svg {
              width: $hundred-p;
              height: 10px;
              fill: $white;
            }
          }
          &:hover {
            .anticon-close {
              background: $alice-blue;
              svg {
                fill: $black;
              }
            }
          }
        }
        .ant-image {
          display: $d-flex;
          justify-content: $center;
          overflow: $overflow-hidden;
          border-radius: 0.5rem;
          .upload-image {
            max-width: 250px;
            width: $hundred-p;
            max-height: 250px;
            height: $hundred-p;
            object-fit: $object-fit-contain !important;
            border-radius: 0.5rem;
            margin: $zero;
          }
        }
      }
    }
   
  }
  .ant-input-suffix {
    .anticon {
      position: $position-absolute;
      right: 0.435rem;
      top: 0.75rem;
      width: 35px;
      height: 35px;
      background: $soft-peach;
      color: $tealish-gray;
      font-weight: $font-weight-semibold;
      display: $d-flex;
      justify-content: $center;
      align-items: $center;
      border-radius: 0.25rem;
    }
  }
}
.btn-fixed {
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
  }
  padding: 1.25rem;
  @media screen and (min-width: 1440px) {
    padding: 1.875rem;
  }
  .submit-products-btn {
    width: 84px;
    color: $white;
    .anticon {
      svg {
        fill: $white !important;
      }
    }
  }
}
.loader-animation-main {
  .hold-product-loader {
    height: 380px;
  }
  p {
    margin: $zero;
  }
}
:where(.css-dev-only-do-not-override-1xg9z9n).ant-upload-wrapper
  .ant-upload-drag:not(.ant-upload-disabled):hover,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-upload-wrapper
  .ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: $light-grey !important;
}
.ant-upload-wrapper {
  .ant-upload-drag {
    .ant-upload-drag-icon {
      .anticon {
        color: $aluminium !important;
      }
    }
  }
}
.ant-input-suffix {
  .anticon {
    position: $position-absolute;
    right: 0.435rem;
    top: 0.75rem;
    width: 35px;
    height: 35px;
    background: $soft-peach;
    color: $tealish-gray;
    font-weight: $font-weight-semibold;
    display: $d-flex;
    justify-content: $center;
    align-items: $center;
    border-radius: 0.25rem;
  }
}
