@import "../../../styles/variable.scss";

.user-report-main {
  background: $alice-blue;
  padding: 1.25rem;
  padding-top: 0.875rem;
  @media screen and (min-width: 1440px) {
    padding: 1.875rem;
    padding-top: 1rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
  }
  .table-wrap {
    .table-header-main {
      display: $d-flex;
      margin-bottom: 1rem;
      .print-button-main {
        justify-content: $end;
        align-items: $end;
        flex-wrap: $nowrap;
        .common-button {
          width: 100px;
          @media screen and (max-width: 575px) {
            width: 80px;
          }
        }
        .common-button:nth-child(2) {
          margin-left: 1rem;
          background: $persian-green !important;
          border: $none;
          box-shadow:
            #03b2885e 0px 6px 12px -2px,
            #03b28845 0px 3px 7px -3px;
          &::after {
            content: "";
            background: linear-gradient(
              132deg,
              $neon-blue 4.27%,
              $medium-slate-blue
            );
          }
          &:hover {
            box-shadow:
              #4c4de361 0px 6px 12px -2px,
              #4c4de342 0px 3px 7px -3px;
          }
        }
      }
    }
    .table {
      background: $bg-transparent;
      padding: $zero;
    }
  }
  .user-report-table {
    height: calc(100vh - 243px);
    background: $white;
    overflow-x: $overflow-hidden;
    overflow-y: $auto;
    @media screen and (min-width: 1500px) {
      height: calc(100vh - 268px);
    }
    @media screen and (max-width: 1200px) {
      height: 380px;
    }
    @media screen and (max-width: 768px) {
      height: 336px;
    }
    .ant-spin {
      top: 2.25rem;
    }
    .ant-spin-nested-loading {
      height: calc(100vh - 243px);
      background: $white;
      overflow-x: $auto;
      @media screen and (min-width: 1500px) {
        height: calc(100vh - 268px);
      }
      @media screen and (max-width: 1200px) {
        height: 380px;
      }
      @media screen and (max-width: 768px) {
        height: 336px;
      }
    }
    table {
      .ant-table-thead {
        tr {
          .ant-table-cell {
            position: $position-sticky;
            top: $zero;
            z-index: $z-index-one;
            height: 45px;
            background-color: $white;
            padding: 0.563rem;
          }
          .ant-table-cell:first-child {
            border-start-start-radius: $zero;
            padding-left: $zero;
          }
          .ant-table-cell:last-child {
            right: $zero;
            box-shadow: $table-box-shadow;
            border-start-end-radius: $zero;
            text-align: $center;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-row {
          .ant-table-cell {
            padding: 0.563rem;
            .ant-tag-red {
              color: $red;
              border: 0.063rem $border-style-solid $red;
              padding: 0.125rem 0.5rem;
            }
            .ant-tag-success {
              background: $clear-day;
              color: $persian-green;
              border-color: $persian-green;
              padding: 0.125rem 0.5rem;
            }
          }
          .ant-table-cell:first-child {
            max-width: 150px;
            overflow: $overflow-hidden;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
            padding-left: $zero;
          }
          .ant-table-cell:nth-child(2) {
            max-width: 150px;
            overflow: $overflow-hidden;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
          }
          .ant-table-cell:nth-child(3) {
            max-width: 150px;
            overflow: $overflow-hidden;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
          }
          .ant-table-cell:nth-child(4) {
            max-width: 150px;
            overflow: $overflow-hidden;
            text-overflow: $text-overflow-ellipsis;
            white-space: $nowrap;
          }
          .ant-table-cell:last-child {
            position: $position-sticky;
            right: $zero;
            background: $white;
            text-align: $center;
          }
        }
      }
    }
  }
}
