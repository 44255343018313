@import "../../../styles/variable.scss";

.unit-list-main {
  background: $alice-blue;
  padding: 1.25rem;
  padding-top: 0.875rem;
  @media screen and (min-width: 1440px) {
    padding: 1.875rem;
    padding-top: 1rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
  }
  .table-wrap {
    .table-header-main {
      .print-button-main {
        .new-button {
          width: 138px;
          @media screen and (max-width: 768px) {
            width: 128px;
          }
        }
        .generate-backup-button {
          width: 130px;
          @media screen and (max-width: 768px) {
            width: 110px;
          }
        }
      }
    }
    .table {
      background: $bg-transparent;
      padding: $zero;
    }
  }
  .unit-list-table {
    background: $white;
    overflow-x: $auto;
    height: calc(100vh - 294px);
    @media screen and (min-width: 1500px) {
      height: calc(100vh - 312px);
    }
    @media screen and (max-width: 1200px) {
      height: 320px;
    }
    @media screen and (max-width: 768px) {
      height: 336px;
    }
    .ant-spin-nested-loading {
      background: $white;
      height: calc(100vh - 294px);
      overflow: $auto;
      @media screen and (min-width: 1500px) {
        height: calc(100vh - 312px);
      }
      @media screen and (max-width: 1200px) {
        height: 320px;
      }
      @media screen and (max-width: 768px) {
        height: 336px;
      }
      .ant-spin-container {
        height: $hundred-p;
        .ant-table-empty {
          height: $hundred-p;
          .ant-table-container {
            height: $hundred-p;
            .ant-table-content {
              height: $hundred-p;
              table {
                height: $hundred-p;
              }
            }
          }
        }
      }
    }
    .ant-table-content {
      background: $white;
    }
    table {
      .ant-table-wrapper {
        overflow-x: $auto;
        overflow-y: $overflow-hidden;
      }
      colgroup {
        col {
          width: 16px !important;
        }
      }
      .ant-table-thead {
        tr {
          th {
            position: $position-sticky;
            top: $zero;
            z-index: $z-index-one;
          }
          .ant-table-cell {
            padding: 0.563rem;
            .ant-checkbox-wrapper {
              .ant-checkbox {
                .ant-checkbox-inner {
                  border: 0.063rem $border-style-solid $blue-lotus;
                }
              }
              .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background: $blue-lotus;
                }
              }
            }
          }
          .ant-table-column-sort{
            background: $white;
          }
          .ant-table-cell:first-child {
            padding-left: $zero;
            border-start-start-radius: $zero;
          }
          .ant-table-cell:last-child {
            position: $position-sticky !important;
            z-index: 3;
            width: 130px;
            text-align: $center;
            box-shadow: $table-box-shadow;
            border-start-end-radius: $zero;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-row {
          .ant-table-cell {
            padding: 0.563rem;
            p {
              margin: $zero;
            }
            .ant-checkbox-wrapper {
              .ant-checkbox {
                .ant-checkbox-inner {
                  border: 0.063rem $border-style-solid $blue-lotus;
                }
              }
              .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background: $blue-lotus;
                }
              }
            }
          }
          .ant-table-column-sort{
            background: $white;
          }
          .ant-table-cell:first-child {
            padding-left: $zero;
          }
          .ant-table-cell:last-child {
            position: $position-sticky !important;
            z-index: $z-index-one;
            .system-action-icon-none {
              span {
                cursor: $cursor-not-allowed !important;
                svg {
                  fill: $oslo-grey !important;
                }
              }
            }
            div {
              display: $d-flex;
              align-items: $center;
              justify-content: $center;
              span {
                svg {
                  height: 19px;
                  width: $hundred-p;
                  object-fit: $none;
                  @media screen and (max-width: 768px) {
                    height: 18px;
                    width: 15px;
                  }
                }
              }
              span:nth-child(1) {
                cursor: $cursor-pointer;
                svg {
                  fill: $blue-lotus;
                }
              }
              .ant-image {
                cursor: $cursor-pointer;
                margin-left: 0.563rem;
                img {
                  height: $hundred-p;
                  width: 19px;
                  @media screen and (max-width: 768px) {
                    width: 16px;
                  }
                }
              }
            }
          }
          .ant-table-cell-fix-right {
            position: $position-static !important;
          }
        }
      }
    }
  }
}
.unit-modal-main {
  padding-bottom: $zero;
  .ant-modal-content {
    padding: $zero !important;
    .ant-modal-close {
      top: 0.625rem;
      right: 1rem;
      width: 20px;
      height: 20px;
      border-radius: $fifty-p;
      border: 0.063rem $border-style-solid $jasper;
      background: $jasper;
      transition: all 0.3s $transition-ease-in-out;
      box-shadow: #dc3545 0px 3px 8px -2px, #dc3545 0px 3px 7px -3px;
      .ant-modal-close-x {
        .ant-modal-close-icon {
          svg {
            width: $hundred-p;
            height: 12px;
            fill: $white;
            transition: all 0.3s $transition-ease-in-out;
          }
        }
      }
      &:hover {
        background: #dc354538;
        border: 0.063rem $border-style-solid $jasper;
        .ant-modal-close-x {
          .ant-modal-close-icon {
            svg {
              fill: $jasper;
            }
          }
        }
      }
    }
    .ant-modal-header {
      padding: 0.5rem 1rem $zero;
      margin: $zero;
      .ant-modal-title {
        font-size: 1.125rem;
        padding: $zero $zero 0.313rem $zero;
      }
    }
    .ant-modal-body {
      max-height: 550px;
      overflow: $auto;
      padding: 0.375rem 1rem $zero;
    }
    .ant-modal-footer {
      padding: $zero 1rem 0.625rem;
      .create-button {
        background: $persian-green !important;
        box-shadow:
          #03b2887d 0px 6px 12px -2px,
          #03b28859 0px 3px 7px -3px;
        &::after {
          content: "";
          background: $medium-slate-blue;
        }
        &:hover {
          box-shadow:
            #4c4de361 0px 6px 12px -2px,
            #4c4de342 0px 3px 7px -3px;
        }
        .anticon {
          svg {
            fill: $white !important;
          }
        }
      }
    }
  }
  .unit-modal-input-main {
    .unit-list-input-main {
      height: 77px;
      .unit-list-input {
        background: $alice-blue;
      }
      .error-count-msg {
        .error-msg {
          margin-top: 0.125rem;
        }
      }
    }
  }
}
