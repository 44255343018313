@import "../../../styles/variable.scss";

.customer-pos-page-main {
  width: 60%;
  height: calc(100vh - 134.48px);
  padding: 3.125rem $zero;
  margin: $auto;
  .payment-method-wrap {
    height: $fit-content;
    width: $hundred-p;
    margin-top: 0.625rem;
    .bill-list-wrap {
      background: $white;
      display: $d-flex;
      gap: 1.25rem;
      border-radius: 0.25rem;
      padding: 0.75rem;
      .order-bill {
        width: $hundred-p;
        display: $d-flex;
        flex-direction: $flex-column;
        gap: 0.5rem;
        @media screen and (max-width: 1199px) {
          gap: 0.313rem;
        }
        @media screen and (max-width: 991px) {
          gap: 0.5rem;
        }
      }
      .bill-main {
        display: $d-flex;
        justify-content: $justify-content-between;
        align-items: $center;
        .order-list-bill {
          color: $shuttle-grey;
          font-size: 0.75rem;
          font-weight: $font-weight-medium;
          margin: $zero;
          margin-right: 1rem;
          @media screen and (max-width: 1199px) {
            font-size: 0.625rem;
          }
          @media screen and (max-width: 991px) {
            font-size: 0.813rem;
          }
        }
        .order-list-bill-price {
          color: $shuttle-grey;
          font-size: 0.75rem;
          white-space: $nowrap;
          font-weight: $font-weight-medium;
          margin: $zero;
          @media screen and (max-width: 1199px) {
            font-size: 0.625rem;
          }
          @media screen and (max-width: 991px) {
            font-size: 0.813rem;
          }
        }
        .discount {
          color: $jasper;
          font-size: 0.938rem;
          font-weight: $font-weight-medium;
          margin: $zero;
          @media screen and (max-width: 1440px) {
            font-size: 0.813rem;
          }
          @media screen and (max-width: 1250px) {
            font-size: 0.75rem;
          }
        }
      }
    }
    .grand-total-main {
      box-shadow:
        #4c4de361 0px 6px 12px -2px,
        #4c4de342 0px 3px 7px -3px;
      background: linear-gradient(132deg, $neon-blue 4.27%, $medium-slate-blue);
      display: $d-flex;
      border-radius: 0.25rem;
      margin: 0.625rem $zero;
      justify-content: $center;
      height: 45px;
      align-items: $center;
      p {
        color: $white;
        font-weight: $font-weight-semibold;
        font-size: 1rem;
        margin: $zero;
      }
    }
  }
  .table-wrap {
    .table {
      padding: $zero;
      .ant-table-content {
        height: calc(100vh - 294.48px);
        overflow: $auto;
        .ant-table-thead {
          position: $position-sticky;
          width: $hundred-p;
          top: $zero;
          z-index: 1;
          background: $white;
          .ant-table-cell {
            padding: 0.625rem;
          }
          .ant-table-cell:first-child {
            padding-left: $zero;
          }
        }
        .ant-table-tbody {
          .ant-table-row {
            .ant-table-cell:first-child {
              padding-left: $zero;
            }
            .ant-table-cell {
              padding: 0.625rem;
              .counter-input-main {
                height: 40px;
                input {
                  width: 120px;
                  height: 40px;
                  background: #f1f5f9;
                }
              }
            }
          }
        }
      }
    }
  }
}
