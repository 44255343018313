@import "../../styles/variable.scss";

.page-not-found {
  height: calc(100vh - 134px);
  display: $d-flex;
  justify-content: $center;
  align-items: $center;
  flex-direction: $flex-column;
  overflow: $overflow-hidden;
  .page-not-found-img {
    position: $position-relative;
    width: 740px;
    height: 670px;
    display: $d-flex;
    justify-content: $center;
    @media only screen and (max-width: 768px) {
      width: 500px;
    }
    @media only screen and (max-width: 575px) {
      width: 400px;
    }
    @media only screen and (max-width: 425px) {
      width: 300px;
    }
    .page-not-found-text {
      position: $position-absolute;
      bottom: 25%;
      color: $dark-seafoam;
      text-align: $center;
      font-size: 2.188rem;
      font-weight: $font-weight-semibold;
      margin: $zero;
      @media only screen and (max-width: 768px) {
        font-size: 1.563rem;
      }
      @media only screen and (max-width: 575px) {
        bottom: 28%;
        font-size: 0.938rem;
      }
    }
  }
}
