@import "./../../styles/variable.scss";

.Payment-completed-receipt {
  width: 780px !important;
  .ant-modal-content {
    padding: $zero !important;
    .ant-modal-footer {
      padding-right: 0.938rem;
      .payment-complete-btn {
        display: $d-flex;
        align-items: $center;
        justify-content: $end;
        gap: 1.25rem;
        padding: $zero $zero 1rem;
        .next-order {
          width: 110px;
          background: $bg-transparent;
          box-shadow:
            #2c3a5263 0px 6px 12px -2px,
            #2c3a523b 0px 3px 7px -3px;
          padding: $zero 0.938rem;
          &::after {
            content: "";
            background: $japanese-indigo;
            border: $none;
          }
          &:hover {
            border: $none;
            color: $white;
            box-shadow:
              #2c3a5263 0px 6px 12px -2px,
              #2c3a523b 0px 3px 7px -3px;
          }
          .anticon {
            svg {
              fill: $white !important;
            }
          }
        }
        .print-receipt {
          width: 120px;
          background: $persian-green !important;
          gap: 0.375rem;
          box-shadow:
            #03b2885e 0px 6px 12px -2px,
            #03b28845 0px 3px 7px -3px;
          padding: $zero;
          &::after {
            content: "";
            background: $medium-slate-blue !important;
          }
          &:hover {
            box-shadow:
              #4c4de361 0px 6px 12px -2px,
              #4c4de342 0px 3px 7px -3px;
          }
        }
      }
    }
    .ant-modal-body {
      max-height: 600px;
      overflow: $auto;
      .payment-complete-modal-main {
        .payment-method-recept {
          @media screen and (max-width: 575px) {
            .quotation-date-main {
              flex-direction: column;
              div {
                width: $hundred-p !important;
              }
            }
            .bill-main {
              flex-direction: column;
              div {
                width: $hundred-p !important;
              }
            }
          }
        }
      }
    }
  }
}
