@import "../../../styles/variable.scss";

.till-list-main {
  background: $alice-blue;
  padding: 0.875rem 1.25rem 1.25rem;
  @media screen and (min-width: 1440px) {
    padding: 1rem 1.875rem 1.875rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
  }
  .table {
    padding: $zero;
  }
  .till-table {
    height: calc(100vh - 240px);
    overflow-x: $overflow-hidden;
    background: $white;
    overflow-y: $auto;
    @media screen and (min-width: 1500px) {
      height: calc(100vh - 268px);
    }
    @media screen and (max-width: 1200px) {
      height: 320px;
    }
    @media screen and (max-width: 768px) {
      height: 336px;
    }
    .ant-spin {
      top: 2.25rem;
    }
    .ant-spin-nested-loading {
      max-height: calc(100vh - 280px);
      background: $white;
      overflow-x: $auto;
      @media screen and (min-width: 1500px) {
        max-height: calc(100vh - 309px);
      }
      @media screen and (max-width: 1200px) {
        max-height: 320px;
      }
      @media screen and (max-width: 768px) {
        max-height: 336px;
      }
    }
    table {
      .ant-table-thead {
        tr {
          .ant-table-cell {
            position: $position-sticky;
            top: $zero;
            z-index: $z-index-one;
            height: 45px;
            padding: $zero 0.563rem;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-row {
          .ant-table-cell {
            padding: 0.563rem;
          }
          .ant-table-cell:last-child{
            .input-wrap{
              width: $fit-content;
              .input-label{
                margin: $zero;
              }
              .input-area-main{
                  width: $fit-content;
                  height: 35px;
                .ant-select{
                  width: 150px;
                  height: 35px;
                  background: $alice-blue;
                }
              }
            }
          }
        }
      }
    }
  }
}
.new-till-modal {
  .ant-modal-content {
    padding: $zero !important;
    .ant-modal-close {
      top: 0.5rem;
      right: 1rem;
      width: 21px;
      height: 20px;
      border-radius: $fifty-p;
      border: 0.063rem $border-style-solid $jasper;
      background: $jasper;
      transition: all 0.3s $transition-ease-in-out;
      box-shadow:
        #dc3545 0px 3px 8px -2px,
        #dc3545 0px 3px 7px -3px;
      .ant-modal-close-x {
        .ant-modal-close-icon {
          svg {
            width: $hundred-p;
            height: 12px;
            fill: $white;
            transition: all 0.3s $transition-ease-in-out;
          }
        }
      }
      &:hover {
        background: #dc354538;
        border: 0.063rem $border-style-solid $jasper;
        .ant-modal-close-x {
          .ant-modal-close-icon {
            svg {
              fill: $jasper;
            }
          }
        }
      }
    }
    .ant-modal-header {
      margin-bottom: $zero;
      .ant-modal-title {
        color: $midnight;
        font-size: 1.125rem;
        font-weight: $font-weight-bold;
        line-height: 1.5;
        margin: $zero 1rem;
        padding: 0.375rem $zero;
        border-bottom: 0.063rem $border-style-solid $lavender-mist;
      }
    }
    .ant-modal-body {
      padding: 0.563rem 1rem $zero;
      @media screen and (max-width: 767px) {
        max-height: 440px;
        overflow: $auto;
      }
      .input-wrap{
        .input-area-main{
          .ant-input{
            background: $alice-blue;
          }
        }
      }
    }
    .ant-modal-footer {
        padding: 0.563rem 1rem 0.75rem;
        .modal-buttons-main {
          width: $hundred-p;
          display: $d-flex;
          gap: 1.25rem;
          .filter-cancel-btn {
            background: $bg-transparent !important;
            color: $jasper !important;
            box-shadow: 0 5px 10px 0 #dc384833;
            border: 0.063rem $border-style-solid $jasper !important;
            .filter-icon {
              padding-left: 0.5rem;
            }
            &::after {
              content: "";
              background: $jasper;
              color: $white;
              border: 0.063rem $border-style-solid $jasper;
            }
            &:hover {
              color: $white !important;
              border: 0.063rem $border-style-solid $jasper !important;
            }
          }
          .filter-button {
            background: $persian-green !important;
            box-shadow:
              #03b2887d 0px 6px 12px -2px,
              #03b28859 0px 3px 7px -3px;
            &::after {
              content: "";
              background: $medium-slate-blue;
            }
            &:hover {
              box-shadow:
                #4c4de361 0px 6px 12px -2px,
                #4c4de342 0px 3px 7px -3px;
            }
          }
        }
      }
  }
}
