@import "../../../styles/variable.scss";
.mix-match-list-main {
  background: $alice-blue;
  padding: 1.25rem;
  padding-top: 0.875rem;
  @media screen and (min-width: 1440px) {
    padding: 1.875rem;
    padding-top: 1rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
  }
  .table-wrap {
    .table-header-main {
      margin: $zero;
    }
    .table {
      padding: $zero;
      background-color: $bg-transparent;
      .mix-match-list-table {
        height: calc(100vh - 231px);
        background: $white;
        overflow-x: $overflow-hidden;
        overflow-y: $auto;
        @media screen and (min-width: 1500px) {
          height: calc(100vh - 256px);
        }
        @media screen and (max-width: 1200px) {
          height: 345px;
        }
        @media screen and (max-width: 768px) {
          height: 336px;
        }
        .ant-spin {
          top: 2.25rem;
        }
        .ant-spin-nested-loading {
          height: calc(100vh - 231px);
          background: $white;
          overflow-x: $auto;
          @media screen and (min-width: 1500px) {
            height: calc(100vh - 256px);
          }
          @media screen and (max-width: 1200px) {
            height: 345px;
          }
          @media screen and (max-width: 768px) {
            height: 336px;
          }
        }
        table {
          .ant-table-thead {
            tr {
              .ant-table-cell {
                position: $position-sticky;
                top: $zero;
                z-index: 3;
                background-color: $white;
                padding: 0.563rem;
                text-transform: $text-transform-uppercase;
              }
              .ant-table-cell:first-child {
                padding-left: $zero;
              }
              .ant-table-cell:last-child {
                border-start-end-radius: $zero;
                width: 180px;
                text-align: $center;
                box-shadow: $table-box-shadow;
                @media screen and (max-width: 768px) {
                  max-width: 90px;
                  min-width: 90px !important;
                  width: 90px;
                  overflow: $overflow-hidden;
                  text-overflow: $text-overflow-ellipsis;
                  white-space: $nowrap;
                }
              }
            }
          }
          .ant-table-tbody {
            .ant-table-row {
              .ant-table-cell {
                padding: 0.563rem;
                p {
                  margin: $zero;
                }
              }
              .ant-table-cell:first-child {
                padding-left: $zero;
                .ant-table-row-expand-icon {
                  height: 16px;
                  width: 16px;
                  border: 0.063rem $border-style-solid #000eff;
                  background: $white;
                  border-radius: 0.25rem;
                  &::before{
                    background: #000eff;
                  }
                  &::after{
                    background: $neon-blue;
                    inset-inline-start: 6px;
                  }
                }
              }
              .ant-table-cell:last-child {
                text-align: $center;
                @media screen and (max-width: 768px) {
                  max-width: 90px;
                  min-width: 90px !important;
                  width: 90px;
                  overflow: $overflow-hidden;
                  text-overflow: $text-overflow-ellipsis;
                  white-space: $nowrap;
                }
                div {
                  display: $d-flex;
                  align-items: $center;
                  justify-content: $center;
                  span {
                    svg {
                      height: 19px;
                      width: $hundred-p;
                      object-fit: $none;
                      fill: $blue-lotus;
                      @media screen and (max-width: 768px) {
                        height: 18px;
                        width: 14px;
                      }
                    }
                  }
                  .ant-image {
                    cursor: $cursor-pointer;
                    margin-left: 0.563rem;
                    margin-top: -0.25rem;
                    img {
                      height: $hundred-p;
                      width: 19px;
                      @media screen and (max-width: 768px) {
                        width: 15px;
                      }
                    }
                  }
                }
              }
            }
            .ant-table-expanded-row {
              .ant-table-cell {
                padding: $zero;
                .table-wrap {
                  .mix-expand-modal-table {
                    padding: $zero;
                    .ant-spin-nested-loading {
                      height: $auto;
                    }
                    table {
                      .ant-table-thead {
                        tr {
                          .ant-table-cell {
                            position: $position-relative;
                            padding: 0.75rem;
                            z-index: $zero;
                            background: #f3f6f966;
                          }
                          .ant-table-cell:nth-child(1) {
                            width: 130px;
                            overflow: $auto;
                            text-overflow: $text-overflow-ellipsis;
                            white-space: $nowrap;
                            padding-left: 3.75rem;
                            @media screen and (max-width:684px) {
                              padding-left: 2.5rem;
                            }
                          }
                          .ant-table-cell:nth-child(2) {
                            max-width: 200px;
                            overflow: $auto;
                            text-overflow: $text-overflow-ellipsis;
                            white-space: $nowrap;
                          }
                          .ant-table-cell:nth-child(3) {
                            max-width: 200px;
                            overflow: $auto;
                            text-overflow: $text-overflow-ellipsis;
                            white-space: $nowrap;
                          }
                          .ant-table-cell:last-child {
                            max-width: 180px;
                            text-align: $center;
                            box-shadow: $table-box-shadow;
                          }
                        }
                      }
                      .ant-table-tbody {
                        .ant-table-row {
                          .ant-table-cell-row-hover {
                            background: #f3f6f966 !important;
                          }
                          .ant-table-cell {
                            padding: 0.75rem;
                            background: #f3f6f966;
                          }
                          .ant-table-cell:nth-child(1) {
                            width: 130px;
                            overflow: $auto;
                            text-overflow: $text-overflow-ellipsis;
                            white-space: $nowrap;
                            padding-left: 3.75rem;
                            @media screen and (max-width:684px) {
                              padding-left: 2.5rem;
                            }
                          }
                          .ant-table-cell:nth-child(2) {
                            max-width: 200px;
                            overflow: $auto;
                            text-overflow: $text-overflow-ellipsis;
                            white-space: $nowrap;
                          }
                          .ant-table-cell:last-child {
                            max-width: 180px;
                            text-align: $center;
                          }
                        }
                        .ant-table-row:last-child {
                          .ant-table-cell {
                            border-bottom: $none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
