@import "../../styles/variable.scss";

.ant-notification {
  .ant-notification-notice-wrapper {
    .ant-notification-notice {
      .ant-notification-notice-content {
        .ant-notification-notice-with-icon {
          margin-bottom: $zero;
          .ant-notification-notice-message {
            margin-bottom: $zero;
          }
        }
      }
      .ant-notification-notice-close {
        top: 1.375rem;
        right: 1.5rem;
        width: 20px;
        height: 20px;
        border-radius: $fifty-p;
        border: 0.063rem $border-style-solid $jasper;
        background: $jasper;
        transition: all 0.3s $transition-ease-in-out;
        box-shadow:
          #dc3545 0px 3px 8px -2px,
          #dc3545 0px 3px 7px -3px;
        .ant-notification-notice-close-x {
          .anticon-close {
            svg {
              width: $hundred-p;
              height: 12px;
              fill: $white;
              transition: all 0.3s $transition-ease-in-out;
            }
          }
        }
        &:hover {
          background: #dc354538;
          border: 0.063rem $border-style-solid $jasper;
          .ant-notification-notice-close-x {
            .anticon-close {
              svg {
                fill: $jasper;
              }
            }
          }
        }
      }
    }
  }
}
