@import "../../../styles/variable.scss";

.system-setting-main {
  padding: 1.25rem;
  padding-top: 0.875rem;
  @media screen and (min-width: 1440px) {
    padding: 1.875rem;
    padding-top: 1rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
  }
  .system-setting-wrap {
    background: $white;
    padding: 1.25rem;
    border-radius: 0.25rem;
    margin-bottom: 1.25rem;
    @media screen and (max-width: 576px) {
      padding: 0.75rem;
    }
    .system-setting-title {
      font-size: 1.125rem;
      font-weight: $font-weight-semibold;
      color: $midnight;
      margin: $zero;
      line-height: 1.2;
      border-bottom: 0.063rem $border-style-solid $lavender-mist;
      padding: $zero $zero 0.625rem;
      margin-bottom: 0.625rem;
      @media screen and (max-width: 768px) {
        font-size: 1rem;
        padding: $zero $zero 0.313rem;
        margin-bottom: 0.313rem;
      }
    }
    .company-logo-main {
      .multiple-images-main {
        .multiple-image-title {
          font-size: 0.875rem;
          color: $ebony-clay;
          opacity: 1;
          font-weight: $font-weight-normal;
          line-height: 1.281rem;
          margin-top: $zero;
          @media screen and (min-width:1640px) {
            font-size: 1rem;
          }
        }
        .upload-images-box-main {
          background: $alice-blue;
          padding: 1.5rem;
          border-radius: 0.25rem;
          @media screen and (max-width: 425px) {
            padding: 0.75rem;
          }
          .ant-upload-drag {
            padding: 2.5rem 0.625rem;
            background: $white;
            .ant-upload-btn {
              padding: $zero;
            }
          }
          .ant-upload-hint {
            margin-bottom: $zero;
          }
          .upload-image {
            margin-bottom: 1rem;
          }
        }
      }
    }
    .system-setting-address-textarea {
      background: $alice-blue;
      height: 150px;
    }
    .system-setting-form-main {
      margin-bottom: 1.25rem;
      .system-setting-input {
        background: $alice-blue;

      }
      .upload-images-box-main {
        background: $alice-blue !important;
      }
      .system-setting-dropdown {
        background: $alice-blue;
      }
      .system-setting-textarea {
        height: 234px;
        background: $alice-blue;
        margin-bottom: 0.625rem;
      }
      .upload-image-main {
        display: $d-flex;
        justify-content: $center;
        align-items: $center;
        .ant-image {
          .upload-image {
            max-width: 290px;
            height: $hundred-p;
            max-height: 290px;
            border-radius: 0.5rem;
            margin-bottom: $zero;
          }
        }
      }
      .uploaded-image-wrap {
        position: $position-relative;
        margin-top: 1rem;
        .close-button-main {
          position: $position-absolute;
          right: -0.375rem;
          top: -0.375rem;
          z-index: $z-index-one;
          display: $d-flex;
          justify-content: $flex-end;
          .anticon-close {
            width: 20px;
            height: 20px;
            background: $neon-blue;
            border: 0.125rem $border-style-solid $white;
            padding: 0.188rem;
            border-radius: 3.125rem;
            transition: 0.2s all $transition-ease-in-out;
            svg {
              width: $hundred-p;
              height: 10px;
              fill: $white;
            }
          }
        }
      }
      .input-wrap {
        .input-area-main {
          .system-setting-input {
            background: $alice-blue;
            .ant-input-wrapper {
              .ant-input-group-addon{
                .ant-select{
                  .ant-select-selector{
                    .ant-select-selection-item{
                      display: $d-flex;
                      align-items: $center;
                      color: $black;
                      padding-inline-end: 0.75rem;
                      font-size: 0.875rem;
                      @media screen and (max-width: 1250px) {
                        font-size: 0.813rem;
                      }
                      @media screen and (max-width: 768px) {
                        font-size: 0.75rem;
                      }
                    }
                  }
                }
              }
              .ant-input {
                background-color: $bg-transparent;
                border: $none;
                margin-top: 0.188rem;
                padding: $zero;
                font-size: 0.875rem;
                @media screen and (max-width: 1250px) {
                  font-size: 0.813rem;
                }
                @media screen and (max-width: 768px) {
                  font-size: 0.75rem;
                }
                &:focus {
                  box-shadow: $none;
                }
              }
            }
            .ant-input-group {
              .ant-input-group-addon {
                border: $none;
                background-color: $bg-transparent;
                padding-left: $zero;
                .ant-select {
                  margin-top: -0.125rem;
                  .ant-select-selector {
                    padding-left: $zero !important;
                  }
                }
                .ant-select-open {
                  border: $none;
                }
              }
            }
          }
          .error-count-msg {
            .error-msg {
              margin-top: 0.125rem;
            }
          }
          .error-msg {
            margin-top: 0.125rem;
          }
        }
        .text-input-area-main {
          .system-setting-textarea {
            font-size: 0.875rem;
            @media screen and (max-width: 768px) {
              font-size: 0.75rem;
            }
          }
          .error-msg {
            margin-top: 0.125rem;
          }
        }
        .upload-images-box-main {
          .error-msg {
            margin-top: 0.125rem;
          }
        }
      }
    }
  }
  .system-setting-btn {
    width: 180px;
    background: $persian-green !important;
    box-shadow:
      #03b2885e 0px 6px 12px -2px,
      #03b28845 0px 3px 7px -3px;
    @media screen and (max-width: 768px) {
      width: 120px;
    }
    &::after {
      content: "";
      background: $medium-slate-blue;
    }
    &:hover {
      box-shadow:
        #4c4de361 0px 6px 12px -2px,
        #4c4de342 0px 3px 7px -3px;
    }
    .anticon {
      svg {
        fill: $white !important;
      }
    }
  }
  .pos-receipt-system {
    background: $white;
    padding: 1.25rem;
    border-radius: 0.25rem;
    @media screen and (max-width: 576px) {
      padding: 0.75rem;
    }
    .pos-receipt-setting-title {
      font-size: 1.125rem;
      font-weight: $font-weight-semibold;
      color: $midnight;
      margin: $zero;
      line-height: 1.2;
      border-bottom: 0.063rem $border-style-solid $lavender-mist;
      padding: $zero $zero 0.625rem;
      margin-bottom: 1rem;
      @media screen and (max-width: 768px) {
        font-size: 1rem;
        padding: $zero $zero 0.313rem;
        margin-bottom: 0.313rem;
      }
    }
    .pos-setting-option-main {
      margin-bottom: 1.25rem;
      @media screen and (max-width: 768px) {
        row-gap: 1rem !important;
      }
      .pos-setting-checkbox {
        span {
          &:nth-child(2) {
            font-size: 1rem;
            font-weight: $font-weight-normal;
            line-height: 1.281rem;
            color: $midnight;
            @media screen and (max-width: 768px) {
              font-size: 0.813rem;
            }
          }
        }
      }
    }
    .pos-setting-form {
      .pos-setting-note-input-main {
        .error-count-msg {
          .error-msg {
            margin-top: 0.125rem;
          }
        }
      }
      .pos-setting-note-input {
        background: $alice-blue;
      }
    }
  }
  .payment-gateway-main {
    background: $white;
    padding: 1.563rem 1.25rem 1.875rem;
    border-radius: 0.25rem;
    margin-bottom: 2.188rem;
    .payment-gateway-title {
      font-size: 1.125rem;
      font-weight: $font-weight-semibold;
      color: $midnight;
      margin: $zero;
      line-height: 1.2;
      border-bottom: 0.063rem $border-style-solid $lavender-mist;
      padding: $zero $zero 1.25rem;
      margin-bottom: 2.188rem;
    }
    .payment-details-main {
      .payment-gateway-input {
        background: $alice-blue;
      }
    }
    .payment-gateway-setting-checkbox {
      margin-bottom: 1.875rem;
      .ant-checkbox {
        height: 24px;
      }
      span {
        &:nth-child(2) {
          font-size: 1rem;
          font-weight: $font-weight-normal;
          line-height: 1.281rem;
          color: $midnight;
        }
      }
    }
  }
  .sms-configuration-main {
    background: $white;
    padding: 1.563rem 1.25rem 1.875rem;
    border-radius: 0.25rem;
    margin-bottom: 2.188rem;
    .sms-configuration-title {
      font-size: 1.125rem;
      font-weight: $font-weight-semibold;
      color: $midnight;
      margin: $zero;
      line-height: 1.2;
      border-bottom: 0.063rem $border-style-solid $lavender-mist;
      padding: $zero $zero 1.25rem;
      margin-bottom: 2.188rem;
    }
    .sms-configuration-form {
      .sms-configuration-input {
        background: $alice-blue;
      }
      .sms-configuration-form-main {
        margin-bottom: 0.5rem;
      }
    }
  }
  .smtp-configuration-main {
    background: $white;
    padding: 1.563rem 1.25rem 1.875rem;
    border-radius: 0.25rem;
    margin-bottom: 2.188rem;
    .smtp-configuration-title {
      font-size: 1.125rem;
      font-weight: $font-weight-semibold;
      color: $midnight;
      margin: $zero;
      line-height: 1.2;
      border-bottom: 0.063rem $border-style-solid $lavender-mist;
      padding: $zero $zero 1.25rem;
      margin-bottom: 2.188rem;
    }
    .smtp-configuration-form {
      .smtp-configuration-input {
        background: $alice-blue;
      }
      .smtp-configuration-form-main {
        margin-bottom: 0.5rem;
      }
    }
  }
  .clear-cache-main {
    background: $white;
    padding: 1.563rem 1.25rem 1.875rem;
    border-radius: 0.25rem;
    margin-bottom: 2.188rem;
    .clear-cache-title {
      font-size: 1.125rem;
      font-weight: $font-weight-semibold;
      color: $midnight;
      margin: $zero;
      line-height: 1.2;
      border-bottom: 0.063rem $border-style-solid $lavender-mist;
      padding: $zero $zero 1.25rem;
      margin-bottom: 2.188rem;
    }
  }
}
:where(.css-1xg9z9n).ant-upload-wrapper
  .ant-upload-drag:not(.ant-upload-disabled):hover,
:where(.css-1xg9z9n).ant-upload-wrapper
  .ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: $light-grey !important;
}
:where(.css-dev-only-do-not-override-1xg9z9n).ant-upload-wrapper
  .ant-upload-drag:not(.ant-upload-disabled):hover,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-upload-wrapper
  .ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: $light-grey !important;
}
.ant-upload-wrapper {
  .ant-upload-drag {
    .ant-upload-drag-icon {
      .anticon {
        color: $aluminium !important;
      }
    }
  }
}
