@import "../../../styles/variable.scss";

.create-mix-match-main {
  background: $alice-blue;
  padding: 0.875rem 1.25rem;
  @media screen and (min-width: 1440px) {
    padding: 1rem 1.875rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.625rem;
  }
  .create-mix-match-inputs {
    .input-main {
      position: $position-relative;
      .suggestion-list {
        top: 5rem;
      }
    }
    .input-wrap {
      .ant-picker {
        .ant-picker-input {
          input {
            &::placeholder {
              color: $shuttle-grey;
            }
          }
        }
      }
    }
  }
  .update-bill-main {
    width: $hundred-p;
    display: $d-flex;
    justify-content: start;
    margin-top: 1.25rem;
    @media screen and (max-width: 1440px) {
      gap: 0.938rem;
    }
    @media screen and (max-width: 992px) {
      margin-bottom: 1rem;
    }
    .update-bill {
      width: 30%;
      background: $white;
      border-radius: 0.25rem;
      padding: 1rem;
      color: $shuttle-grey;
      font-size: 0.938rem;
      font-weight: $font-weight-medium;
      margin: $zero;
      display: $d-flex;
      justify-content: $justify-content-between;
      @media screen and (max-width: 1440px) {
        font-size: 0.813rem;
      }
      @media screen and (max-width: 575px) {
        width: $hundred-p;
      }
    }
  }
  .create-mix-match-wrap {
    .table-wrap {
      .table-header-main {
        display: $none;
      }
      .table {
        padding: $zero;
        background-color: $white;
        .create-mix-match-table {
          overflow: $auto;
          margin: $zero 1.25rem;
          padding: $zero;
          height: 304px;
          @media screen and (min-width: 1500px) {
            height: calc(100vh - 489px);
          }
          @media screen and (max-width: 1200px) {
            height: 230px;
          }
          table {
            .ant-table-thead {
              tr {
                .ant-table-cell {
                  position: $position-sticky;
                  top: $zero;
                  z-index: 3;
                  background-color: $white;
                  padding: 0.563rem;
                  border-start-start-radius: $zero;
                }
                .ant-table-cell:first-child {
                  padding-left: $zero;
                }
                .ant-table-cell:last-child {
                  text-align: $center;
                  box-shadow: $table-box-shadow;
                  border-start-end-radius: $zero;
                }
              }
            }
            .ant-table-tbody {
              .ant-table-row {
                .ant-table-cell {
                  padding: 0.563rem;
                  p {
                    margin: $zero;
                  }
                }
                .ant-table-cell:first-child {
                  padding-left: $zero;
                }
                .ant-table-cell:last-child {
                  text-align: $center;
                  div{
                    .ant-image {
                      cursor: $cursor-pointer;
                      img {
                        height: $hundred-p;
                        width: 19px;
                        @media screen and (max-width: 768px) {
                          width: 15px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .pagination {
          margin-bottom: 0.75rem;
        }
      }
    }
  }
  .save-btn {
    width: 100px;
  }
}
